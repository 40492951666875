import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import metadata from './CADriverComponent.metadata.json5';

import messages from '../../CADriversPage.messages';
import { Link } from '@jutro/router';

function CADriverComponent(props) {
    const {
        driverVM,
        id,
        onValidate,
        onValueChange,
        showErrors,
        isReadOnly,
        downloadDriverReleaseForm,
        file,
        updateFile,
        isMaskPrivateFields,
        licenseStateOptions,
        updateCurrentRow
    } = props;
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    const [licenseNumberPath, updateLicenseNumberPath] = useState('licenseNumber');
    const [isMaskDOB, updateIsMaskDOB] = useState(false);

    const translator = useTranslator();

    const {
        licenseNumber,
        dateOfBirth
    } = driverVM.value;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [driverVM, id, onValidate, isComponentValid]);

    const uploadfile = (newfile) => {
        updateFile(newfile);
    };

    const onBlurLicenseNumber = useCallback(() => {
        if (isMaskPrivateFields && licenseNumber) {
            let maskLicenseNumber = '';
            for (let i = 0; i < licenseNumber.length; i++) {
                maskLicenseNumber += '*';
            }
            _.set(driverVM, 'maskLicenseNumber', maskLicenseNumber);
            updateLicenseNumberPath('maskLicenseNumber');
        }
    }, [driverVM, isMaskPrivateFields, licenseNumber]);

    const onBlurDOB = useCallback(() => {
        if (isMaskPrivateFields && dateOfBirth) {
            if (_.get(driverVM, 'dateOfBirth.aspects.subtreeValid')) {
                _.set(driverVM, 'maskDateOfBirth', `**/**/${dateOfBirth.year}`);
                updateIsMaskDOB(true);
            }
        }
    }, [dateOfBirth, driverVM, isMaskPrivateFields]);

    useEffect(() => {
        onBlurLicenseNumber();
        onBlurDOB();
    }, []);

    const onFocusLicenseNumber = useCallback(() => {
        if (isMaskPrivateFields) {
            _.set(driverVM, 'licenseNumber', '');
            updateLicenseNumberPath('licenseNumber');
        }
    }, [driverVM, isMaskPrivateFields]);

    const onFocusMaskDOB = useCallback(() => {
        updateIsMaskDOB(false);
        _.set(driverVM, 'dateOfBirth', '');
    }, [driverVM]);

    const renderMVRWarning = () => {
        return translator(messages.MVRWarning, {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            driverReleaseFormLink: isReadOnly ? translator(messages.driverReleaseFormLink) : <Link id="downloadDriverReleaseForm" onClick={(e) => { downloadDriverReleaseForm() }}>{translator(messages.driverReleaseFormLink)}</Link>
        })
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        driverNumber: {
            readOnly: true
        },
        licenseState: {
            availableValues: licenseStateOptions
        },
        licenseNumber: {
            onBlur: onBlurLicenseNumber,
            onFocus: onFocusLicenseNumber,
            path: licenseNumberPath
        },
        dateOfBirth: {
            onBlur: onBlurDOB,
            visible: !isMaskDOB
        },
        maskDateOfBirth: {
            onFocus: onFocusMaskDOB,
            visible: isMaskDOB
        },
        MVRWarning: {
            message: renderMVRWarning()
        },
        uploadSection: {
            value: _.get(file, 'name', null),
            disabled: isReadOnly
        },
        uploadContainer: {
            visible: !isReadOnly
        }
    };

    const readValue = useCallback((fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent, driverVM, fieldId, fieldPath, overrideProps
        );
    }, [driverVM]);

    const resolvers = {
        resolveCallbackMap: {
            uploadfile
        },
        resolveComponentMap: {}
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={driverVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            onValueChange={onValueChange}
            resolveValue={readValue}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

CADriverComponent.propTypes = {
};
CADriverComponent.defaultProps = {
};
export default CADriverComponent;
