import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import React, { useState } from 'react';
import IMScheduleDetailsCovTermField from '../../../../../components/IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsCovTermField';
import IMScheduleDetailsPropertyField from '../../../../../components/IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsPropertyField';
import messages from './CoveredPremisesScheduleItemForm.messages';

const LegalLiabilityFieldIds = [
    'IMColdStorageLockerCoverageScheduleLegalLiabilityLimitPerLocker',
    'IMColdStorageLockerCoverageScheduleLegalLiabilityLimitAllLockers',
];
const DirectCoverageFieldIds = [
    'IMColdStorageLockerCoverageScheduleDirectCoverageLimitPerLocker',
    'IMColdStorageLockerCoverageScheduleDirectCoverageLimitAllLockers',
];

const CoveredPremisesScheduleItemForm = (props) => {
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const {
        propertyInfos,
        coveragePropertyInfos,
        scheduleItem: originScheduleItem,
        handleScheduleItemCancel,
        handleSaveScheduleItem,
        isEditable,
        isEditing,
        setIsEditing,
    } = props;

    const validationID = `CoveredPremisesScheduleItem_${originScheduleItem.publicId_Ext}`;

    const { onValidate, isComponentValid } = useValidation(validationID);

    const [scheduleItem, setScheduleItem] = useState(originScheduleItem);

    const setScheduleItemFunc = (setPropertyFieldFunc) => {
        const newScheduleItem = setPropertyFieldFunc(scheduleItem);
        setScheduleItem(newScheduleItem);
    };

    const propertyFieldComponents = propertyInfos
        .sort((a, b) => a.order - b.order)
        .map((propertyInfo) => {
            const propertyInfoID = propertyInfo.id;
            const propertyInfoItemData = scheduleItem.itemData[propertyInfoID];
            return (
                <IMScheduleDetailsPropertyField
                    id={propertyInfoID}
                    key={propertyInfoID}
                    setScheduleItemFunc={setScheduleItemFunc}
                    propertyInfo={propertyInfo}
                    onValidate={onValidate}
                    propertyInfoItemData={propertyInfoItemData}
                    showErrors={showErrors}
                    isEditable={isEditable}
                    setIsEditing={setIsEditing}
                />
            );
        });

    const termFieldComponents = coveragePropertyInfos
        .filter((termPropertyInfo) => {
            if (
                termPropertyInfo.id ===
                'IMColdStorageLockerCoverageScheduleLocation'
            ) {
                return false;
            }

            const coverageType = scheduleItem.scheduleItemTerms.find(
                (t) =>
                    t.code_Ext === 'IMColdStorageLockerCoverageScheduleCoverage'
            );
            const coverageTypeCode = _.get(coverageType, 'chosenTerm');
            if (LegalLiabilityFieldIds.includes(termPropertyInfo.id)) {
                return coverageTypeCode === 'LegalLiabilityLimit';
            }
            if (DirectCoverageFieldIds.includes(termPropertyInfo.id)) {
                return coverageTypeCode === 'DirectCoverageLimit';
            }
            return true;
        })
        .sort((a, b) => a.order - b.order)
        .map((termPropertyInfo) => {
            const termCode = termPropertyInfo.id;
            const term = scheduleItem.scheduleItemTerms.find(
                (t) => t.code_Ext === termCode
            );
            if (_.isNil(term)) {
                return null;
            }
            return (
                <IMScheduleDetailsCovTermField
                    key={termCode}
                    term={term}
                    setScheduleItemFunc={setScheduleItemFunc}
                    onValidate={onValidate}
                    showErrors={showErrors}
                    isEditable={isEditable}
                    setIsEditing={setIsEditing}
                />
            );
        });

    return (
        <>
            <h4>{translator(messages.CoveredPremises)}</h4>
            {propertyFieldComponents}
            {termFieldComponents}
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    variant="secondary"
                    onClick={handleScheduleItemCancel}
                    label={translator(messages.Cancel)}
                />

                <Button
                    onClick={() => handleSaveScheduleItem(scheduleItem)}
                    disabled={!isComponentValid}
                    label={translator(messages.SaveAndContinue)}
                />
            </Flex>
        </>
    );
};

export default CoveredPremisesScheduleItemForm;
