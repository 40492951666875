import { ConfigUtil } from 'wni-portals-util-js';
import PEURLs from './URLConfig.ProducerEngage';
import AOURLs from './URLConfig.AgentsOnline';

const aoURL = ConfigUtil.getBrandedAOUrlConfig();

let enableProducerEngageWithAgentsOnline = false;
const updateEnableProducerEngageWithAgentsOnline = (isEnable) => {
    enableProducerEngageWithAgentsOnline = isEnable;
};

/**
 * Get ProducerEngage (Portal) URL String
 * @param {string} entryKey
 * @param {array} params Optional
 * @returns {string}
 */
function getPEURL(entryKey, ...params) {
    const urlGetter = PEURLs[entryKey];
    if (!urlGetter) {
        return null;
    }

    const url = urlGetter(...params);

    return url;
}

function getPEURLObj(entryKey, ...params) {
    return {
        to: getPEURL(entryKey, ...params)
    };
}

function goToPEPage(history, entryKey, ...params) {
    if (!history) {
        return;
    }
    const url = getPEURL(entryKey, ...params);
    history.push(url);
}


/**
 * Get AgentsOnline URL String
 * @param {string} entryKey
 * @param {array} params Optional
 * @returns {string}
 */
function getAOURL(entryKey, ...params) {
    const urlGetter = AOURLs[entryKey];
    if (!urlGetter) {
        return null;
    }

    const url = urlGetter(...params);
    return aoURL + url;
}

function getAOURLObj(accountType, entryKey, ...params) {
    if(accountType === 'company' || !enableProducerEngageWithAgentsOnline) {
        return getPEURLObj(entryKey, ...params)
    }
    return {
        href: getAOURL(entryKey, ...params)
    };
}

function goToAOPage(accountType, history, entryKey, ...params) {
    if(accountType === 'company' || !enableProducerEngageWithAgentsOnline) {
        return goToPEPage(history, entryKey, ...params)
    }
    const url = getAOURL(entryKey, ...params);
    window.location.replace(url);
}


const InteractionModes = [
    {
        name: 'Only Producer Engage inside',
        code: 'PE',
        // getURL: (entryKey, ...params) => getPEURL(entryKey, ...params),
        getURLObj: (accountType, entryKey, ...params) => getPEURLObj(entryKey, ...params),
        goToPage: (accountType, history, entryKey, ...params) => goToPEPage(history, entryKey, ...params),
    },
    {
        name: 'Producer Engage with Agents Online',
        code: 'AO',
        // getURL: (entryKey, ...params) => getAOURL(entryKey, ...params),
        getURLObj: (accountType, entryKey, ...params) => getAOURLObj(accountType, entryKey, ...params),
        goToPage: (accountType, history, entryKey, ...params) => goToAOPage(accountType, history, entryKey, ...params),
    }
];

export default InteractionModes;
export {
    updateEnableProducerEngageWithAgentsOnline
};
