import React, { useEffect, useState } from "react";
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { WniCheckboxField, WniDropdownSelect, WniInputNumber, WniInputNumberWithAction, WniTextArea, WniToggle } from "wni-common-base-components";
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import messages from './DroneSupplementalCoverages.messages'

const SupplementalCoverageCheckBox = (props) => {
    const {
        path,
        label,
        isEditable,
        updateSupplementalCoverages,
        supplementalCoverages,
    } = props

    return <WniCheckboxField
        id={`${path}_Checkbox`}
        label= {label}
        readOnly={!isEditable}
        onValueChange={(value) => updateSupplementalCoverages(value, path)}
        checked={supplementalCoverages[path]}
        labelPosition = 'right'
        showInlineLabel
        value={supplementalCoverages[path]}

    />
}

const SupplementalCoverageLimit = (props) => {

    const translator = useTranslator();

    const {
        path,
        rangePath,
        isEditable,
        updateSupplementalCoverages,
        supplementalCoverages,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props

    const id = `${path}_DropdownSelect`

    const { onValidate, isComponentValid } = useValidation(id);

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id)
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id)
            }
        }
        
    }, [id, isComponentValid, onDroneCoverageValidate])



    return <WniDropdownSelect
        id={id}
        label= {translator(messages.Limit)}
        readOnly={!isEditable}
        onValueChange={(value) => updateSupplementalCoverages(value, path)}
        value={supplementalCoverages[path]}
        availableValues={supplementalCoverages[rangePath]}
        onValidationChange={onValidate}
        showErrors={showErrors}
    />
}

const SupplementalCoverageOther = (props) => {

    const {
        path,
        labelPath,
        isEditable,
        setIsEditing,
        updateSupplementalCoverages,
        supplementalCoverages,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props

    const id = `${path}_InputNumber`

    const { onValidate, isComponentValid } = useValidation(id);

    const originValue = supplementalCoverages[path]

    const [editingValue, setEditingValue] = useState(originValue)

    useEffect(() => {
        setEditingValue(originValue)
    }, [originValue])

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id)
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id)
            }
        }
        
    }, [id, isComponentValid, onDroneCoverageValidate])



    return <WniInputNumber
        id={id}
        label= {supplementalCoverages[labelPath]}
        readOnly={!isEditable}
        onValueChange={(value) => {
            setEditingValue(value)
            setIsEditing(true)
        }}
        onBlur={() => {
            setIsEditing(false)
            updateSupplementalCoverages(editingValue, path)
        }}
        showFractions={false}
        required
        showRequired
        value={editingValue}
        onValidationChange={onValidate}
        showErrors={showErrors}
    />
}

const SupplementalCoverageOtherWaitingPeriod = (props) => {

    const translator = useTranslator();

    const {
        path,
        isEditable,
        setIsEditing,
        updateSupplementalCoverages,
        supplementalCoverages,
        onValidate: onDroneCoverageValidate,
        showErrors,
    } = props

    const id = `${path}_WaitingPeriod`

    const { onValidate, isComponentValid } = useValidation(id);

    const originValue = supplementalCoverages[path]

    const [editingValue, setEditingValue] = useState(originValue)

    useEffect(() => {
        setEditingValue(originValue)
    }, [originValue])

    useEffect(() => {
        if (onDroneCoverageValidate) {
            onDroneCoverageValidate(isComponentValid, id)
        }
        return () => {
            if (onDroneCoverageValidate) {
                onDroneCoverageValidate(true, id)
            }
        }
        
    }, [id, isComponentValid, onDroneCoverageValidate])



    return <WniInputNumberWithAction
        id={id}
        label= {translator(messages.WaitingPeriod)}
        readOnly={!isEditable}
        onValueChange={(value) => {
            setEditingValue(value)
            setIsEditing(true)
        }}
        onBlur={() => {
            setIsEditing(false)
            updateSupplementalCoverages(editingValue, path)
        }}
        showFractions={false}
        value={editingValue}
        onValidationChange={onValidate}
        showErrors={showErrors}
        actionVisible
        actionConfig={{text: translator(messages.hours)}}
    />
}

const DroneSupplementalCoverages = (props) => {

    const translator = useTranslator();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };
    
    const {
        supplementalCoverages,
        setSupplementalCoverages,
        updateSupplementalCoveragesService,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate,
    } = props

    const updateSupplementalCoverages = async (value, path) => {
        const supplementalCoveragesForUpdate = {
            [path]: value
        }

        const newSupplementalCoverages = await withLoadingMask(() => updateSupplementalCoveragesService(supplementalCoveragesForUpdate))
        setSupplementalCoverages(newSupplementalCoverages)
    }

    const commmonSupplementalCoverageCheckBoxProps = {
        isEditable,
        updateSupplementalCoverages,
        supplementalCoverages,
    }

    const commonSupplementalCoverageLimitProps = {
        isEditable,
        updateSupplementalCoverages,
        supplementalCoverages,
        onValidate,
        showErrors,
    }

    const commonSupplementalCoverageOtherProps = {
        isEditable,
        setIsEditing,
        updateSupplementalCoverages,
        supplementalCoverages,
        onValidate,
        showErrors,
    }

    return <>
        <h4>{translator(messages.SupplementalCoverages)}</h4>
        <SupplementalCoverageCheckBox
            path="isCRLeasePayments"
            label={translator(messages.ContinuingRentalorLeasePayments)}
            {...commmonSupplementalCoverageCheckBoxProps}
        />
        {supplementalCoverages.isCRLeasePayments && <div>
            <SupplementalCoverageLimit
                path="continuingRentalorLeasePaymentsCode"
                rangePath="continuingRentalorLeasePaymentsRange"
                {...commonSupplementalCoverageLimitProps}
            />
            {supplementalCoverages.isContinuingRentalorLeasePaymentsOtherVisible && <SupplementalCoverageOther
                path='continuingRentalorLeasePaymentsOther'
                labelPath='continuingRentalorLeasePaymentsOtherLabel'
                {...commonSupplementalCoverageOtherProps}
            />}
            <SupplementalCoverageOtherWaitingPeriod
                path='continuingRentalorLeasePaymentsWaitingPeriod'
                {...commonSupplementalCoverageOtherProps}
            />
        </div>}
        <SupplementalCoverageCheckBox
            path="isPCAndRemoval"
            label={translator(messages.PollutantCleanupAndRemoval)}
            {...commmonSupplementalCoverageCheckBoxProps}
        />
        {supplementalCoverages.isPCAndRemoval && <div>
            <SupplementalCoverageLimit
                path="pollutantCleanupAndRemovalCode"
                rangePath="pollutantCleanupAndRemovalRange"
                {...commonSupplementalCoverageLimitProps}
            />
            {supplementalCoverages.isPollutantCleanupAndRemovalOtherVisible && <SupplementalCoverageOther
                path='pollutantCleanupAndRemovalOther'
                labelPath='pollutantCleanupAndRemovalOtherLabel'
                {...commonSupplementalCoverageOtherProps}
            />}
        </div>}
        <SupplementalCoverageCheckBox
            path="isRentalReimbursement"
            label={translator(messages.RentalReimbursement)}
            {...commmonSupplementalCoverageCheckBoxProps}
        />
        {supplementalCoverages.isRentalReimbursement && <div>
            <SupplementalCoverageLimit
                path="rentalReimbursementCode"
                rangePath="rentalReimbursementRange"
                {...commonSupplementalCoverageLimitProps}
            />
            {supplementalCoverages.isRentalReimbursementOtherVisible && <SupplementalCoverageOther
                path='rentalReimbursementOther'
                labelPath='rentalReimbursementOtherLabel'
                {...commonSupplementalCoverageOtherProps}
            />}
            <SupplementalCoverageOtherWaitingPeriod
                path='rentalReimbursementWaitingPeriod'
                {...commonSupplementalCoverageOtherProps}
            />
        </div>}
        <SupplementalCoverageCheckBox
            path="isSparePartsAndTools"
            label={translator(messages.SparePartsAndTools)}
            {...commmonSupplementalCoverageCheckBoxProps}
        />
        {supplementalCoverages.isSparePartsAndTools && <div>
            <SupplementalCoverageLimit
                path="sparePartsAndToolsCode"
                rangePath="sparePartsAndToolsRange"
                {...commonSupplementalCoverageLimitProps}
            />
            {supplementalCoverages.isSparePartsAndToolsOtherVisible && <SupplementalCoverageOther
                path='sparePartsAndToolsOther'
                labelPath='sparePartsAndToolsOtherLabel'
                {...commonSupplementalCoverageOtherProps}
            />}
        </div>}
        {supplementalCoverages.isElectricalDisturbanceVisible && <WniToggle
            id='electricalDisturbance'
            label= {translator(messages.ElectricalDisturbance)}
            readOnly={!isEditable}
            onValueChange={(value) => updateSupplementalCoverages(value, 'electricalDisturbanceCode')}
            value={supplementalCoverages.electricalDisturbanceCode}
            availableValues={supplementalCoverages.electricalDisturbanceRange}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />}
        <WniTextArea
            id='droneAdditionalInformation'
            label= {translator(messages.AdditionalInformation)}
            readOnly={!isEditable}
            onValueChange={(value) => {
                setIsEditing(true)
                setSupplementalCoverages({
                    ...supplementalCoverages,
                    droneAdditionalInformation: value
                })
            }}
            onBlur={() => {
                setIsEditing(false)
                updateSupplementalCoverages(supplementalCoverages.droneAdditionalInformation, 'droneAdditionalInformation')
            }}
            value={supplementalCoverages.droneAdditionalInformation}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    </>
}

export default DroneSupplementalCoverages
