
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext, useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';



import { formatDate, formatNumber, FormattedDate, FormattedNumber } from '@jutro/components';

import {
    ActionColumn,
    ActionItem,
    DataTable,
    defaultColumnFilter,
    DisplayColumn,
    FieldColumn,
    RadioColumn,
    useDataFilter,
} from '@jutro/legacy/datatable';

import { Button } from '@jutro/legacy/components';

/**
 * Shows PolicyLineSummaryClauseDTO[] in a DataTable.
 * 
 * Sample Clauses Data:
  * [
 * 	 {
 * 		"description": "Experience Mod",
 * 		"terms": [
 * 			{
 * 				"name": "Factor",
 * 				"chosenTermValue": "1"
 * 			},
 * 			{
 * 				"name": "Rating Effective Date",
 * 				"chosenTermValue": ""
 * 			}
 * 		]
 * 	},
 * 	{
 * 		"description": "Catastrophe (Other Than Certified Acts of Terrorism) Premium Endorsement"
 * 	}
 * ]
 * @param {object} props
 * @returns {object} React Component
 */


function extractTermDTO (termDTOParam, termFormatter) {
    let termDTO = termDTOParam;
    if (_.isFunction(termFormatter)) {
        termDTO = termFormatter(termDTOParam);
    }

    const {
        name,
        chosenTermValue,
    } = termDTO;
    return {
        description: name,
        value: chosenTermValue,
    };
}

function extractClauseDTO(clauseDTO) {
    const {
        description,
        value = '', // might come from DisplayableSummaryComponent
        // terms,
    } = clauseDTO;
    return {
        description,
        value,
        //
        showBold: true,
    };
}

function clauseDTOExtractor(clause, { termFormatter } = {}) {
    const retval = [];
    const {
        terms,
    } = clause;
    
    const covRow = extractClauseDTO(clause);
    retval.push(covRow);

    if (!_.isEmpty(terms)) {
        terms.forEach((term) => {
            const termRow = extractTermDTO(term, termFormatter);
            retval.push(termRow);
        });
    }

    return retval;
}
function ScheduleItemComponent(props) {
    const {
        scheduledItem,
    } = props;

    return null;
}

function ScheduleDTOComponent(props) {
    const {
        schedule,
    } = props;

    if (_.isEmpty(schedule)) {
        return null;
    }

    const {
        description,
        scheduledItems = [],
    } = schedule;



    if (_.isEmpty(scheduledItems)) {
        return null;
    }

    const renderCell = (columnField) => {
        return (rowData, ...params) => {
            // const retval = rowData[columnField];
            // console.log(params);
            const {
                [columnField]: columnData,
                showBold,
            } = rowData;
            if (showBold) {
                return <strong>{columnData}</strong>;
            }
            return columnData;
        };
    };
    const renderTitle = () => {
        if(_.isEmpty(description)) {
            return null;
        }
        return <h4>{description}</h4>;
    };

    const extractTableColumnsAndData = () => {

        
        const termsList = scheduledItems.map((item) => item.terms).filter((terms) => !_.isEmpty(terms));

        if (_.isEmpty(termsList)) {
            return null;
        }

        
        const columns = [];
        termsList[0].forEach((term) => columns.push(term.name));

        const tableData = [];
        for(const terms of termsList) {
            
            const tableRecord = {};
            terms.forEach((term) => {
                const {
                    name,
                    chosenTermValue,
                } = term;
                tableRecord[name] = chosenTermValue;
            });

            tableData.push(tableRecord);
        }

        return {
            columns,
            tableData,
        };
    }

    const renderScheduledItemsTable = () => {

        const tableColumnsAndData = extractTableColumnsAndData();

        if (!tableColumnsAndData) {
            return null;
        }

        const {
            columns = [],
            tableData,
        } = tableColumnsAndData;

        const tableColumns = columns.map((column) => (
            <DisplayColumn
                key={column}
                value={column}
                header={column}
                renderCell={renderCell(column)}
            />
        ));

        return (
            <DataTable
                id={`scheduledItemTable-${description}`}
                data={tableData}
                tableLabel={`scheduledItemTable-${description}`}
                // onRowClick={onRowClick}
                // selectOnRowClick
                showSearch={false}
            >
                {tableColumns}
            </DataTable>
        );
    };

    return (
        <div>
            {renderTitle()}
            {renderScheduledItemsTable()}
        </div>
    );
}

function ScheduleSummaryComponent(props) {
    const {
        schedules,
        // title,
    } = props;
    
    if (_.isEmpty(schedules)) {
        return null;
    }

    //
    const retval = schedules.map((schedule) => <ScheduleDTOComponent schedule={schedule} />);
    return retval;
}

ScheduleSummaryComponent.propTypes = {
    clauses: PropTypes.shape({}).isRequired,
    //
    // tableRowExtractor: PropTypes.func,
    showClauseInBold: PropTypes.bool,
    title: PropTypes.string,
    termFormatter: PropTypes.func,
};

ScheduleSummaryComponent.defaultProps = {
    // tableRowExtractor: clauseDTOExtractor,
    showClauseInBold: true,
    title: undefined,
    termFormatter: undefined,
};

export default ScheduleSummaryComponent;
