import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import React from 'react';

const AccordionCardTitle = (props) => {
    const { title, actionText, onClick } = props;
    const translator = useTranslator();
    return (
        <Flex justifyContent="between">
            <div>{translator(title)}</div>
            <Button
                variant="primary"
                icon="gw-add"
                onClick={(e) => {
                    e.stopPropagation()
                    onClick()
                }}

                label={actionText}
            />
        </Flex>
    );
};

export default AccordionCardTitle;
