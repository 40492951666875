import { defineMessages } from 'react-intl';

export default defineMessages({
    additionalCoveragesTitle: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Additional Coverages',
        defaultMessage: 'Selectable Additional Coverages',
    },
    exclusionsTitle: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Exclusions',
        defaultMessage: 'Selectable Exclusions',
    },
    defaultTitle: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Conditions and Exclusions',
        defaultMessage: 'Selectable Conditions and Exclusions',
    },
    coveragesTitle: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Search Coverages For Inland Marine',
        defaultMessage: 'Search Coverages For Inland Marine',
    },
    exclusionsAndConditionsTitle: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Search Exclusions & Conditions For Inland Marine',
        defaultMessage: 'Search Exclusions & Conditions For Inland Marine',
    },
    additionalCoveragesAddButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Add All Selected Coverages',
        defaultMessage: 'Add All Selected Coverages',
    },
    coveragesAddButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Add All Selected Coverages',
        defaultMessage: 'Add All Selected Coverages',
    },
    exclusionsAddButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Add All Selected Exclusions',
        defaultMessage: 'Add All Selected Exclusions',
    },
    addButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Add All Selected Exclusions and Conditions',
        defaultMessage: 'Add All Selected Exclusions and Conditions',
    },
    exclusionsAndConditionsAddButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Add Selected Exclusions and Conditions',
        defaultMessage: 'Add Selected Exclusions & Conditions',
    },
    additionalCoveragesButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Display All Selectable Additional Coverages',
        defaultMessage: 'Additional Coverages',
    },
    exclusionsButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Display All Selectable Exclusions',
        defaultMessage: 'Exclusions',
    },
    displayAllConditionsAndExclusionsButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Display All Selectable Conditions and Exclusions',
        defaultMessage: 'Display All Selectable Conditions and Exclusions',
    },
    coveragesButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Display All Selectable Coverages',
        defaultMessage: 'Display All Selectable Coverages',
    },
    exclusionsAndConditionsButton: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.Display All Selectable Exclusion and Condition',
        defaultMessage: 'Display All Selectable Exclusion and Condition',
    },
    coveragesHeader: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.header.Coverage',
        defaultMessage: 'Coverage',
    },
    additionalCoveragesHeader: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.header.Coverage',
        defaultMessage: 'Coverage',
    },
    exclusionsHeader: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.header.Exclusion',
        defaultMessage: 'Exclusion',
    },
    exclusionsAndConditionsHeader: {
        id: 'wni.im.wizard.commercialProperty.coveragePopup.header.Exclusion and Condition',
        defaultMessage: 'Exclusion and Condition',
    },
});
