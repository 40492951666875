import _ from 'lodash';
import React from 'react';
import { IMPolicyDetailsChangeService } from 'wni-capability-policychange-im';
import { IMPolicyDetailsPage } from 'wni-capability-quoteandbind-im-react';

const IMPolicyDetailsChangePage = (props) => {
    const { wizardData: submissionVM } = props;

    const changeData = _.get(submissionVM.value, 'changeData');

    const savePolicyDetailsData = async (
        quoteID,
        sessionUUID,
        imPolicyDetailsData,
        // questionSetData,
        quoteBaseData,
        authHeader
    ) => {
        return IMPolicyDetailsChangeService.saveIMPolicyDetailsData(
            quoteID,
            sessionUUID,
            imPolicyDetailsData,
            quoteBaseData,
            changeData,
            authHeader
        );
    };
    return (
        <IMPolicyDetailsPage
            {...props}
            savePolicyDetailsData={savePolicyDetailsData}
            isPolicyChange
        />
    );
};
export default IMPolicyDetailsChangePage;
