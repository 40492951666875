import React, { useState } from 'react';
import _ from 'lodash';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './IMLocationDetailModal.metadata.json5';
import { act } from 'react';

function IMLocationDetailModal(props) {
    const {
        size,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        title,
        isReadOnly,
        showErrors,
        locationVM,
        viewModelService,
        useAuthenticationData,
        useDependenciesData,
        IMBuildingsAndLocationsService,
        extendProps: {
            jobID,
            sessionUUID,
            authHeader
        },
    } = props;

   const [getLocationVM, updateLocationVM] = useState(locationVM);
   const {
        onValidate, 
        isComponentValid,
    } = useValidation('IMLocationDetailModal');
    const {
        loadingMask: { setLoadingMask },
    } = useDependenciesData;

    const handleSave = async () => {
        setLoadingMask(true);
        const res = await IMBuildingsAndLocationsService.updateLocation(jobID, sessionUUID, getLocationVM.value, authHeader);
        setLoadingMask(false);
        onResolve(res);
    }

    const onValueChange = (value, path) => {
        const newLocationVM = viewModelService.clone(getLocationVM);
        _.set(newLocationVM, path, value);
        updateLocationVM(newLocationVM);
    }

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left',
            showRequired: true,
            readOnly: isReadOnly
        },
        addressLookup: {
            model: getLocationVM,
            dataPath: 'address',
            onAddressChange: onValueChange,
            hideFieldType: {
                pobox: true
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly,
            useAuthenticationData
        },
    };
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            addressinput: AddressInputComponent,
        },
    };

    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalHeader title={title} />
            <ModalBody id="imLocationDetailModal">
                <ViewModelForm
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    model={getLocationVM}
                    onValueChange={onValueChange}
                    onValidationChange={onValidate}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} variant="secondary" label={cancelBtnLabel} />
                <Button onClick={handleSave} disabled={!isComponentValid} label={actionBtnLabel} />
            </ModalFooter>
        </ModalNext>
    );
}

IMLocationDetailModal.propTypes = {
   
};
IMLocationDetailModal.defaultProps = {
    size: 'md'
};
export default IMLocationDetailModal;
