import React from 'react';
import CoveragePartSchedule from './CommonCoveragePartSchedule/CommonCoveragePartSchedule';

const CommonCovPartSchedule = (props) => {

    const {
        id: scheduleId,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        wizardData: submissionVM,
        coveragePartSchedule,
        updateScheduleService,
        schedulePath,
        scheduleLable,
        scheduleFormHeader,
        onValidate,
        visibleOfDivider
    } = props




    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    return (
            <div className="formCoveragesContainer">
                {coveragePartSchedule && <CoveragePartSchedule
                    jobID={jobID}
                    sessionUUID={sessionUUID}
                    schedule={coveragePartSchedule}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isEditable={isEditable}
                    setCoveragePartClauses={setCoveragePartClauses}
                    updateScheduleService={updateScheduleService}
                    schedulePath={schedulePath}
                    scheduleLable={scheduleLable}
                    scheduleFormHeader={scheduleFormHeader}
                    onValidate={onValidate}
                    scheduleId={scheduleId}
                    visibleOfDivider={visibleOfDivider}
                />}
            </div>
    )
}

export default CommonCovPartSchedule