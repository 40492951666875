import { Button } from '@jutro/components';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex } from '@jutro/layout';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { WniCheckboxField, WniDataTable } from 'wni-common-base-components';
import CoverageConfigContext from '../../../context/IMCoveragesConfigContext';
import IMCoverageUtil from '../../../util/IMCoverageUtil';
import IMScheduleItemReadonlyCell from "../../IMScheduleTable/IMScheduleItemReadonlyCell";
import ScheduleItemForm from '../CommonScheduleItemForm/CommonScheduleItemForm';
import messages from './CommonCoveragePartSchedule.messages';

const CommonCoveragePartSchedule = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { authHeader } = useAuthentication();

    const {
        schedule,
        isEditable,
        setCoveragePartClauses,
        onValidate: onPageValidate,
        jobID,
        sessionUUID,
        setIsEditing,
        isEditing,
        updateScheduleService,
        scheduleId,
        schedulePath,
        scheduleLable,
        scheduleFormHeader,
        visibleOfDivider = false
    } = props;

    const {
        propertyInfos = [],
        coveragePropertyInfos = [],
        scheduleItems = [],
        deserializationClass,
        readonly_Ext: scheduleReadonly,
    } = schedule;

    const {
        scheduleConfig
    } = useContext(CoverageConfigContext)

    const visibleColumnIdsOverride = _.get(scheduleConfig, `visibleColumnIdsOverride.${scheduleId}`);

    const [selectedScheduleItemNumbers, setSelectedScheduleItemNumbers] = useState([]);
    const [openedScheduleItem, setOpenedScheduleItem] = useState();

    useEffect(() => {
        if (!_.isNil(openedScheduleItem) && !_.isEmpty(scheduleItems)) {
            const newOpenScheduleItem = scheduleItems.find(elt => elt.publicId_Ext === openedScheduleItem.publicId_Ext);
            const clonedNewOpenScheduleItem = _.cloneDeep(newOpenScheduleItem);
            setOpenedScheduleItem(clonedNewOpenScheduleItem);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleItems])

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(!IMCoverageUtil.isScheduleInvalid(schedule), scheduleId);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, scheduleId);
            }
        }
    }, [propertyInfos, scheduleId, onPageValidate, schedule, openedScheduleItem])

    const sortedScheduleItems = scheduleItems.toSorted(
        (scheduleItemA, scheduleItemB) => {
            const getScheduleNumber = (scheduleItem) => {
                return _.get(scheduleItem, 'itemNumber');
            };
            return (
                getScheduleNumber(scheduleItemA) -
                getScheduleNumber(scheduleItemB)
            );
        }
    );

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const saveScheduleItems = async (newScheduleItems) => {
        const scheduleRequest = _.clone(schedule);
        _.set(scheduleRequest, 'updated_Ext', true);
        _.set(scheduleRequest, 'scheduleItems', newScheduleItems);
        const newCoveragePartCoverages = await withLoadingMask(() =>
            updateScheduleService(
                jobID,
                sessionUUID,
                scheduleRequest,
                authHeader
            )
        );
        setCoveragePartClauses(newCoveragePartCoverages);
        const newScheduleItemsRes = _.get(newCoveragePartCoverages, `${schedulePath}.scheduleItems`);
        
        return newScheduleItemsRes;
    };

    const delSchedule = async () => {
        const newScheduleItems = scheduleItems.filter((scheduleItem) => {
            const { itemNumber } = scheduleItem;
            return !selectedScheduleItemNumbers.includes(itemNumber);
        });
        await saveScheduleItems(newScheduleItems);
        setSelectedScheduleItemNumbers([]);
        setOpenedScheduleItem(undefined);
    };

    const addSchedule = async () => {
        const newScheduleItems = scheduleItems.concat([
            {
                '@deserialization-class': deserializationClass,
                itemData: {},
            },
        ]);
        const oldScheduleItemPublicIds = scheduleItems.map(
            (item) => item.publicId_Ext
        );
        const newScheduleItemsRes = await saveScheduleItems(newScheduleItems);
        const newAddedItem = newScheduleItemsRes.find(
            (item) => !oldScheduleItemPublicIds.includes(item.publicId_Ext)
        );
        setOpenedScheduleItem(newAddedItem);
    };

    const handleScheduleItemCancel = () => setOpenedScheduleItem(undefined);

    const handleSaveScheduleItem = async (newScheduleItem) => {
        const { publicId_Ext: publicId } = newScheduleItem;

        const newScheduleItems = scheduleItems.map((scheduleItem) => {
            if (scheduleItem.publicId_Ext === publicId) {
                return newScheduleItem;
            }
            return scheduleItem;
        });
        await saveScheduleItems(newScheduleItems);
        setOpenedScheduleItem(undefined);
    };

    const scheduleNumberColumn = (
        <DisplayColumn
            key="ScheduleNumber"
            header="Schedule Number"
            sortable={false}
            textAlign="left"
            path="itemNumber"
            renderCell={(scheduleItem) => {
                const { itemNumber } = scheduleItem;
                if (!isEditable || scheduleReadonly) {
                    return itemNumber;
                }
                return (
                    <WniCheckboxField
                        value={selectedScheduleItemNumbers.includes(itemNumber)}
                        label={itemNumber}
                        showInlineLabel
                        onValueChange={(checked) => {
                            let newSelectedScheduleItemNumbers;
                            if (checked) {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.concat([
                                        itemNumber,
                                    ]);
                            } else {
                                newSelectedScheduleItemNumbers =
                                    selectedScheduleItemNumbers.filter(
                                        (num) => num !== itemNumber
                                    );
                            }
                            setSelectedScheduleItemNumbers(
                                newSelectedScheduleItemNumbers
                            );
                        }}
                    />
                );
            }}
        />
    );

    const propertyInfoColumns = propertyInfos
    .filter((propertyInfo) => {
        if (visibleColumnIdsOverride) {
            return visibleColumnIdsOverride.includes(propertyInfo.id)
        }
        return true
    })
    .map((propertyInfo) => {
        const { id, label } = propertyInfo;

        return (
            <DisplayColumn
                key={id}
                header={label}
                renderCell={(scheduleItem) => {
                    return (
                        <IMScheduleItemReadonlyCell
                            propertyInfo={propertyInfo}
                            scheduleItem={scheduleItem}
                        />
                    );
                }}
                sortable={false}
            />
        );
    });

    const termPropertyColumns = coveragePropertyInfos
        .filter((propertyInfo) => {
            if (visibleColumnIdsOverride) {
                return visibleColumnIdsOverride.includes(propertyInfo.id)
            }
            return true
        })
        .sort((a, b) => a.order - b.order)
        .map((coveragePropertyInfo) => {
            const { id, label } = coveragePropertyInfo;

            return (
                <DisplayColumn
                    key={id}
                    header={label}
                    renderCell={(scheduleItem) => {
                        const { scheduleItemTerms } = scheduleItem;
                        const term = scheduleItemTerms.find(
                            (t) => t.code_Ext === id
                        );

                        return _.get(term, 'chosenTermValue');
                    }}
                    sortable={false}
                />
            );
        });

    const actionColumn = (
        <DisplayColumn
            key="ActionColumn"
            sortable={false}
            textAlign="left"
            renderCell={(scheduleItem) => {
                return (
                    <Button
                        className="btn-link"
                        onClick={() => setOpenedScheduleItem(scheduleItem)}
                        label={messages.editOrViewschedule}
                    />
                       
                );
            }}
        />
    );

    const columns = [
        scheduleNumberColumn,
        ...propertyInfoColumns,
        ...termPropertyColumns,
    ];
    const dataTableColumns =
        isEditable && !scheduleReadonly ? [...columns, actionColumn] : columns;

    return (
        <>
            {isEditable && !scheduleReadonly && (
                <>
                    {scheduleLable && <h4>{scheduleLable}</h4>}
                    {visibleOfDivider && <hr/>}
                    <Flex gap="small" justifyContent="right" className="mb-10">
                        <div>
                            <Button
                                className="wni-button-danger"
                                disabled={selectedScheduleItemNumbers.length === 0}
                                onClick={delSchedule}
                                label={messages.scheduleDel}
                            />
                            <Button icon="gw-add" onClick={addSchedule} label={messages.scheduleAdd} />
                        </div>
                    </Flex>
                </>
                
            )}
            <div className="table-wrapper mb-10">
                <WniDataTable
                    id={`clause_schedule_table_IM${scheduleId}ScheduledCov_Ext`}
                    data={sortedScheduleItems}
                    showSearch={false}
                >
                    {dataTableColumns}
                </WniDataTable>
            </div>
            {openedScheduleItem && (
                <ScheduleItemForm
                    propertyInfos={propertyInfos}
                    coveragePropertyInfos={coveragePropertyInfos}
                    scheduleItem={openedScheduleItem}
                    handleScheduleItemCancel={handleScheduleItemCancel}
                    handleSaveScheduleItem={handleSaveScheduleItem}
                    isEditable={isEditable}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    scheduleFormHeader={scheduleFormHeader}
                    onValidate={onPageValidate}
                    scheduleId={scheduleId}
                />
            )}
            {(IMCoverageUtil.isScheduleInvalid(schedule) && _.isNil(openedScheduleItem))?
                <div className='font-error-light-16 mb-10 mt--10' >Information for this coverage is missing.</div>
                : null}
        </>
    );
};

export default CommonCoveragePartSchedule;
