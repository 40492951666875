import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { Flex } from '@jutro/layout'
import { useValidation } from '@xengage/gw-portals-validation-react';
import {React, useState, useCallback, useContext, useEffect} from 'react';
import { ValidationIssuesComponent } from 'wni-components-platform-react';
import { ValidationIssueUtil } from 'wni-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { CPRisksService, CPRetrieveRiskItemSummaryService } from 'wni-capability-quoteandbind-cp';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import BuildingClauses from '../BuildingClauses';
import metadata from '../Common/CommonRiskItemDetails.metadata.json5';

import DetailsFieldMap from '../Common/DetailsFieldMap'
import PersonalPropertyClauses from '../PersonalPropertyClauses';
import riskItemMessages from '../RiskItemComponent/RiskItemComponent.messages'
import { Button } from '@jutro/legacy/components';


const PersonalProperty = (props) => {

    const translator = useTranslator();

    const {
        onValidate,
        isComponentValid,
    } = useValidation('PersonalProperty');

    const { authHeader } = useAuthentication();
    const [personalPropertyClauses, setPersonalPropertyClauses] = useState()

    const [showErrors, updateShowErrors] = useState(false)
    const [validationIssues, updateValidationIssues] = useState([]);

    const {
        riskItem,
        jobID,
        sessionUUID,
        submissionVM,
        updateSubmissionVM,
        isReadOnly,
        handleCloseRisk,
    } = props

    const {
        locationPublicID,
        buildingPublicID,
        occupancyPublicID,
        publicID: personalPropertyPublicID   ,
        locationDescription,
        buildingDescription,
        occupancyDescription 
    } = riskItem

    const serviceProps = {
        jobID,
        sessionUUID,
        authHeader
    }

    const viewModelService = useContext(ViewModelServiceContext);
    const dtoName = 'wni.edge.capabilities.quote.lob.commercialproperty.dto.CPPersonalPropertyDTO';
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const [currentRowVM, setCurrentRowVM] = useState({});

    const getPersonalPropertyDetailsFunc = useCallback(async () => {
        const res = await CPRisksService.getPersonalPropertyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader);
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, occupancyPublicID, personalPropertyPublicID, sessionUUID])

    const updatePersonalPropertyDetailsFunc = useCallback(async (dto) => {
        const [needsRefresh, notNeedRefresh] = _.partition(dto.personalPropertyDisplayables?.filter(elt => elt.updated), 'needsRefresh');
        const orderedDisplayables = notNeedRefresh.concat(needsRefresh);
        Object.assign(dto, {'changedPersonalPropertyDisplayables': orderedDisplayables});
        const res = await CPRisksService.updatePersonalPropertyDetails(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, dto, authHeader);
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, occupancyPublicID, personalPropertyPublicID, sessionUUID])

    const getPersonalPropertyClausesFunc = useCallback(async () => {
        const res = await CPRisksService.getPersonalPropertyClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader)
        return res
    }, [authHeader, buildingPublicID, jobID, locationPublicID, occupancyPublicID, personalPropertyPublicID, sessionUUID])

    const updatePersonalPropertyClausesFunc = async (dto) => {
        const res = await CPRisksService.updatePersonalPropertyClauses(jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, dto, authHeader)
        return res
    }

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true)
        const res = await serviceCallFunc()
        setLoadingMask(false)
        return res
    }

    const initFieldData = useCallback(async () => {
        // const buidlingDetailsDto = await getBuildingDetailsFunc()
        // const newBuildingClauses = await getLocationClausesFunc()
        const [
            buidlingDetailsDto, 
            newPersonalPropertyClauses
        ] = await withLoadingMask(() => Promise.all([
            getPersonalPropertyDetailsFunc(),
            getPersonalPropertyClausesFunc()
        ]))
        
        const res = viewModelService.create(buidlingDetailsDto, 'pc', dtoName);
        setPersonalPropertyClauses(newPersonalPropertyClauses)
        setCurrentRowVM(res)
        
    // !!! Never add setLoadingMask into dependency, which always different after render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService])

    



    const updateService = async(serviceData) => {
        const {
            personalProperty: updatedDetailsDto,
            personalPropertyClauses: newPersonalPropertyClauses
        } = await withLoadingMask(() => updatePersonalPropertyDetailsFunc(serviceData));
        setPersonalPropertyClauses(newPersonalPropertyClauses);
        const displayables = updatedDetailsDto?.personalPropertyDisplayables;
        const clonedDisplayables = _.set(_.clone(updatedDetailsDto), 'personalPropertyDisplayables', displayables);
        const res = viewModelService.create(clonedDisplayables, 'pc', dtoName);
        setCurrentRowVM(res);
        return res
    };


  
    const writeValue = (fieldItem, path, fieldModel = {}) => {
        const newVm = viewModelService.clone(currentRowVM);
        _.set(newVm.value, path, fieldItem);
        setCurrentRowVM(newVm);
        _.set(currentRowVM.value, path, fieldItem);
        if(fieldModel.triggerFunc === 'onValueChange' && fieldItem.needsRefresh) {
            updateService(currentRowVM.value);
        }
    }

    const onBlur = (value, fieldItem) => {
        updateService(currentRowVM.value, value, fieldItem)
    };


    useEffect(() => {
        initFieldData()
    }, [initFieldData])

    const handleClose = async () => {
        if (isReadOnly) {
            handleCloseRisk((oldTreeRows) => oldTreeRows)
            return
        }
        if (!isComponentValid) {
            updateShowErrors(true)
            return
        }
        const newVm = viewModelService.clone(currentRowVM);
        const allUpdatedDisplayables = _.get(newVm, 'value.personalPropertyDisplayables', [])?.filter(elt => elt.updated);
        if (!_.isEmpty(allUpdatedDisplayables)) {
            updateService(newVm.value);
        }
        const buildingTree = await withLoadingMask(() => CPRetrieveRiskItemSummaryService.retrievePersonalPropertySummary(
            jobID, sessionUUID, locationPublicID, buildingPublicID, occupancyPublicID, personalPropertyPublicID, authHeader
        ))
        const occupancyIndex = buildingTree.occupancyTreeRows?.findIndex(elt => elt.publicID === occupancyPublicID);
        const newPersonalProperties = _.get(buildingTree,  `occupancyTreeRows[${occupancyIndex}].personalPropertyTreeRows`, []);
        const newPersonalProperty = newPersonalProperties?.find(elt => elt.publicID === personalPropertyPublicID);
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(newPersonalProperty.errorsAndWarnings);
        updateValidationIssues(newValidationIssues);
        const generateNewTiskTreeRowFunc = (oldRiskTreeRows) => {
            const newRiskTreeRows = _.clone(oldRiskTreeRows)
            const locationIndex = oldRiskTreeRows.findIndex(location => location.publicID === locationPublicID)
            const location = oldRiskTreeRows[locationIndex]
            const buildings = location.buildingTreeRows
            const buildingIndex = buildings.findIndex(building => building.publicID === buildingPublicID)

            _.set(
                newRiskTreeRows, 
                `[${locationIndex}].buildingTreeRows[${buildingIndex}]`, 
                buildingTree
            )

            return newRiskTreeRows
        }
        if (!_.isEmpty(newValidationIssues)) {
            updateShowErrors(true)
            return
        }
        handleCloseRisk((oldTreeRows) => generateNewTiskTreeRowFunc(oldTreeRows))
    }
    
    const overrideProps = {
        fieldSetModel: {
            vm: currentRowVM,
            dataPath: 'personalPropertyDisplayables',
            // submissionVM,
            onValueChange: writeValue,
            // onSearch,
            onValidate,
            onBlur,
            showErrors,
            isReadOnly,
            // filterSizeClassMapsInChange
        },
        riskItemDetailsCard: {
            title: 'Personal Property Details'
        },
        locationInput: {
            visible: true,
            value: locationDescription
        },
        buildingInput: {
            visible: true,
            value: buildingDescription
        },
        occupancyInput: {
            visible: true,
            value: occupancyDescription
        }
    }
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            fieldsetmap: DetailsFieldMap
        }
    };
    return (
        <>
            <ValidationIssuesComponent validationIssues={validationIssues}/>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                // onValueChange={onValueChange}
                onValidationChange={onValidate}
                // resolveValue={readValue}
                // classNameMap={resolvers.resolveClassNameMap}
                // callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
            <PersonalPropertyClauses
                riskItem={riskItem}
                serviceProps={serviceProps}
                submissionVM={submissionVM}
                updateSubmissionVM={updateSubmissionVM}
                updateClauseFunc={updatePersonalPropertyClausesFunc}
                riskClauses={personalPropertyClauses}
                setRiskClauses={setPersonalPropertyClauses}
                showErrors={showErrors}
                isEditable={!isReadOnly}
                onValidate={onValidate}
            />
            <Flex justifyContent="right">
                <Button onClick={handleClose}>
                    {translator(riskItemMessages.Close)}
                </Button>
            </Flex>
        </>
    );
}

export default PersonalProperty