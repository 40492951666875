import React from 'react';
import PropTypes from 'prop-types';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { WniAccountsUtil } from 'wni-portals-util-js';
import { useBusinessData } from 'wni-portals-util-react';
import metadata from './BackLinkComponent.metadata.json5';

const BackLinkComponent = (props) => {
    const { interactionModel } = useDependencies('interactionModel');
    const { enableCommercialLine } = useBusinessData();

    const {
        accountNumber,
        accountType,
        history,
        showBackToAccountBillingAndPaymentLink,
        justifyContent
    } = props;

    const goToAllQuotes = () => {
        history.push({
            pathname: '/policies',
            state: {
                selectedTile: 'quotes',
                initStatus: 'Quotes',
            },
        });
    };

    const goToAccountSummary = () => {
        if (enableCommercialLine) {
            return history.push(`/accounts/${accountNumber}/summary`)
        }
        return history.push(`/internal/account-quotes/${accountNumber}`)
    };

    const goToAccountBillingAndPayment = () => {
        return history.push(`/accounts/${accountNumber}/billingAndPayment`);
    };

    const resolvers = {
        resolveCallbackMap: {

        },
        resolveComponentMap: {},
    };
    const overrideProps = {
        BackToContainer: {
            justifyContent: justifyContent
        },
        backToAccountBillingAndPayment: {
            visible: showBackToAccountBillingAndPaymentLink,
            onClick: goToAccountBillingAndPayment
        },
        // BackToAgentsOnline: {
        //     // jump to AO for both CL and PL
        //     ...interactionModel.getURLObj(null, 'accountSummary', accountNumber)
        // },
        BackToAccountSummary: {
            onClick: goToAccountSummary
        },
        BackToAllQuotes: {
            onClick: goToAllQuotes
        }
    };
    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers} />
    );
};


BackLinkComponent.propTypes = {
    accountNumber: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    showBackToAccountBillingAndPaymentLink: PropTypes.bool,
    justifyContent: PropTypes.string
};

BackLinkComponent.defaultProps = {
    showBackToAccountBillingAndPaymentLink: false,
    justifyContent: 'left'
};

export default BackLinkComponent;
