import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import React from 'react';
import _ from 'lodash';
import metadata from './CoverageFormSelection.metadata.json5';

function CoverageFormSelection(props) {
    const { vm, writeValue, isReadOnly, syncCoverageForm } = props;

    const isVisible = (path) => {
        return _.get(vm, `${path}Visible.value`);
    };

    const isDisabled = (path) => {
        return !_.get(vm, `${path}.available.value`);
    };

    if (!vm) {
        return null;
    }
    const overrideProps = {
        '@field': {
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly
        },
        contractorsEquipPart: {
            visible: isVisible('contractorsEquipPart')
        },
        scheduledEquipmentForm: {
            disabled: isDisabled('scheduledEquipmentForm'),
            onValueChange: syncCoverageForm
        },
        blanketEquipmentForm: {
            disabled: isDisabled('blanketEquipmentForm'),
            onValueChange: syncCoverageForm
        },
        smallToolsFloater: {
            disabled: isDisabled('smallToolsFloater'),
            onValueChange: syncCoverageForm
        },
        miscFloatersPart: {
            visible: isVisible('miscFloatersPart')
        },
        miscFormsPart: {
            visible: isVisible('miscFormsPart')
        },
        transitPart: {
            visible: isVisible('transitPart')
        }
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.componentContent,
            vm,
            id,
            path,
            overrideProps
        );
    };

    const resolvers = {
        callbackMap: {},
        componentMap: {},
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vm}
            overrideProps={overrideProps}
            resolveValue={readValue}
            onValueChange={writeValue}
            {...resolvers}
        />
    );
}

export default CoverageFormSelection;
