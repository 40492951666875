import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies, withDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Loader } from '@jutro/components';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { useWniModal } from 'wni-components-platform-react';
import { useBusinessData } from 'wni-portals-util-react';
import UWUtil from 'gw-capability-gateway-react/Components/UnderwritingComponent/UWUtil';
import { useTranslator } from '@jutro/locale';
import { JobService } from 'gw-capability-gateway';
import { WniPolicyService, WniRenewalService, WniSubmissionService } from 'wni-capability-gateway';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import gatewayMessages from 'gw-capability-gateway-react/gateway.messages';
import styles from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.module.scss';
import messages from 'gw-capability-gateway-react/QuoteDetails/Summary/Summary.messages';
import cx from 'classnames';
import { DocumentDownloadService } from '@xengage/gw-portals-document-js';
import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { SuccessNotification, Link as LinkComponent } from 'gw-components-platform-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';


import { ErrorsAndWarningsUtil, DocumentsUtil, SummaryUtil, WniProductsUtil } from 'wni-portals-util-js';
import { UnderIssuesTableComponent, DocumentCheckedComponent } from 'wni-capability-gateway-react';
import UnderwritingComponent from '../../Components/UnderwritingComponent/UnderwritingComponent';
import UnderwritingMessage from './UnderwritingMessage/UnderwritingMessage';
import BackLinkComponent from '../../Components/BackLinkComponent/BackLinkComponent';
import metadata from './RenewalSummary.metadata.json5';
import localMessages from './RenewalSummary.messages';
import interalStyles from './RenewalSummary.module.scss';

import { Link as GWLink } from '@jutro/router';

function DownloadDocumentComponent(props) {
    const { documents, docType } = props;

    const downloadDocument = (sessionID, publicID) => {
        // const { authHeader, history } = props;
        const serviceUrl = getProxiedServiceUrl('downloadDocument');
        const templateDownloadEndpointURL = serviceUrl.concat(
            `/${publicID}?token=${sessionID}`
        );
        const params = {
            method: 'GET',
            url: templateDownloadEndpointURL,
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        };

        const errorCallback = () => {
            // history.push('/documenterror');
        };

        DocumentDownloadService.getDocument(
            templateDownloadEndpointURL,
            params,
            errorCallback
        );
    };
    if (documents == null) {
        return null;
    }
    return documents.map((doc) => {
        if (docType === doc.docType_Ext) {
            const { name, publicID, sessionID } = doc;
            return (
                <div className={cx(interalStyles['new-line'])}>
                    <GWLink
                        className={cx(interalStyles['download-link'])}
                        onClick={() => {
                            downloadDocument(sessionID, publicID);
                        }}
                        id={publicID}
                    >
                        {name}
                    </GWLink>
                </div>
            );
        }
        return null;
    });
}

function RenewalSummary(props) {
    const modalApi = useWniModal();
    const {
        // fromAccountLanding,
        fromAccountLanding: {
            quoteDetailsData: {
                updateJobSummary,
                loadRenewalSummary: job
            },
        },
        authHeader,
        history,
        authUserData,
        interactionModel,
    } = props;

    const {
        createUser_Ext: {
            isExternalUser_Ext: createIsExternal
        },
    } = job;

    const translator = useTranslator();

    const [canWithdraw, setCanWithdraw] = useState(false);
    const [isLoading, updateIsLoading] = useState(true);
    const [isCopying, setIsCopying] = useState(false);
    const [documentsData, setDocumentsData] = useState([]);
    const [isShowNotification, setIsShowNotification] = useState(false);
    const [referredToUnderWriter, setReferredToUnderWriter] = useState(false);
    const [showReferToUW, setShowReferToUW] = useState(false);
    const [newSubmissionObj, setNewSubmissionObj] = useState({});
    const [retainInAgency, setRetainInAgency] = useState();
    
    const isCL = WniProductsUtil.isCLProduct(job.productCode);
    const { enableCommercialLine } = useBusinessData();

    const onSetShowReferToUW = (value) => {
        setShowReferToUW(value);
    };

    const initCanWithdraw = () => {
        if (!_.isEmpty(job)) {
            const { status: submissionStatus } = job;

            if (submissionStatus === 'Not-taken') {
                setCanWithdraw(false);
            } else if (
                submissionStatus !== 'Bound'
                && !job.policy.issued
                && !job.closeDate
            ) {
                setCanWithdraw(true);
            }
        }
    };

    const handleError = (title, message) => {
        return modalApi.showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        });
    };

    const getDocuments = async () => {
        try {
            const { jobNumber } = job;
            const renewalData = await WniRenewalService
                .getDocumentsForRenewalGroupByType([jobNumber], authHeader);
            const filteredDocs = DocumentsUtil.getDocumentData(renewalData,
                DocumentsUtil.RENEWAL);
            setDocumentsData(renewalData);
            setRetainInAgency(filteredDocs);
        } catch (e) {
            handleError(
                gatewayMessages.modalError,
                gatewayMessages.errorLoadingDocument
            );
        }
    };

    const checkReferredToUnderWriter = async () => {
        const { jobNumber } = job;
        setReferredToUnderWriter(await WniPolicyService.referredToUnderWriter(
            [jobNumber],
            authHeader
        ));
    };

    const addToRecentlyView = async () => {
        const { jobNumber } = job;
        await WniRenewalService.addRecentlyViewedRenewal(jobNumber, authHeader);
    };

    useEffect(() => {
        initCanWithdraw();
        Promise.all([
            getDocuments(),
            checkReferredToUnderWriter(),
            addToRecentlyView()
        ]).then(() => updateIsLoading(false))
        
        // POI-4182: another valudationissuescompoennt might be needed here -- which is not that much fun.
        // if (reQuotedByAMR) {
        //     updateDynamicNotifications([{
        //         reason: translator(BRCLMessages.BR_POI_4182),
        //         level: 'warning',
        //     }]);
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <Loader showLoader={isLoading} />
        );
    }

    const onContinueTransaction = ({ isReadOnly = false }) => {
        const { lobQuoteURL } = appConfig;
        const productCode = _.get(job, 'productCode');
        const postalCode = _.get(
            job,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (job.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    isReadOnly,
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: job.jobNumber,
                        // producerCode_Ext: job.producerCode_Ext
                    },
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            } else {
                JobUtil.openJobInXCenter(job.jobNumber);
            }
        }
    };

    const onCopySubmission = async () => {
        const { lobQuoteURL } = appConfig;
        setIsCopying(true);
        const newSubmission = await WniSubmissionService.copySubmission(
            job.jobNumber,
            authHeader
        );
        const productCode = _.get(newSubmission, 'productCode');
        if (newSubmission.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                setIsShowNotification(true);
                setNewSubmissionObj(newSubmission);
            }
        }
        setIsCopying(false);
    };

    const goToNextSubmission = (lobQuoteURL, productCode, nextLocation) => {
        history.push(lobQuoteURL[productCode], nextLocation);
    };

    const getCopyMsg = () => {
        if (!_.isEmpty(newSubmissionObj) && isShowNotification) {
            const { lobQuoteURL } = appConfig;
            const productCode = _.get(newSubmissionObj, 'productCode');
            const postalCode = _.get(
                newSubmissionObj,
                'policy.account.accountHolder.primaryAddress.postalCode'
            );
            const nextJobNum = newSubmissionObj.jobNumber;
            const nextLocation = {
                quoteentry: {
                    postalCode: postalCode,
                    quoteID: nextJobNum,
                },
            };
            return (
                <>
                    The application has been copied to &nbsp;
                    <GWLink
                        onClick={() => goToNextSubmission(lobQuoteURL, productCode, nextLocation)}
                        className="font-bold"
                    >
                        {nextJobNum}
                    </GWLink>
                </>
            );
        }
        return null;
    };

    const handleUpdateJobSummary = (newJob) => {
        if (updateJobSummary) {
            updateJobSummary(newJob);
        }
    };

    const updateWithDrawQuote = (jobStatusCode, submission) => {
        const status = translator({
            id: `typekey.PolicyPeriodStatus.${jobStatusCode}`,
            defaultMessage: jobStatusCode,
        });
        _.set(submission, 'status', status);
        _.set(submission, 'statusCode', jobStatusCode);
        handleUpdateJobSummary(submission);
    };

    const onWithdrawTransaction = () => {
        modalApi.showConfirm({
            title: messages.withdrawJob,
            message: translator(localMessages.sureWithDrawQuote,
                { quoteNumber: job.jobNumber }),
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            JobService.withdrawJobByJobNumber(
                job.jobNumber,
                authHeader
            ).then(
                () => {
                    updateWithDrawQuote('Withdrawn', job);
                },
                () => {
                    modalApi.showAlert({
                        title: gatewayMessages.modalError,
                        message: messages.failedWithdrawSubmission,
                        status: 'error',
                        icon: 'gw-error-outline',
                        confirmButtonText: commonMessages.ok
                    });
                }
            );
        }).catch(_.noop);
    };

    const onViewQuote = () => {
        onContinueTransaction({ isReadOnly: true });
    };

    const hasUwIssues = (uwIssues) => {
        return uwIssues && uwIssues.length > 0;
    };

    // const getIsViewTransaction = () => {
    //     const statusCode = _.get(
    //         fromAccountLanding,
    //         'quoteDetailsData.loadRenewalSummary.statusCode'
    //     );
    //     if (statusCode) {
    //         switch (statusCode) {
    //             case 'Withdrawn':
    //                 return false;
    //             default:
    //                 return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
    //         }
    //     } else {
    //         return _.get(authUserData, 'permissionMap_Ext.viewsubmission');
    //     }
    // };

    const getSubmissionToProceed = () => {
        const hasEditSubmissionPermission = _.get(authUserData, 'permissionMap_Ext.editsubmission');
        const hasWithDrawPermission = _.get(authUserData, 'permissionMap_Ext.withdraw');
        // const hasReferToUnderWriterPermission = _.get(
        //     authUserData,
        //     'permissionMap_Ext.ReferToUnderwriter_Ext'
        // );
        if (
            job && (job.statusCode === 'Withdrawn')
        ) {
            return {
                isContinueTransaction: false,
                isWithdrawTransaction: false,
                isViewTransaction: false
            };
        }
        return {
            isCopySubmission: false,
            isContinueTransaction:
                hasEditSubmissionPermission && canWithdraw
                && !job.currentVersionLocked_Ext,
            isWithdrawTransaction: canWithdraw && hasWithDrawPermission,
            // isViewTransaction: getIsViewTransaction()
            //     && !_.get(authUserData, 'isExternalUser_Ext'),
            isViewTransaction: false,
            // isReferToUnderWriterTransaction: hasReferToUnderWriterPermission
            //     || _.get(authUserData, 'isExternalUser_Ext')
            isReferToUnderWriterTransaction: false
        };
    };

    const getUWIssuesApprovedNotificationContent = () => {
        // const transactionVisibleActions = getSubmissionToProceed();
        const uwutil = UWUtil(job, authUserData.userType);
        return {
            infoMessageTitle: translator(localMessages[`approvedForThis${uwutil.jobType}`]),
        };
    };

    const getNotificationContent = (warningMessagesAndSuccessNotificationVisibility) => {
        const {
            successNotification: {
                showUWIssuesApprovedMsg
            }
        } = warningMessagesAndSuccessNotificationVisibility;
        const selectedVersionPublicID = _.get(job, 'selectedVersion_Ext');
        const underwritingIssues = _.get(job, 'underwritingIssues');
        const callingFromAPI = _.get(job, 'callingFromAPI_Ext');
        const hasBlockingUWIssue = ErrorsAndWarningsUtil
            .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);
        const content = {};
        if (
            job
                && !['Bound', 'Quoted', 'Withdrawn'].includes(job.statusCode)
                && ((['Draft'].includes(job.statusCode) && !job.currentVersionLocked_Ext) || !hasUwIssues(job.underwritingIssues))
        ) {
            content.infoMessageTitle = translator(
                messages.quoteSuccessfullyStarted
            );
        }
        if (
            job
            && (job.statusCode === 'Quoted' || job.statusCode === 'Rated')
            && (!hasBlockingUWIssue || (hasBlockingUWIssue && callingFromAPI))
        ) {
            content.infoMessageTitle = translator(
                messages.premiumQuoteCalculated
            );
        }
        if (job && job.statusCode === 'Withdrawn') {
            content.infoMessageTitle = translator(
                messages.thisQuoteHasBeenWithDrawn
            );
        }
        if (job && job.statusCode === 'Bound') {
            content.infoMessageTitle = translator(localMessages.thePolicyIsRenewed);
        }
        let infoMessages = [{
            infoMessageTitle: content.infoMessageTitle,
        }];

        if (showUWIssuesApprovedMsg) {
            infoMessages = infoMessages.concat(getUWIssuesApprovedNotificationContent());
        }

        return {
            infoMessages: infoMessages,
            withDrawContent: translator(messages.withdrawQuote),
            continueContent: translator(messages.continueQuote),
            viewContent: translator(localMessages.viewQuote),
            copyContent: translator(localMessages.copySubmission),
        };
    };

    const getPolicyLinkVisible = () => {
        const policyNumberAssigned = _.get(
            job,
            'policyNumberAssigned_Ext'
        );
        return policyNumberAssigned;
    };

    const redirectToPolicy = () => {
        const policyNumber = _.get(job, 'policyNumber_Ext');
        const accountNumber = _.get(job, 'policy.account.accountNumber');
        const accountType = _.get(job, 'policy.account.accountHolder.contactType_Ext');
        const url = interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber);

        return (
            <LinkComponent
                {...url}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </LinkComponent>
        );
    };

    const getJobStatus = () => {
        // if (job.policy) {
        //     if (job.policy.issued) {
        //         return translator(gatewayMessages.issued);
        //     }
        //     if (job.status === 'Not-taken') {
        //         return translator(gatewayMessages.notTaken);
        //     }
        // }
        // return job.status;
        if (job.status === 'Not-taken') {
            return translator(gatewayMessages.notTaken);
        }
        return job.status
    };

    // const goToAllQuotes = () => {
    //     history.push({
    //         pathname: '/policies',
    //         state: {
    //             initStatus: 'Quotes',
    //         },
    //     });
    // };

    const downloadDocumentAll = async () => {
        documentsData.forEach((doc) => {
            const { publicID, sessionID } = doc;

            const serviceUrl = getProxiedServiceUrl('downloadDocument');
            const templateDownloadEndpointURL = serviceUrl.concat(
                `/${publicID}?token=${sessionID}`
            );
            const params = {
                method: 'GET',
                url: templateDownloadEndpointURL,
                headers: {
                    'Content-Type': 'application/json',
                },
                responseType: 'arraybuffer',
            };

            const errorCallback = () => {
                history.push('/documenterror');
            };

            DocumentDownloadService.getDocument(
                templateDownloadEndpointURL,
                params,
                errorCallback
            );
        });
    };

    const getStatusInfo = () => {
        let submissionStatus = getJobStatus(job);
        if(enableCommercialLine) {
            submissionStatus = job.status
        }
        if (
            submissionStatus.toLowerCase() === 'bound'
                && job.policy.issued
        ) {
            return translator(gatewayMessages.issued);
        }
        return submissionStatus;
    };

    const underwritingIssues = _.get(job, 'underwritingIssues');
    const selectedVersionPublicID = _.get(job, 'selectedVersion_Ext');
    const selectedPolicyPeriod = _.get(job, 'policy.periods', [])
        .find((policyPeriod) => policyPeriod.publicID === selectedVersionPublicID);
    const hasBlockingUWIssue = ErrorsAndWarningsUtil
        .quoteDetailsSummaryPageHasBlockingUWIssue(underwritingIssues, selectedVersionPublicID);
    const documentEmpty = (retainInAgency && _.get(retainInAgency, 'length') > 0);
    if (_.isEmpty(job)) {
        return <Loader loaded />;
    }
    const uwutil = UWUtil(job, authUserData.userType);

    const warningMessagesAndSuccessNotificationVisibility = SummaryUtil
        .getWarningMessagesAndSuccessNotificationVisibility(
            job,
            authUserData,
            referredToUnderWriter,
            showReferToUW,
            UWUtil,
            getSubmissionToProceed()
        );

    const getTotalPremium = () => {
        if(!createIsExternal && job.isEditLocked && isCL && enableCommercialLine) {
            // only locked CL hide premium
            // if(isCL || !enableCommercialLine) {
            //     return '-'
            // }
            return '-'
        }
        return _.get(selectedPolicyPeriod, 'totalPremium')
    }

    // =====================================
    const accountNumber = _.get(job, 'policy.account.accountNumber');
    const accountType = _.get(job, 'policy.account.accountHolder.contactType_Ext');
    const policyNumber = _.get(job, 'policyNumber_Ext');
    const producerCodeOfServiceDescription = _.get(job, 'producerCodeOfService_Ext.description');
    const producerCodeOfRecordDescription = _.get(job, 'producerCodeOfRecord_Ext.description');
    const overrides = {
        notification: {
            message: getCopyMsg(),
            visible: isShowNotification
        },
        quoteNotification: {
            notificationContent: getNotificationContent(
                warningMessagesAndSuccessNotificationVisibility
            ),
            transactionVisibleActions: getSubmissionToProceed(),
            visible: !warningMessagesAndSuccessNotificationVisibility.warningExist && (!enableCommercialLine),
            isCopying
        },
        underwritingTable: {
            job: job,
            jobService: WniSubmissionService,
            onUpdateJobSummary: handleUpdateJobSummary,
            transactionVisibleActions: getSubmissionToProceed(),
            referredToUnderWriter: referredToUnderWriter,
            checkReferredToUnderWriter: checkReferredToUnderWriter,
            hasBlockingUWIssue: hasBlockingUWIssue,
            showReferToUW: showReferToUW,
            setShowReferToUW: onSetShowReferToUW,
            warningMessagesAndSuccessNotificationVisibility:
                    warningMessagesAndSuccessNotificationVisibility,
            visible: !enableCommercialLine
        },
        underwritingIssues: {
            uwIssues: job.underwritingIssues,
            uwUtil: uwutil,
            noRecordsMessage: translator(messages[`noOpenUnderwriting${uwutil.jobType}`]),
            visible: !['Bound', 'Withdrawn'].includes(job.statusCode) || (!enableCommercialLine),
        },
        underwritingMessage: {
            job,
            createIsExternal,
            visible: isCL || enableCommercialLine
        },
        summaryDetailGridRow: {
            columns: job.statusCode === 'Quoted' || job.statusCode === 'Bound'
                ? ['1fr', '1fr', '1fr', '1fr'] : ['1fr', '1fr', '1fr'],
        },
        policyInfoLink: {
            visible: getPolicyLinkVisible(),
            value: redirectToPolicy(),
        },
        detailGridTotalColumn: {
            visible: job.statusCode === 'Quoted' || job.statusCode === 'Bound',
        },
        totalPremiumId: {
            value: getTotalPremium(),
        },
        fullPayDiscountId: {
            visible: _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay' && _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext.amount') > 0,
            value: _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext')
        },
        totalCostId: {
            value: _.get(selectedPolicyPeriod, 'totalCost'),
            visible: job.statusCode !== 'Rated' && _.get(selectedPolicyPeriod, 'selectedPaymentPlanName_Ext') === 'Full Pay' && _.get(selectedPolicyPeriod, 'fullPayDiscount_Ext.amount') > 0
        },
        agencyOfRecordOrgId: {
            value: `${producerCodeOfRecordDescription}`,
        },
        agencyOfServiceId: {
            value: `${producerCodeOfServiceDescription}`,
        },
        summaryCreatedDataId: {
            value: _.get(job, 'createTime')
        },
        summaryPolicyInceptionId: {
            value: _.get(job, 'effectiveDate_Ext')
        },
        summaryPolicyExpirationDateId: {
            value: _.get(job, 'policyExpirationDate_Ext')
        },
        summaryStatusdDataId: {
            value: getStatusInfo(),
        },
        documentcheckedList: {
            visible: documentEmpty,
            history: history,
            submission: job,
            renderData: [
                {
                    data: retainInAgency
                },
                {
                    data: []
                }
            ],
            showSendViaEmail: true,
            useJobNumber: true
        },
        backToPolicyCetailsLink: {
            ...interactionModel.getURLObj(accountType, 'policySummary', accountNumber, policyNumber),
        },
        backLinkContainer: {
            accountNumber,
            accountType,
            history: history,
        }
    };
    const resolvers = {
        resolveClassNameMap: interalStyles,
        resolveComponentMap: {
            notificationcomponent: SuccessNotification,
            underwritingcomponent: UnderwritingComponent,
            uwissuestablecomponent: UnderIssuesTableComponent,
            underwritingMessage: UnderwritingMessage,
            downloaddocumentcomponent: DownloadDocumentComponent,
            documentcheckedcomponent: DocumentCheckedComponent,
            backlinkcomponent: BackLinkComponent
        },
        resolveCallbackMap: {
            onCopySubmission: onCopySubmission,
            onContinueTransaction: onContinueTransaction,
            onWithdrawTransaction: onWithdrawTransaction,
            onViewTransaction: onViewQuote,
            redirectToPolicy: redirectToPolicy,
            downloadDocumentAll: downloadDocumentAll,
            // goToAllQuotes: goToAllQuotes,
        },
    };
    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            job,
            id,
            path,
            overrides
        );
    };
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={job}
            overrideProps={overrides}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

RenewalSummary.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
    authUserData: PropTypes.shape({
        userType: PropTypes.string
    }).isRequired,
    fromAccountLanding: PropTypes.shape({
        quoteDetailsData: PropTypes.shape({
            jobNumber: PropTypes.string.isRequired,
            loadRenewalSummary: PropTypes.shape({}).isRequired,
            updateJobSummary: PropTypes.func.isRequired,
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    domainCompany: PropTypes.shape({ domainName: PropTypes.string }).isRequired,
    interactionModel: PropTypes.shape({
        getURLObj: PropTypes.func,
    }).isRequired
};

export default withAuthenticationContext(withDependencies(['domainCompany', 'interactionModel'])(RenewalSummary));
