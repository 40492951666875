import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { JsonRPCService } from '@xengage/gw-portals-transport-js';


function processSubmission(method, data, additionalHeaders = {}) {
    return JsonRPCService.send(getProxiedServiceUrl('imValuablePapersService'), method, data, additionalHeaders);
}

export default class IMValuablePapersService {
  
    /**
     * Invokes IMValuablePapersHandler.getValuablePapersPartWithSync()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getValuablePapersPartWithSync(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getValuablePapersPartWithSync', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.getValuablePapersPart()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getValuablePapersPart(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getValuablePapersPart', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.updateValuablePapersPartClauses()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} valuablePapersCoveragesToUpdate
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static updateValuablePapersPartClauses(jobNumber, sessionUUID, valuablePapersCoveragesToUpdate, authHeader = {}) {
        return processSubmission('updateValuablePapersPartClauses', [jobNumber, sessionUUID, valuablePapersCoveragesToUpdate], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.getNewNonPersistableValuablePaper()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static getNewNonPersistableValuablePaper(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('getNewNonPersistableValuablePaper', [jobNumber, sessionUUID], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.deleteValuablePapers()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Array.<string>} valuablePaperIdsToDelete
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static deleteValuablePapers(jobNumber, sessionUUID, valuablePaperIdsToDelete, authHeader = {}) {
        return processSubmission('deleteValuablePapers', [jobNumber, sessionUUID, valuablePaperIdsToDelete], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.saveValuablePaper()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} valuablePaperDTO
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static saveValuablePaper(jobNumber, sessionUUID, valuablePaperDTO, authHeader = {}) {
        return processSubmission('saveValuablePaper', [jobNumber, sessionUUID, valuablePaperDTO], authHeader);
    }

    /**
     * Invokes IMValuablePapersHandler.onValuablePapersNext()
     * 
     * @param {string} jobNumber 
     * @param {string} sessionUUID
     * @param {Object} authHeader 
     * @returns {Promise}
     */
    static onValuablePapersNext(jobNumber, sessionUUID, authHeader = {}) {
        return processSubmission('onValuablePapersNext', [jobNumber, sessionUUID], authHeader);
    }
}