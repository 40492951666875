import _ from 'lodash';
import React from 'react';
import { WniDateUtil } from 'wni-portals-util-js';

import { IconButton, CurrencyField } from '@jutro/legacy/components';

const CONST_NEW = 'New';

const STYLE = {
    width: '100%',
    height: '100%',
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'space-evenly'
};

function renderCell(item, index, property) {
    const value = _.get(item, property.path);
    return value || '';
}

function renderPolicyCell(item, index, property, getProductName=_.noop) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const product = _.get(data, property.product, '');
                const quoteNumber = _.get(data, property.quoteNumber, '');
                return (
                    <div>
                        <div>{getProductName(product)}</div>
                        <div>{quoteNumber}</div>
                    </div>
                );
            })}
        </div>
    );
}

function renderCurrencyFieldCell(item, index, property, value) {
    const v = item[property.path] || value;
    return (
        <CurrencyField
            id={`downpayment${index}`}
            value={v}
            dataType="object"
            showFractions
            readOnly
            hideLabel
        />
    );
}

function renderPremiumCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const value = _.get(data, 'premium.totalCost_Ext', '');
                return (
                    <div>
                        {renderCurrencyFieldCell(item, index, property, value)}
                    </div>
                );
            })}
        </div>
    );
}

function renderFeeCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    const totalFee = {
        amount: 0.0,
        currency: ''
    };
    _.each(datas, (data) => {
        const fee = _.get(data, 'premium.installmentFees_Ext.amount', '');
        if (!_.isNil(fee)) {
            totalFee.amount = totalFee.amount * 100 + fee * 100;
            totalFee.amount /= 100;
        }
        const currency = _.get(data, 'premium.installmentFees_Ext.currency', '');
        if (_.isEmpty(totalFee.currency)) {
            totalFee.currency = currency;
        }
    });
    return renderCurrencyFieldCell(item, index, property, totalFee);
}

function renderEmptyCell() {
    return null;
}

function renderFieldCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const value = _.get(data, property.path, '');
                return (
                    <div>
                        {value}
                    </div>
                );
            })}
        </div>
    );
}

function renderPaymentMethodCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const value = _.get(data, 'payUsing', '');
                if (_.isEmpty(value)) {
                    return (
                        <div>
                            Bill
                        </div>
                    );
                }
                const payUsingName = _.get(data, 'payUsingName', '');
                return (
                    <div>
                        {payUsingName}
                    </div>
                );
            })}
        </div>
    );
}

function renderPaymentPlanCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    const PaymentUtil = _.get(property, 'paymentUtil');
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const selectedPaymentPlan = _.get(data, 'selectedPaymentPlanName', '');
                const displayName = _.get(PaymentUtil.forMatterPaymentPlan({ name: selectedPaymentPlan }), 'name');
                return (
                    <div>
                        {displayName}
                    </div>
                );
            })}
        </div>
    );
}

function renderEmptyBillAccountCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const status = _.get(data, property.path, CONST_NEW);
                return (
                    <div>
                        {status}
                    </div>
                );
            })}
        </div>
    );
}

function renderStatusCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const status = _.get(data, property.path, '');
                return (
                    <div>
                        {status}
                    </div>
                );
            })}
        </div>
    );
}

function renderBillAccountCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const status = _.get(data, property.path, '');
                return (
                    <div>
                        {status}
                    </div>
                );
            })}
        </div>
    );
}

function renderDownpaymentCell(item, index, property) {
    const mortgageBill = _.get(item, 'mortgageBill');
    if (mortgageBill) {
        return 'Not Required';
    }
    return renderCurrencyFieldCell(item, index, property, null);
}

function renderLocalDateCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const value = _.get(data, property.path, '');
                if (value === '') {
                    return (
                        <div />
                    )
                }
                const date = new Date(value.year, value.month, value.day);
                return (
                    <div>
                        {WniDateUtil.formatDateWithPattern(date)}
                    </div>
                );
            })}
        </div>
    );
}


function renderEditIconCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    const editQuoteRowFn = _.get(property, 'editQuoteRowFn');
    return (
        <div style={STYLE}>
            {datas.map((value, idx) => {
                return (
                    <div>
                        <IconButton
                            id={`edit${idx}`}
                            icon="gw-edit"
                            iconColor="dark"
                            size="medium"
                            onClick={(e) => editQuoteRowFn(e, value)}
                        />
                    </div>
                );
            })}
        </div>
    );
}

function renderConditionCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                const condition = _.get(property, 'condition', _.noop);
                const actualPath = condition(data);
                const value = _.get(data, actualPath, '');
                return (
                    <div>
                        {value}
                    </div>
                );
            })}
        </div>
    );
}

function renderBillingMethodCell(item, index, property) {
    const datas = _.get(item, 'data', []);
    if (_.isEmpty(datas)) {
        datas.push(item);
    }
    return (
        <div style={STYLE}>
            {datas.map((data) => {
                let value;
                const isMortgageBill = _.get(data, 'mortgageBill', false);
                if (isMortgageBill) {
                    value = 'Mortgagee Bill';
                } else {
                    const selectedBillingMethod = _.get(data, property.path, '');
                    switch(selectedBillingMethod) {
                        case 'DIRECT_BILL':
                            value = 'Direct Bill';
                            break;
                        case 'AGENCY_BILL':
                            value = 'Agency Bill';
                            break;
                        case 'PAY_AS_YOU_GO':
                            value = 'Pay As You Go';
                            break;
                        default: break;
                    }
                }
                return (
                    <div>
                        {value}
                    </div>
                );
            })}
        </div>
    );
}


export default {
    renderCell,
    renderPolicyCell,
    renderCurrencyFieldCell,
    renderPremiumCell,
    renderEmptyCell,
    renderFieldCell,
    renderFeeCell,
    renderPaymentMethodCell,
    renderPaymentPlanCell,
    renderEmptyBillAccountCell,
    renderStatusCell,
    renderBillAccountCell,
    STYLE: STYLE,
    renderDownpaymentCell,
    renderLocalDateCell,
    renderEditIconCell,
    renderConditionCell,
    renderBillingMethodCell
};
