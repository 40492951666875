import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMTransitService as  TransitService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './TransitPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMCommonClausesCard from '../../components/IMCommonClausesCard/CommonClausesCard';
import messages from './TransitPage.messages';
import IMCoverageUtil from '../../util/IMCoverageUtil';

 
const TRANSPORTATIONS_ACCORDION_CARD_COMPONENT_MAPS = ['transportationsMainCovCard', 'transportationsSupplementalCovCard', 'transportationCovs']
const OWNERSCARGO_ACCORDION_CARD_COMPONENT_MAPS = ['ownersCargoMainCovCard', 'ownersCargoSupplementalCovCard', 'ownersCargoCovs']
const TRIPTRANSIT__ACCORDION_CARD_COMPONENT_MAPS = ['tripTransitMainCovCard', 'tripTransitSupplementalCovCard', 'tripTransitCovs']
const TransitPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('TransitPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [transitClauses, setTransitClauses] = useState()
    const [transitLoadingClauses, setTransitLoadingClauses] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await TransitService.getTransitClausesWithSync(jobID, sessionUUID, authHeader) 
            : await TransitService.getTransitClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setTransitClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await TransitService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setTransitClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    const calculateOwnersCargoLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await TransitService.calculateOwnersCargoLimit(jobID, sessionUUID, termCode, authHeader)
        setTransitClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    const calculateTripTransitLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await TransitService.calculateTripTransitLimit(jobID, sessionUUID, termCode, authHeader)
        setTransitClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: TransitService
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setTransitClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await TransitService.onTransitNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    const defaultOpenId = useMemo(() => {
        const isTransportationsVisible = _.get(transitClauses, 'transportationsCoverage.visible');
        const isOwnersCargoCoverage = _.get(transitClauses, 'ownersCargoCoverage.visible');
        if (isTransportationsVisible) {
            return 'transportations'
        }
        if (!isTransportationsVisible && isOwnersCargoCoverage) {
            return 'ownersCargo'
        }
        return 'tripTransit'
    }, [transitClauses])

     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: transitClauses,
            setCoveragePartClauses: setTransitClauses,
            isEditing,
            setIsEditing,
            isEditable: true,
            showErrors,
            onValidate: onValidate,
            loadingClauses: transitLoadingClauses,
            setLoadingClauses: setTransitLoadingClauses,
            onClickAddSearchClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: TransitService.updateTransitClauses,
            calculateLimit
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: true,
            },
            coveragesAccordion: {
                defaultOpenedId: defaultOpenId
            },
            transportations: {
                visible: _.get(transitClauses, 'transportationsCoverage.visible'),
                errorState: !_.isEmpty(_.intersection(invalidFields, TRANSPORTATIONS_ACCORDION_CARD_COMPONENT_MAPS))
            },
            ownersCargo: {
                visible: _.get(transitClauses, 'ownersCargoCoverage.visible'),
                errorState: !_.isEmpty(_.intersection(invalidFields, OWNERSCARGO_ACCORDION_CARD_COMPONENT_MAPS))
            },
            tripTransit: {
                visible: _.get(transitClauses, 'tripTransitCoverage.visible'),
                errorState: !_.isEmpty(_.intersection(invalidFields, TRIPTRANSIT__ACCORDION_CARD_COMPONENT_MAPS))
            },
            transportationsMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'transportationsCoverage.mainCoverage'),
                coverageFormPath: 'transportationsCoverage.mainCoverage',
            },
            transportationsSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'transportationsCoverage.supplementalCoverage'),
                coverageFormPath: 'transportationsCoverage.supplementalCoverage',
            },
            transportationCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'transportationsCoverage.transportationCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'transportationsCoverage.transportationCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            ownersCargoMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'ownersCargoCoverage.mainCoverage'),
                coverageFormPath: 'ownersCargoCoverage.mainCoverage',
                calculateLimit: calculateOwnersCargoLimit
            },
            ownersCargoSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'ownersCargoCoverage.supplementalCoverage'),
                coverageFormPath: 'ownersCargoCoverage.supplementalCoverage',
                calculateLimit: calculateOwnersCargoLimit
            },
            ownersCargoCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'ownersCargoCoverage.ownersCargoCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'ownersCargoCoverage.ownersCargoCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            tripTransitMainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'tripTransitCoverage.mainCoverage'),
                coverageFormPath: 'tripTransitCoverage.mainCoverage',
                calculateLimit: calculateTripTransitLimit
            },
            tripTransitSupplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(transitClauses, 'tripTransitCoverage.supplementalCoverage'),
                coverageFormPath: 'tripTransitCoverage.supplementalCoverage',
                calculateLimit: calculateTripTransitLimit
            },
            tripTransitCovs: {
                ...accordionCommonProps,
                coverageFormPath: 'tripTransitCoverage.tripTransitCovs',
                generateUpdatedClausesDTO: IMCoverageUtil.generateUpdatedCoverageFormClausesDTO,
                clauses: _.get(transitClauses, 'tripTransitCoverage.tripTransitCovs'),
                visibleOfTableTitle: true,
                visibleOfDivider: true,
                scheduleTableTitle: translator(messages.ListOfScheduleItems)
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'Transit'
            }
        }
    }, [defaultOpenId, transitClauses, invalidFields, accordionCommonProps, translator, calculateOwnersCargoLimit, calculateTripTransitLimit]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            TransportationsMainCovCard: IMCommonMainCoverage,
            TransportationsSupplementalCovCard: IMCommonMainCoverage,
            TransportationCovs: IMCommonClausesCard,
            OwnersCargoMainCovCard: IMCommonMainCoverage,
            OwnersCargoSupplementalCovCard: IMCommonMainCoverage,
            OwnersCargoCovs: IMCommonClausesCard,
            TripTransitMainCovCard: IMCommonMainCoverage,
            TripTransitSupplementalCovCard: IMCommonMainCoverage,
            TripTransitCovs: IMCommonClausesCard,
            AdditionalAccordionCard: IMCommonAdditionalCovCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask || transitLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {transitClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default TransitPage
