import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import messages from './CommonScheduleItemForm.messages';
import IMCoverageUtil from '../../../util/IMCoverageUtil';
import IMScheduleDetailsPropertyField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsPropertyField";
import IMScheduleDetailsCovTermField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsCovTermField";


const CommonScheduleItemForm = (props) => {
    const [showErrors, setShowErrors] = useState(false)
    const {
        propertyInfos,
        coveragePropertyInfos,
        scheduleItem: originScheduleItem,
        handleScheduleItemCancel,
        handleSaveScheduleItem,
        isEditable,
        onValidate,
        scheduleId,
        setIsEditing,
        scheduleFormHeader
    } = props;
    
    const [scheduleItem, setScheduleItem] = useState(originScheduleItem);

    useEffect(() => {
        setScheduleItem(originScheduleItem)
    }, [originScheduleItem])
    
    useEffect(() => {
        if (onValidate) {
            onValidate(!IMCoverageUtil.isScheduleItemFormInvalid(scheduleItem, propertyInfos), scheduleId);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleId);
            }
        }
    }, [onValidate, scheduleItem, propertyInfos, scheduleId])

    const setScheduleItemFunc = (setPropertyFieldFunc) => {
        const newScheduleItem = setPropertyFieldFunc(scheduleItem)
        setScheduleItem(newScheduleItem)
    }

    const propertyFieldComponents = propertyInfos
        .sort((a, b) => a.order - b.order)
        .map(propertyInfo => {
            const propertyInfoID = propertyInfo.id
            const propertyInfoItemData = scheduleItem.itemData[propertyInfoID]
            return <IMScheduleDetailsPropertyField
                id={propertyInfoID}
                key={propertyInfoID}
                setScheduleItemFunc={setScheduleItemFunc}
                propertyInfo={propertyInfo}
                onValidate={onValidate}
                propertyInfoItemData={propertyInfoItemData}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
            />
        })

    const termFieldComponents = coveragePropertyInfos
        .sort((a, b) => a.order - b.order)
        .map(termPropertyInfo => {
            const termCode = termPropertyInfo.id
            const term = scheduleItem.scheduleItemTerms
                .find(t => t.code_Ext === termCode)
            if (_.isNil(term)) {
                return null;
            }
            return <IMScheduleDetailsCovTermField
                key={termCode}
                term={term}
                setScheduleItemFunc={setScheduleItemFunc}
                onValidate={onValidate}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
            />
        })

    const handleSave = useCallback((currentScheduleItem) => {
        const isScheduleItemInvalid = IMCoverageUtil.isScheduleItemFormInvalid(currentScheduleItem, propertyInfos)
        if (isScheduleItemInvalid) {
            setShowErrors(true)
            return false
        }
        handleSaveScheduleItem(currentScheduleItem)
    }, [handleSaveScheduleItem, propertyInfos])

    return (
        <>
            <h4>{scheduleFormHeader}</h4>
            {propertyFieldComponents}
            {termFieldComponents}
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    variant="secondary"
                    onClick={handleScheduleItemCancel}
                    label={messages.Cancel}
                />
                <Button
                    onClick = {()=> handleSave(scheduleItem)}
                    label={messages.SaveAndContinue}
                />
            </Flex>
        </>
        
    );
};

export default CommonScheduleItemForm;
