import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMMsicFormsService } from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext'
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMMiscFormsPage.metadata.json5';

import WizardPage from '../../templates/IMWizardPage';
import DroneCoverageCard from './component/DroneCoverageCard';
import ColdStorageLockerCoverageCard from './component/ColdStorageLockerCoverageCard/ColdStorageLockerCoverageCard';

const IMMiscFormsPage = (props) => {
    const {
        wizardData: submissionVM,
        updateWizardData,
    } = props;

    const { initialValidation, onValidate, isComponentValid } = useValidation('MsicFormsPage');
    const { authHeader } = useAuthentication();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [miscForms, setMiscForms] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }

    const initCoverablePart = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await IMMsicFormsService.getMiscFormsWithSync(jobID, sessionUUID, authHeader) 
            : await IMMsicFormsService.getMiscForms(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setMiscForms(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePart()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    } 
    

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const onPageNext = async () => {
        setLoadingMask(true)
        const errorsAndWarnings = await IMMsicFormsService.onMiscFormsNext(jobID, sessionUUID, authHeader)      
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        const hasErrorIssues = newValidationIssues.some((issue) => {
            return issue.type === 'error';
        });
        const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues)
        if (hasErrorIssues || hasNewErrorsOrWarnings) {
            return false;
        }
        setLoadingMask(false)
        return submissionVM
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    
    const defaultOpenId = useMemo(() => {
        const isColdStorageLockerCoverage = _.get(miscForms, 'coldStorageLockerCoverage');
        const isDroneCoverageCoverage = _.get(miscForms, 'droneCoverage');
        if (isDroneCoverageCoverage) {
            return 'DroneCoverageCard'
        }
        if (!isDroneCoverageCoverage && isColdStorageLockerCoverage) {
            return 'ColdStorageLockerCoverageCard'
            
        }
    }, [miscForms])

    const accordionCommonProps = {
        miscForms,
        setMiscForms: (newMsicForms) => {
            setMiscForms(newMsicForms)
            updateWizardDataWhileSetPeriodStatus(submissionVM)
        },
        isEditing,
        setIsEditing,
        isEditable: true,
        showErrors,
        onValidate: onValidate,
        // onClickAddSearchCoverage,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        defaultOpenId: defaultOpenId
    }

    const overrideProps = {
        '@field': {
            isEditable: true,
        },
        DroneCoverageCard: {
            ...accordionCommonProps
        },
        ColdStorageLockerCoverageCard: {
            ...accordionCommonProps
        }
    };

    

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            DroneCoverageCard: DroneCoverageCard,
            ColdStorageLockerCoverageCard: ColdStorageLockerCoverageCard
        },
    };

    const disableNavigator = isEditing || isLoadingMask

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ? onPageNext : handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {miscForms && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default IMMiscFormsPage
