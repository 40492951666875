import React, {useEffect, useCallback} from 'react'
import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { IMValuablePapersService } from 'wni-capability-quoteandbind-im';
import messages from './CoveragesAccordionCard.messages'

import IMCoverageUtil from '../../../../util/IMCoverageUtil';
import CoverageContext from '../../../../context/IMCoverageContext';
import IMSingleClauseComponentVM from '../../../../components/IMSingleClauseComponentVM';
import IMClausesComponentVM from '../../../../components/IMClausesComponentVM';
import SingleClauseContext from '../../../../context/IMSingleClauseContext';
import IMTermComponent from '../../../../components/IMSingleClauseComponentVM/IMTermComponent';
import ValuablePapersTable from '../ValuablePapersTable';

const CoveragesAccordionCard = (props) => {

    const {
        coveragePart,
        setCoveragePart,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,

        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const { 
        partCoverages: coveragePartClauses,
        availableBuildings,
        valuablePapers,

    } = coveragePart

    const { mainCoverages, valuablePapersCoverages } = coveragePartClauses;

    const [IMVPPropertyAtPremisesCov] = mainCoverages

    const {
        code_Ext: IMVPPropertyAtPremisesCovCode,
        terms: IMVPPropertyAtPremisesCovTerms
    } = IMVPPropertyAtPremisesCov


    const {
        jobID,
        sessionUUID,
    } = submissionVM.value

    const translator = useTranslator();

    const validationID = 'ValueablePapersCoveragesAccordionCard'

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate])

    const setCoveragePartClauses = useCallback((newCoveragePartClauses) => {
        const newCoveragePart = _.clone(coveragePart)
        _.set(coveragePart, 'partCoverages', newCoveragePartClauses)
        setCoveragePart(newCoveragePart)
    }, [coveragePart, setCoveragePart])

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setClauseValue(originClauses, clausesPath, value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, clausesPath, newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, setCoveragePartClauses, setIsEditing]
    );

    const changeValuablePapersPartCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, 'valuablePapersCoverages', valuablePapersCoverages);
        },
        [valuablePapersCoverages, changeClauses]
    );

    const changeMainCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, 'mainCoverages', mainCoverages);
        },
        [changeClauses, mainCoverages]
    );


    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        const coveragePartClausesToUpdate = IMCoverageUtil.generateUpdatedValuablePapersClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        const newCoveragePart = await IMValuablePapersService.updateValuablePapersPartClauses(
            jobID,
            sessionUUID,
            coveragePartClausesToUpdate,
            authHeader
        )
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePart(newCoveragePart);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, jobID, sessionUUID, authHeader, setCoveragePart, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeMainCoveragesAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeMainCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeMainCoverages, updateNewCoveragePartClausesToServer]
    );

    const changeValuablePapersPartClauseAndSync = useCallback(
        (value, changedPath) => {
            const newCoveragePartClauses = changeValuablePapersPartCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeValuablePapersPartCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
        <AccordionCard
            id="CoveragesAccordionCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={translator(messages.Coverages)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <SingleClauseContext.Provider value={{
                        clauseCode: IMVPPropertyAtPremisesCovCode,
                        clausePath: 'mainCoverages[0]',
                        isEditable,
                        // isDisabled,
                        onChangeClause: changeMainCoverages,
                        onSyncCoverages: onSyncCoverages,
                        onChangeSubmissionAndSync: changeMainCoveragesAndSync,
                        labelPosition: 'left',
                        onValidate,
                        setIsEditing,
                        showErrors,
                    }}>
                        {IMVPPropertyAtPremisesCovTerms.map(term => {
                            const termIndex = IMVPPropertyAtPremisesCovTerms
                                .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                            return <IMTermComponent
                                key={_.get(term, 'code_Ext')}
                                term={term}
                                termIndex = {termIndex}
                            />
                        })}
                    </SingleClauseContext.Provider>
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeValuablePapersPartClauseAndSync}
                        onChangeClause={changeValuablePapersPartCoverages}
                        onValidate={onValidate}
                        showAmount={false}
                        path="valuablePapersCoverages"
                        value={valuablePapersCoverages}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        hideCheckBoxForRequired
                    />
                </CoverageContext.Provider>
                
            </div>
            <ValuablePapersTable
                jobID={jobID}
                sessionUUID={sessionUUID}
                isEditable={isEditable}
                setCoveragePart={setCoveragePart}
                availableBuildings={availableBuildings}
                valuablePapers={valuablePapers}
            />
        </AccordionCard>
        
    )
}

export default CoveragesAccordionCard