import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { SubmissionService } from 'gw-capability-gateway';
import { WniLoadSaveService, WniSxsQuoteService, WniCommonQuoteService } from 'wni-capability-quoteandbind';
import {
    IMMultiQuoteService,
    // IMCoveragesService,
} from 'wni-capability-quoteandbind-im';
import { 
    ComponentRenderHelper as renderHelper,
    ModalDialogHelper,
    useLoadingMask,
    useProductsData,
    useBusinessData
} from 'wni-portals-util-react';
import {
    WniSubmissionService,
    WniActivitiesService,
    WniDocumentRetrievalService,
} from 'wni-capability-gateway';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    IncidentsUtil,
    QuoteUtil,
    ErrorsAndWarningsUtil,
    WizardPageJumpUtil,
    ServiceErrorUtil,
    ActivityUtil,
    WindowUtil,
    DocumentsUtil,
    DTOUtil,
    PaymentUtil,
    IssuanceValidationUtil,
    WniClausesUtil,
    ActiveQuoteUtil,
} from 'wni-portals-util-js';

import {
    // SideBySidePeriodsComponentV2,
    // WithdrawTransactionComponent,
    MarkNotTakenComponent,
    SxsTableRowProfiles,
    QuoteAdditionalProductComponent,
    TextEditConfirmComponent,
} from 'wni-capability-gateway-react';
import { ErrorsAndWarningsDisplayComponent, ValidationIssuesComponent, useWniModal } from 'wni-components-platform-react';
import { PortalConstants, WizardConstants } from 'wni-portals-config-js';
import {
    QuoteCommonMessages,
    QuoteSideBySidePeriodMessages,
    BRCLMessages,
} from 'wni-platform-translations';
import { WizardErrorContext} from 'wni-portals-wizard-react';

// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import WizardPage from '../../templates/IMWizardPage';

import QuotePageConstants from './config/IMQuotePage.config';

import IMQuoteClaueTableDataHelper from './util/IMQuoteClauseTableDataHelper';

import IMQuoteIssueRenderHelper from './util/IMQuoteIssueRenderHelper';

import IMQuoteClauseTableUIHelper from './util/IMQuoteClauseTableUIHelper';
import IMPolicyLineSummaryComponent from './components/Summary/IMPolicyLineSummaryComponent';
import SideBySidePeriodsComponent from './components/IMSideBySidePeriodsComponent';

import IMAddNewVersionComponent from './components/AddNewVersion/IMAddNewVersionComponent';
// import IMQuoteClauseTableComponent from './components/Clause/IMQuoteClauseTableComponent';

// import IMCoverageUtil from '../Coverages/util/IMCoverageUtil';

import styles from './IMQuotePage.module.scss';
import metadata from './IMQuotePage.metadata.json5';
import messages from './IMQuotePage.messages';
import { Button } from '@jutro/legacy/components';

// import IMCoveragesConfig from '../Coverages/IMCoverages.config';

// =================================================================

const {
    getQuotePageErrorsAndWarningsTitle,
    getQuotePageErrorsAndWarningsFooter,
    getQuotePageIssueRenderFn,
    //
    renderResolveButton,
} = IMQuoteIssueRenderHelper;


const {
    generateColumnData,
    // generateTableData,
} = IMQuoteClaueTableDataHelper;


const {
    PAYMENT_PLAN_TYPES,
    //
    LOB_NAME,
    SXS_LOB_NAME,
    BRANCH_NAME_PATH,
    LOB_OFFERING_PATH, // lobData.${SXS_LOB_NAME}.offerings
} = QuotePageConstants;


// =================================================================


function IMQuotePage(props) {
    const modalApi = useWniModal();
    const {
        updateAllWizardPageStickyIssues,
    } = useContext(WizardErrorContext);

    const {
        //
        wizardData: submissionVM,
        // updateWizardData: updateSubmissionVM,
        updateWizardSnapshot,
        updateWizardReadOnly,
        wizardStepToFieldMapping,
        jumpTo: wizardJumpTo,
        steps: wizardSteps,
        wizardPageData,
        updateWizardPageData,
        // =======================================
        // quotePageConstants = IMQuoteConstants,
        lobDataProductKey,
        addNewVersionComponent: AddNewVersionComponent,
    } = props;
    const {
        jobID: quoteID, sessionUUID,
        baseData: {
            periodStatus,
            selectedVersion_Ext: selectedVersion,
            accountNumber,
            productCode: currentProductCode,
            accountHolder: {
                contactType_Ext: accountType,
                primaryAddress: {
                    postalCode,
                },
                productAvailable_Ext: productAvailableForAccount,
            },
            //
            showFullPayDiscount_Ext: showFullPayDiscount,
            displayStatus_Ext: displayStatus,
            //
            producerCodePublicID_Ext: periodProducerCodePubliID,
            producerCode_Ext: periodProducerCodeStr,
            policyAddress: {
                state: policyState,
            }
        },
        lobData: {
            [LOB_NAME]: {
                // householdInfoDTO_Ext: {
                //     coveragesForm
                // },
                //
                quotePageData_Ext: {
                    // availableProducts = [],
                    // policySummaries = [],
                    serverCurrentDate: pcServerSystemDate,
                },
            }
        },
        bindData,
        quoteData,
    } = submissionVM.value;

    const {
        [WizardConstants.accountActiveQuotes]: accountActiveQuotes,
        [WizardConstants.policySummaries]: policySummaries,
    } = wizardPageData;

    const { getProductMinDate } = useProductsData();
    const { enableCommercialLine } = useBusinessData();

    const history = useHistory();
    const translator = useTranslator();
    const breakpoint = useContext(BreakpointTrackerContext);
    // const ViewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData: { isExternalUser_Ext: isExternalUser } } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const {
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMQuotePage');
    const [showErrors, updateShowErrors] = useState(false);

    const [sxsDataDTO, updateSxsDataDTO] = useState(undefined);
    const [extraBlockingSubmitIssues, updateExtraBlockingSubmitIssues] = useState([]);
    const [errorsAndWarnings, updateErrorsAndWarnings] = useState(undefined);

    const defaultPaymentViewType = showFullPayDiscount ? PAYMENT_PLAN_TYPES.annually : PAYMENT_PLAN_TYPES.monthly;
    const [paymentViewType, updatePaymentViewType] = useState(defaultPaymentViewType);
    // const [backendPaymentViewType, updateBackendPaymentViewType] = useState(defaultPaymentViewType);

    const [isServiceCallInProgress, updateServiceCallInProgress] = useState(false);

    const quoteCount = QuoteUtil.getCountOfRatedQuotedApprovedQuotes(accountActiveQuotes);
    const [isNewAccount, updateIsNewAccount] = useState(quoteCount === 1); // Default value to be removed due to being deprecated

    const goToIncidentReports = _.noop; // WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, 'IMIncidentsAndReportsPage');
    const goToCoveragesPage = _.noop; // WizardPageJumpUtil.getJumpToPageFn(wizardSteps, wizardJumpTo, 'IMCoveragesPage');
    const goToQuoteDetailsPage = async () => WizardPageJumpUtil.skipWizardPromptAndGoToQuoteDetailsSummaryPage(history, { jobID: quoteID });

    const modalHelper = ModalDialogHelper(modalApi);
    //
    const [selectedDocument, updateSelectedDocument] = useState('');

    const [alreadyOwnedProductCode, updateAlreadyOwnedProductCode] = useState([]);

    const goToAccountSummaryPage = useCallback(async () => {
        if (!enableCommercialLine) {
            WizardPageJumpUtil.goToAccountSummaryPage(history, accountNumber);
        } else {
            history.push(`/accounts/${accountNumber}/summary`);
        }
    }, [accountNumber, history]);

    /**
     * To be refactored
     * @param {object} currentPaymentPlan or bindData
     * @param {boolean} retrievePlansData
     * @returns {array}
     */
    // const updateSelectedPaymentPlan = async (currentPaymentPlan, retrievePlansData = false) => {
    //     let cachedPaymentPlans = wizardPageData[WizardConstants.paymentPlans];
    //     if (!cachedPaymentPlans && retrievePlansData) {
    //         cachedPaymentPlans = await WniLoadSaveService.retrievePaymentPlans(quoteID,
    //             sessionUUID, authHeader);
    //     }
    //     //
    //     if (currentPaymentPlan.selectedPaymentPlan) {
    //         const exitingPaymentPlanType = PaymentUtil.getPaymentPlanType(
    //             currentPaymentPlan.selectedPaymentPlan, cachedPaymentPlans
    //         );
    //         updatePaymentViewType(exitingPaymentPlanType);
    //         return [];
    //     }

    //     const paymentPlanId = PaymentUtil.getPaymentPlanIDByTerm(termType, currentPaymentPlan, cachedPaymentPlans);

    //     let newBindData = null;
    //     if (paymentPlanId) {
    //         newBindData = await WniSxsQuoteService.updatePaymentPlan(quoteID,
    //             sessionUUID, paymentPlanId, authHeader);
    //     }
    //     return [cachedPaymentPlans, newBindData];
    // };

   

    useEffect(() => {
        const alreadyOwnedProducts = ActiveQuoteUtil.getAlreadyOwnedProducts(currentProductCode, policySummaries, accountActiveQuotes);
        updateAlreadyOwnedProductCode(alreadyOwnedProducts)
    }, [accountActiveQuotes, currentProductCode, policySummaries]);
    
    const initPaymentPlanViewType = async () => {
        const cachedValue = wizardPageData[WizardConstants.paymentViewType];
        if (cachedValue) {
            updatePaymentViewType(cachedValue);
            return cachedValue;
        }

        // let retval = paymentViewType;
        // const storedValue = await WniCommonQuoteService.getQuotePriceViewMode(quoteID,
        //     sessionUUID, authHeader);
        // if (!_.isEmpty(storedValue)) {
        //     updateBackendPaymentViewType(storedValue);
        //     if (storedValue !== defaultPaymentViewType) {
        //         updatePaymentViewType(storedValue);
        //     }
        //     retval = storedValue;
        // }
        // return retval;
        return paymentViewType;
    };

    const goToReadOnlyMode = useCallback((isUwLocked) => {
        updateServiceCallInProgress(false);
        updateWizardPageData({
            [WizardConstants.isUwLocked]: isUwLocked,
            [WizardConstants.paymentViewType]: paymentViewType
        });
        updateWizardReadOnly(true, { wizardData: submissionVM });
    }, [paymentViewType, submissionVM, updateWizardPageData, updateWizardReadOnly]);

    const updateSideBySideData = useCallback(async (optConfigs = {}) => {
        const {
            quoteErrorsAndWarnings = {},
            sxsDataRetriever = WniSxsQuoteService.retrieveSxsData,
            extraWizardPageData = {},
            // mockupBranchNames,
        } = optConfigs;
        // =========================================
        const sideBySideData = await sxsDataRetriever(quoteID, sessionUUID, authHeader);
        // -----------------------------------------
        // const sideBySideDataRepsonse = await sxsDataRetriever(quoteID, sessionUUID, authHeader);
        // const sideBySideData = IMQuoteMockupUtil.getMockupSxsData(submissionVM.value,
        //     {
        //         errorsAndWarnings: sideBySideDataRepsonse.errorsAndWarnings,
        //         mockupBranchNames,
        //     });
        // const branchNameMapList = sxsPeriods.map((sxsPeriod) => ({
        //     publicID: sxsPeriod.publicID,
        //     branchName: sxsPeriod.branchName,
        // }));
        // updateBranchNames(branchNameMapList);
        // =========================================

        let updatedErrorsWarnings = null;
        if (sideBySideData[SXS_LOB_NAME]) {
            updatedErrorsWarnings = _.get(sideBySideData, 'errorsAndWarnings');
        }
        //
        updatedErrorsWarnings = quoteErrorsAndWarnings;
        updateErrorsAndWarnings(updatedErrorsWarnings);

        //----------------------------------------------------------------------
        if (ErrorsAndWarningsUtil.hasValidationError(updatedErrorsWarnings)) {
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
        }
        //----------------------------------------------------------------------

        if (_.isEmpty(sideBySideData)) {
            return;
        }

        
        const {
            [SXS_LOB_NAME]: lobSxsDataDTO,
            accountJobs,
        } = sideBySideData;

        if (lobSxsDataDTO) {
            updateSxsDataDTO(sideBySideData);

            const newAccountActiveQuotes = accountJobs || accountActiveQuotes;

            //
            const newQuoteCount = QuoteUtil.getCountOfRatedQuotedApprovedQuotes(newAccountActiveQuotes);
            updateIsNewAccount(newQuoteCount === 1);

            //
            updateWizardPageData({
                [WizardConstants.sideBySideData]: sideBySideData,
                [WizardConstants.accountActiveQuotes]: newAccountActiveQuotes,
                ...extraWizardPageData,
            });
        }
    }, [accountActiveQuotes, authHeader, quoteID, sessionUUID, updateWizardPageData]);

    const updateSideBySideDataWithActiveQuotes = useCallback(async (optConfigs) => {
        await updateSideBySideData({
            sxsDataRetriever: WniSxsQuoteService.retrieveSxsDataWithActiveQuotes,
            ...optConfigs,
        });
    }, [updateSideBySideData]);

    const updateWizardData = useCallback((newSubmissionVM) => {
        // updateSubmissionVM(newSubmissionVM);
        updateWizardSnapshot(newSubmissionVM);
    }, [updateWizardSnapshot]);


    const withServiceInProgressUpdated = useCallback(async (cb, checkUwLockedStatus = false,
        serviceCallInvoker = true) => {
        updateServiceCallInProgress(serviceCallInvoker);
        const isUwLocked = false;
        if (checkUwLockedStatus && isExternalUser) {
            // isUwLocked = await WniCommonQuoteService.isUwLocked(quoteID, sessionUUID, authHeader);
            if (isUwLocked) {
                goToReadOnlyMode(isUwLocked);
            }
        }
        let retval = null
        if (!(isUwLocked && isExternalUser)) {
            try {
                retval = await cb();
            } catch (e) {
                const errorMessage = ServiceErrorUtil.getErrorMessage(e);
                modalHelper.alert({ errorMessage });
            } finally{
                updateServiceCallInProgress(false);
                setLoadingMask(false);
            }
        }
        return retval;
    }, [goToReadOnlyMode, isExternalUser, modalHelper]);

    useEffect(() => {
        setLoadingMask(isServiceCallInProgress);
    }, [isServiceCallInProgress, setLoadingMask]);

    useEffect(() => {

        const quoteErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');
        // updateIsNewAccount(_.get(submissionVM.value, 'baseData.numberOfQuotedOrRatedQuotes_Ext') === 1);
        withServiceInProgressUpdated(async () => {
            const currentPaymentViewType = await initPaymentPlanViewType();

            const updateSideBySideDataParams = {
                quoteErrorsAndWarnings,
                extraWizardPageData: { [WizardConstants.paymentViewType]: currentPaymentViewType },
            };

            const cachedSxsDataDTO = wizardPageData[WizardConstants.sideBySideData];
            if (cachedSxsDataDTO) {
                updateSideBySideDataParams.sxsDataRetriever = () => cachedSxsDataDTO;
            }
            await updateSideBySideDataWithActiveQuotes(updateSideBySideDataParams);
        });
        WindowUtil.scrollToTop();
    }, []);

    const onReferToUnderwriter = async (noteForUW, periodPublicID, triggerActivity) => {
        updateServiceCallInProgress(`referToUWBtn${periodPublicID}`);

        if (selectedVersion !== periodPublicID) {
            submissionVM.value = await WniCommonQuoteService.updateCurrentBranchAndRefreshWizardData(quoteID,
                sessionUUID, periodPublicID, authHeader);
            updateWizardData(submissionVM);
        }

        if (triggerActivity) {
            const activityData = ActivityUtil.getActivityData(
                translator(messages.activity19Subject),
                translator(messages.activity19Desc),
                'urgent', 'agent_risk_reservation_referral'
            );
            activityData.jobNumber = quoteID;
            await WniActivitiesService.createNewActivityAssignToUW(activityData, authHeader);
        }
        const jobResponse = await WniSubmissionService.referToUnderwriter(quoteID,
            noteForUW, authHeader);

        // const serverErrors = _.get(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
        const isUwLocked = _.get(jobResponse, 'uwlocked');
        const referToUWIssues = ErrorsAndWarningsUtil.extractServerErrors(jobResponse, 'errorsAndWarnings_Ext.serverErrors_Ext');
        if (!_.isEmpty(referToUWIssues)) {
            updateExtraBlockingSubmitIssues(referToUWIssues);
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
        } else if (isUwLocked) { // go to readonly mode regardless of Internal/External user
            // update flag before wizard mode is changed, so that PAQuotePage.readonly
            // does not get loading mask turned on
            goToReadOnlyMode(isUwLocked);
        }

        updateServiceCallInProgress(false);
    };

    const onFinishQuote = useCallback(async (periodPublicID) => {
        const quoteErrorsResponse = await withServiceInProgressUpdated(async () => {
            // const newQuoteDataDTO = await WniSxsQuoteService.finishSxsQuote(quoteID,
            //     periodPublicID, sessionUUID, authHeader);
            const newWizardDataDTO = await IMMultiQuoteService.finishQuote(quoteID, sessionUUID, authHeader);
            const quoteErrorsAndWarnings = _.get(newWizardDataDTO, 'errorsAndWarnings');

            // submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, newQuoteDataDTO);
            submissionVM.value = newWizardDataDTO;

            // await updateSideBySideData({ quoteErrorsAndWarnings });
            await updateSideBySideDataWithActiveQuotes({ quoteErrorsAndWarnings });

            updateWizardData(submissionVM);

            return quoteErrorsAndWarnings;
            
        }, true, `continueWithQuoteBtn${periodPublicID}`);

        
        //
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(quoteErrorsResponse);
        const pageIdToIssuesMap = ErrorsAndWarningsUtil.populateWizardPageLevelIssues(validationIssues, wizardStepToFieldMapping);
        updateAllWizardPageStickyIssues(pageIdToIssuesMap);
        const jumpFnToEarliestPage = ErrorsAndWarningsUtil.getJumpFnToEarliestPageWithIssue(
            validationIssues, wizardSteps, wizardStepToFieldMapping, wizardJumpTo
        );
        jumpFnToEarliestPage && jumpFnToEarliestPage();
    }, [authHeader, quoteID, sessionUUID, updateWizardData]);

    const onSubmitQuote = useCallback(async (sxsPeriodPublicID) => {
        // run final validation
        withServiceInProgressUpdated(async () => {
            const newWizardDataDTO = await IMMultiQuoteService.finishQuote(quoteID, sessionUUID, authHeader);
            const quoteErrorsAndWarnings = _.get(newWizardDataDTO, 'errorsAndWarnings');

            // TODO: replace this service with IMMultiQuoteService.retrieveRiskReservation()
            const accountClearanceData = await WniSxsQuoteService.retrieveRiskReservation(quoteID,
                sxsPeriodPublicID, sessionUUID, authHeader);

            const serviceErrors = ErrorsAndWarningsUtil.getServerErrorsAsValidationIssues(
                quoteErrorsAndWarnings
            );
            if (!_.isEmpty(serviceErrors)) {
                updateExtraBlockingSubmitIssues(serviceErrors);
            }
            // await updateSideBySideData({
            await updateSideBySideDataWithActiveQuotes({
                quoteErrorsAndWarnings,
                extraWizardPageData: { [WizardConstants.accountClearanceData]: accountClearanceData, }
            });

            const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(quoteErrorsAndWarnings);
            const pageIdToIssuesMap = ErrorsAndWarningsUtil.populateWizardPageLevelIssues(validationIssues, wizardStepToFieldMapping);
            updateAllWizardPageStickyIssues(pageIdToIssuesMap);

            // To be refactored===========================
            // const updatedStatus = _.get(submissionVM.value, 'baseData.periodStatus');
            // const [cachedPaymentPlans, bindData] = await updateSelectedPaymentPlan(paymentViewType,
            //     updatedStatus === 'Quoted');
            // ===========================================
            submissionVM.value = newWizardDataDTO;
            updateWizardData(submissionVM);
        }, true, `runFinalValidationBtn${sxsPeriodPublicID}`);
    }, [authHeader, quoteID, sessionUUID, updateWizardData]);

    const onContinueToIssue = useCallback(async (onNext, periodPublicID) => {
        updateServiceCallInProgress(`continueToIssueBtn${periodPublicID}`);
        if (isExternalUser) {
            // const isUwLocked = await WniCommonQuoteService.isUwLocked(quoteID, sessionUUID, authHeader);
            const isUwLocked = false;
            if (isUwLocked) {
                goToReadOnlyMode(isUwLocked);
                return;
            }
        }

        let selectedVersionUpdated = false;
        if (selectedVersion !== periodPublicID) {
            selectedVersionUpdated = true;
            await WniCommonQuoteService.updateCurrentBranchAndRefreshWizardData(quoteID,
                sessionUUID, periodPublicID, authHeader);
        }

        const readyForIssueErrorsAndWarnings = await WniSxsQuoteService.getReadyForIssueErrorsAndWarnings(
            quoteID, sessionUUID, authHeader
        );
        const hasValidationError = ErrorsAndWarningsUtil.hasValidationErrorOnQuotePage(readyForIssueErrorsAndWarnings);

        // history.push(`/internal/account-quotes-payment/${quoteID}`);
        if (hasValidationError) {
            if (selectedVersionUpdated) {
                submissionVM.value = await WniCommonQuoteService.refreshSubmissionWizardData(quoteID, sessionUUID, authHeader);
                updateWizardData(submissionVM);
            }

            // await updateSideBySideData();
            updateErrorsAndWarnings(readyForIssueErrorsAndWarnings);
            WindowUtil.scrollTo('quoteErrorsAndWarnings');
        } else {
            if (_.isEmpty(bindData) || _.isEmpty(quoteData) || selectedVersionUpdated) {

                if (selectedVersionUpdated) {
                    submissionVM.value = await WniCommonQuoteService.refreshSubmissionWizardData(quoteID, sessionUUID, authHeader);
                } else {
                    const quoteAndBindData = await WniLoadSaveService.retrieveQuoteAndBindData(quoteID, sessionUUID, authHeader);
                    submissionVM.value = DTOUtil.getUpdatedDTO(submissionVM.value, quoteAndBindData);
                }

                updateWizardData(submissionVM);
            }

            updateWizardPageData({ [WizardConstants.paymentViewType]: paymentViewType });
            updateServiceCallInProgress(false);
            
            
            // onNext();
            if (ActiveQuoteUtil.hasOnlyOneActiveJob(accountActiveQuotes)) {
                WizardPageJumpUtil.goToAccountPaymentPage(history, quoteID);
            } else {
                WizardPageJumpUtil.goToAccountSummaryPage(history, accountNumber);
            }
        }
        updateServiceCallInProgress(false);
    }, [accountActiveQuotes, accountNumber, authHeader, bindData, 
        goToReadOnlyMode, history, isExternalUser, paymentViewType,
        quoteData, quoteID, selectedVersion, sessionUUID, submissionVM, 
        updateWizardData, updateWizardPageData]);

    const onAddSideBySidePair = useCallback(async () => {
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await WniSxsQuoteService.createSxsPair(quoteID, sessionUUID,
                authHeader);
            await updateSideBySideData();

            updateWizardData(submissionVM);
        }, false, 'addSxsPairBtn');
    }, [authHeader, quoteID, sessionUUID, submissionVM, updateSideBySideData,
        updateWizardData, withServiceInProgressUpdated]);

    const onRemoveSideBySidePair = useCallback(async (sxsPeriodPublicID) => {
        modalApi.showConfirm({
            title: messages.deleteSideBySidePairTitle,
            // message: isForNamedNonOwner ? messages.deleteSideBySideNamedNonOwnerMessage : messages.deleteSideBySidePairMessage,
            message: messages.deleteSideBySidePairMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: messages.deleteSideBySidePairConfirm,
        }).then((result) => {
            if (result === 'cancel' || result === 'close') {
                return;
            }
            withServiceInProgressUpdated(async () => {
                submissionVM.value = await WniSxsQuoteService.deleteSxsPair(quoteID,
                    sxsPeriodPublicID, sessionUUID, authHeader);
                await updateSideBySideData();
                updateWizardData(submissionVM);
                // reset download dropdown options to default
                updateSelectedDocument('');
            }, false, `removeSxsPairBtn${sxsPeriodPublicID}`);
        }, _.noop);
    }, [authHeader, quoteID, sessionUUID, submissionVM, updateSideBySideData,
        updateWizardData, withServiceInProgressUpdated]);

    /**
     * References:
     * 1, IMQuoteClaueTableDataHelper.generateColumnData()
     * 2, QuoteClauseTableUIHelper.changeSubmissionAndSync()
     * 3, IMLineCoverages.changeSubmission(), updateSubmissionVMToServer()
     * 4, WatercraftCoveragesModal.changeSubmission(), updateSubmissionVMToServer()
     * 5, 
     *
     * Refactoring Notice: Replace positional parameters with named parameters
     * @param {string} value
     * @param {string} changedPath
     * @param {string} lobOfferingPath
     * @param {number} lobOfferingIndex
     */
    // See LineCoveragesInputComponent.changeSubmissinoAndSync()
    const onUpdateCoverage = useCallback(async (value, changedPath,
        lobOfferingPath, lobOfferingIndex) => {
        const lobCoveragesPath = `${LOB_OFFERING_PATH}[${lobOfferingIndex}].coverages`;
        const coverages = _.get(submissionVM.value, `${lobCoveragesPath}`);

        const offeringPeriodPublicID = _.get(submissionVM.value, `${lobOfferingPath}.publicID_Ext`);
        
        // // ======================================================================
        // // ref: IMLineCoverages::changeSubmission()
        // // ref2: WatercraftCoveragesModal::changeSubmission()
        // // WniClausesUtil.setClauseValue(submissionVM, value, changedPath);
        // let coveragePath = `${lobCoveragesPath}.lineCoverages`;
        // let coveragesDTO = _.get(submissionVM.value, coveragePath);
        // if (changedPath.includes('.coverages.watercraftCoverages')) {
        //     coveragePath = `${lobCoveragesPath}.watercraftCoverages`;
        //     coveragesDTO = _.get(submissionVM.value, coveragePath);
        // }
        // const coverageVMPath = coveragePath.replaceAll('[0]', '.children.0');
        // IMCoverageUtil.setClauseValue(coveragesDTO, coverageVMPath, value, changedPath);
        // // ======================================================================

        

        // const lobName = ClausesUtil.getLobNameFromPath(lobOfferingPath);
        // //
        // const offeringPeriodPublicID = _.get(submissionVM, `${lobOfferingPath}.publicID_Ext.value`);
        // //
        // const coverages = _.get(submissionVM, `${lobOfferingPath}.coverages.value`);
        // // Another solution:
        // // const lobOfferings = _.get(submissionVM.value, `lobData.${SXS_LOB_NAME}.offerings`);
        // // const { coverages } = lobOfferings[lobOfferingIndex];
        // // const clausesToUpdate = IMCoverageUtil.generateUpdatedCoveragesDTO(coverages, SXS_LOB_NAME);
        // const clausesToUpdate = IMCoverageUtil.generateUpdatedCoveragesDTO(coverages, SXS_LOB_NAME);

        // const sxsPeriods = _.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`, []);
        // const sxsPeriod = QuoteUtil.getSideBySidePeriod(sxsPeriods, offeringPeriodPublicID);
        // const previousPeriodStatus = _.get(sxsPeriod, 'periodStatus');

        // // the usage of withServiceInProgressUpdated here breaks isClauseLoading. Hmm.
        // updateServiceCallInProgress(true);
        
        // // to be re-opened after IMCoveragesService becomes ready
        // const updatedLobCoveragesDTO = await IMCoveragesService.updateCoverages(quoteID, sessionUUID, 
        //     clausesToUpdate, authHeader);
        // const { 
        //     errorsAndWarnings: newErrorsAndWarnings,
        //     [SXS_LOB_NAME]: lobCoverages
        // } = updatedLobCoveragesDTO;

        // _.set(submissionVM.value, lobCoveragesPath, lobCoverages)
        // _.set(submissionVM.value, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);

        // updateErrorsAndWarnings(newErrorsAndWarnings);
        // updateWizardData(submissionVM);

        // if (previousPeriodStatus !== 'Draft') {
        //     // await updateSideBySideData();
        //     await updateSideBySideDataWithActiveQuotes();
        // }
        // updateServiceCallInProgress(false);
        
    }, [authHeader, quoteID, sessionUUID, submissionVM, sxsDataDTO,
        updateSideBySideDataWithActiveQuotes, updateWizardData]);

    const afterUpdateCoverage = useCallback(async () => {
        const sxsPeriods = _.get(sxsDataDTO, `${SXS_LOB_NAME}.periods`, []);
        const sxsPeriod = QuoteUtil.getSideBySidePeriod(sxsPeriods, selectedVersion);
        const previousPeriodStatus = _.get(sxsPeriod, 'periodStatus');
        //
        if (previousPeriodStatus !== 'Draft') {
            // await updateSideBySideData();
            await updateSideBySideDataWithActiveQuotes();
        }
    }, [authHeader, quoteID, sessionUUID, submissionVM, sxsDataDTO,
        updateSideBySideDataWithActiveQuotes, updateWizardData]);

    const onRateAll = async (sxsPeriodPublicID) => {
        if (!isComponentValid) {
            updateShowErrors(true);
            WindowUtil.scrollTo(invalidFields);
            return;
        }
        withServiceInProgressUpdated(async () => {
            // submissionVM.value = await WniSxsQuoteService.quoteAll(quoteID, sessionUUID, authHeader);
            submissionVM.value = await IMMultiQuoteService.processQuote(quoteID, sessionUUID, authHeader);
            updateWizardData(submissionVM);

            const quoteErrorsAndWarnings = _.get(submissionVM.value, 'errorsAndWarnings');

            const updatedStatus = _.get(submissionVM.value, 'baseData.periodStatus');
            // if (updatedStatus !== 'Draft') {
            // await updateSideBySideData({ quoteErrorsAndWarnings });
            await updateSideBySideDataWithActiveQuotes({ quoteErrorsAndWarnings });
            // }
            // reset download dropdown options to default
            updateSelectedDocument('');
        }, false, `getUpdatedRateBtn${sxsPeriodPublicID}`);
    };

    const onAccountClearance = useCallback(async (periodPublicID) => {
        const cachedData = _.get(wizardPageData, WizardConstants.accountClearanceData, {});
        return cachedData;
    }, [wizardPageData]);

    const postAccountMerge = useCallback(async () => {
        withServiceInProgressUpdated(async () => {
            const issueCodes = ['ProtalHasRiskIssuePolicy', 'ProtalHasRiskMatch'];
            await WniLoadSaveService.approveUnderwritingIssueByCodes(
                quoteID, sessionUUID, issueCodes, authHeader
            );
            await updateSideBySideData();
            //
            submissionVM.value = await WniCommonQuoteService.refreshSubmissionWizardData(quoteID, sessionUUID, authHeader);

            updateWizardSnapshot(submissionVM);
            updateIsNewAccount(false);
        });
    }, [authHeader, quoteID, sessionUUID, submissionVM,
        updateSideBySideData, updateWizardSnapshot, withServiceInProgressUpdated]);

    // =======================================
    const onSwitchToIMVersion = useCallback(async (periodPublicID) => {
        withServiceInProgressUpdated(async() => {
            if (selectedVersion === periodPublicID) {
                return;
            }

            submissionVM.value = await WniCommonQuoteService.updateCurrentBranchAndRefreshWizardData(quoteID,
                sessionUUID, periodPublicID, authHeader);
            // await updateSideBySideData();
            await updateSideBySideDataWithActiveQuotes();
            updateWizardData(submissionVM);
        });
    }, [submissionVM, selectedVersion]);

    const onCreateNewIMVersion = useCallback(async (createService) => {
        withServiceInProgressUpdated(async () => {
            submissionVM.value = await createService(quoteID, sessionUUID, authHeader);
            await updateSideBySideDataWithActiveQuotes();
            updateWizardData(submissionVM);
        });
    }, [submissionVM]);

    const onUpdateBranchName = useCallback(async (newBranchName) => {
        withServiceInProgressUpdated(async () => {
            await IMMultiQuoteService.updateBranchName(quoteID, sessionUUID, newBranchName, authHeader);
            // await updateSideBySideData();
            
            await updateSideBySideDataWithActiveQuotes();
            // const activeQuotes = await WniCommonQuoteService.getActiveQuotes(quoteID, sessionUUID, authHeader);
            // updateWizardPageData({
            //     [WizardConstants.accountActiveQuotes]: activeQuotes,
            // });

            // //
            // _.set(submissionVM.value, BRANCH_NAME_PATH, newBranchName);
            // updateWizardData(submissionVM);
        });
    }, [updateSideBySideDataWithActiveQuotes]);

    const onWithdrawBranch = useCallback(async () => {
        const confirmWithDrawn = await modalApi.showConfirm({
            title: QuoteCommonMessages.deleteThisQuoteVersion,
            message: QuoteCommonMessages.deleteThisQuoteVersionConfirmMessage,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: QuoteCommonMessages.deleteThisQuoteVersionConfirmConfirm,
        }).catch(_.noop);
        if (confirmWithDrawn !== 'confirm') {
            return;
        }
        withServiceInProgressUpdated(async () => {
            const response = await IMMultiQuoteService.withdrawBranch(quoteID, sessionUUID, selectedVersion, authHeader);
            submissionVM.value = response;

            await updateSideBySideDataWithActiveQuotes();

            updateWizardData(submissionVM);

            // PC OOTB Logic: submission will be locked after a branch is withdrawn
            // goToReadOnlyMode(true);
        });
    }, [submissionVM, selectedVersion]);

    const onStartNewSubmission = useCallback(async (productCode, countryCode) => {
        const effectiveDate = getProductMinDate(productCode, policyState) || pcServerSystemDate;
        withServiceInProgressUpdated(async () => {
            const newSubmissionDTO = {
                accountNumber,
                country: countryCode,
                state: policyState,
                // effectiveDate: periodEffectiveDate, // date must not be in the past
                effectiveDate: effectiveDate,
                productCode: productCode,
                producerCode: periodProducerCodeStr,
                producerCodePublicID_Ext: periodProducerCodePubliID,
    
            };

            // Invokes SubmissionHandler.createNewSubmission(NewSusbmissionDTO): SubmissionDTO
            const submissionDTO = await SubmissionService.createSubmission(newSubmissionDTO, authHeader);
            
            const { jobNumber: newQuoteID } = submissionDTO;
            if (newQuoteID) {
                await WniSubmissionService.addRecentlyViewedSubmission(newQuoteID, authHeader);
                WizardPageJumpUtil.goToNewQuoteWizard(history, {
                    productCode,
                    postalCode,
                    jobID: newQuoteID
                });
            }
            
        });
    }, [pcServerSystemDate]);

    const renderCustomWizardPageFooterContent = useCallback(() => {
        return (<>
            <Button id="wni-back-to-account-summary" type="outlined" onClick={goToAccountSummaryPage}>
                {QuoteCommonMessages.backToAccountSummary}
            </Button>
            <QuoteAdditionalProductComponent
                productAvailableForAccount={productAvailableForAccount}
                alreadyOwnedProductCode={alreadyOwnedProductCode}
                startSubmissionFn={onStartNewSubmission}
                accountNumber={accountNumber}
                accountType={accountType}
                baseState={policyState}
                dropUp
            />
        </>);
    }, [goToAccountSummaryPage, alreadyOwnedProductCode, onStartNewSubmission, accountNumber]);


    
    const getDocumentDownloadFunc = useCallback((downloadOptions) => {

        return async () => {
            if (_.isEmpty(downloadOptions)) {
                return;
            }
            const value = downloadOptions[0];
            const selectedVersionPublicID = _.get(value, 'periodPublicID');
            const selectedVersionPairPeriodPublicID = _.get(value, 'pairPeriodPublicID');
            const onDemandDoc = _.get(value, 'docName');
            updateSelectedDocument(value);
            updateServiceCallInProgress(true);
            const doc = await WniSubmissionService.getOnDemandQuoteSummaryDocument(
                [quoteID, selectedVersionPublicID,
                    selectedVersionPairPeriodPublicID, onDemandDoc], authHeader
            );
            const successCallback = () => {
                updateServiceCallInProgress(false);
            };
            const errorCallback = () => {
                updateServiceCallInProgress(false);
                modalApi.showConfirm({
                    title: 'Error',
                    message: ServiceErrorUtil.prependWithFriendMessage(),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: messages.ok,
                }).then(_.noop).catch(_.noop);
            };
            await DocumentsUtil.tryDownloadDocument(
                doc, authHeader, history, WniDocumentRetrievalService, successCallback, errorCallback
            );
        }
    }, []);


    const generateOverrides = useCallback((onNext) => {
        const {
            [WizardConstants.accountClearanceData]: {
                matchAccounts,
                poMatchAccounts,
            } = {},
        } = wizardPageData;
        
        const imSxsDataDTO = _.get(sxsDataDTO, SXS_LOB_NAME);
        const { policyLineSummary, } = imSxsDataDTO || {};

        const hasMatchingAccount = !(_.isEmpty(matchAccounts) && _.isEmpty(poMatchAccounts));
        const showAccountClearanceLink = isNewAccount && hasMatchingAccount;

        const [isClueCompare, isMvrCompare] = IncidentsUtil.getClueMVRCompareFlags(submissionVM.value);
        const isMvrClueServiceCalled = IncidentsUtil.checkMvrClueServiceCallStatus(submissionVM.value);

        const preQuoteBlockUWIssues = ErrorsAndWarningsUtil.filterBlockingUWIssuesBasedOnPeriodFromEW(errorsAndWarnings, selectedVersion);
        
        
        const validationIssues = ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings, extraBlockingSubmitIssues);
        const filteredValidationIssues = validationIssues.filter(ErrorsAndWarningsUtil.isIssueOnOrBeforeQuotPage);
        const issueJumpFnMap = ErrorsAndWarningsUtil.getValidationIssueJumpFnMap(validationIssues,
            wizardSteps, wizardStepToFieldMapping, wizardJumpTo);
        const sortByFlowStepFunc = ErrorsAndWarningsUtil.getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping);

        const combinedIssues = IssuanceValidationUtil.getIssuesMap(filteredValidationIssues);
        const resolveErrorButtonParams = {
            wizardSteps,
            wizardStepToFieldMapping,
            wizardJumpTo,
            resolveButtonLabel: translator(QuoteCommonMessages.resolveErrors),
            isServiceCallInProgress,
        };
        const issueFooterFormatter = getQuotePageErrorsAndWarningsFooter(resolveErrorButtonParams);
        const retrunToQuoteButton = renderResolveButton({
            ...resolveErrorButtonParams,
            validationIssues: combinedIssues,
            resolveButtonLabel: translator(QuoteSideBySidePeriodMessages.returnToQuote),
        });

        const columnData = generateColumnData({
            submissionVM,
            sxsDataDTO,
            // filterLobOfferings: true,
            isClueCompare,
            isMvrCompare,
            isMvrClueServiceCalled,
            showAccountClearanceLink,
            currentAccountNumber: accountNumber,
            // paymentMethod: paymentViewType,
            isMonthlyPayment: PaymentUtil.isMonthlyPaymentPlan(paymentViewType),
            preQuoteBlockUWIssues,
            retrunToQuoteButton,
            // coveragesForm,
            callbackMap: {
                onFinishQuote,
                onSubmitQuote,
                onReferToUnderwriter,
                onContinueToIssue: async (periodPublicID) => onContinueToIssue(onNext, periodPublicID),
                onRateAll,
                goToIncidentReports,
                onAccountClearance,
                postAccountMerge,
                // goToDriverCoveragesPage,
                //
                goToCoveragesPage,
                //
                // onCallVirtualInspectionReport,
                goToQuoteDetailsPage,
            }
        });

        const imSxsPeriods = _.get(imSxsDataDTO, 'periods', []);
        const selectedSxsPeriod = QuoteUtil.getSideBySidePeriod(imSxsPeriods, selectedVersion);

        // const tableData = generateTableData(submissionVM, columnData, translator, sxsDataDTO);
        const canAddSxsPair = QuoteUtil.canAddSideBySidePair(imSxsPeriods);

        
        const calculatutedPeriods = _.filter(imSxsPeriods, (period) => _.get(period, 'periodStatus') !== 'Draft');
        const downloadOptions = DocumentsUtil.getOnDemandDcouments(calculatutedPeriods, LOB_NAME);
        const handleDownload = getDocumentDownloadFunc(downloadOptions);

        //
        const branchName = _.get(selectedSxsPeriod, BRANCH_NAME_PATH);
        const hasMultiplePeriods = ActiveQuoteUtil.hasMultiplePeriodsForJob(accountActiveQuotes, quoteID);
        const accountJobPeriodDTOList = ActiveQuoteUtil.filterAccountJobPeriodDTOList(accountActiveQuotes, quoteID, selectedVersion);
        
        
        return {
            '@field': {
                // apply to all fields
                // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
                labelPosition: 'left',
            },
            errorsAndWarningsSection: {
                validationIssues: combinedIssues,
                issueJumpFnMap,
                typeTitleFormatter: getQuotePageErrorsAndWarningsTitle(translator),
                typeFooterFormatter: issueFooterFormatter,
                issueRenderFn: getQuotePageIssueRenderFn,
                scrollToIssues: true,
                getSortKeyForIssueWithSameType: sortByFlowStepFunc,
                issueDistinguisher: true,
            },
            quoteStatus: {
                // content: displayStatus,
                visible: false,
            },
            addNewVersionBtn: {
                sxsDataDTO: imSxsDataDTO,
                onCreateNewVersion: onCreateNewIMVersion,
                //
                accountJobPeriodDTOList,
                onSwitchToBranch: onSwitchToIMVersion,
            },

            sxsPeriodsInfoSection: {
                quoteID,
                columnData,
                onRemoveSideBySidePair,
                isServiceCallInProgress,
                // sxsPeriodInfoRowFilter: ({ rowId }) => rowId !== 'branchName',
                sxsTableRows: SxsTableRowProfiles.HoRows,
            },
            noSxsDataMessage: {
                visible: !isServiceCallInProgress && _.isEmpty(columnData),
            },
            // ===========================
            branchInfoEditSeciton: {
                visible: hasMultiplePeriods,
            },
            branchNameEdit: {
                text: branchName,
                editButtonLabel: QuoteSideBySidePeriodMessages.editQuoteVersionName,
            },
            //
            // quoteTable: {
            //     visible: false,
            //     columnData,
            //     tableData,
            //     // visible: !_.isEmpty(columnData),
            //     onValidate,
            //     showErrors,
            //     //
            //     clauseTableRenderHelper: IMQuoteClauseTableUIHelper,
            // },
            // quoteTableV2: {
            //     visible: false,
            //     //
            //     ...props,
            //     //
            //     onValidate,
            //     showErrors,
            //     //
            //     afterUpdateCoverage,
            //     updateErrorsAndWarnings,
            // },
            policyLineSummary: {
                policyLineSummary,
            },
            downloadAction: {
                onClick: handleDownload,
                disabled: isServiceCallInProgress || (periodStatus !== 'Quoted'),
                // visible: (periodStatus !== 'Quoted') ? true : !_.isEmpty(downloadOptions)
                visible: false, // POI-53400
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakpoint, submissionVM, isServiceCallInProgress, errorsAndWarnings,
        sxsDataDTO, translator, extraBlockingSubmitIssues,
        paymentViewType, wizardPageData, isNewAccount,
        selectedDocument, showErrors, accountActiveQuotes, onSwitchToIMVersion,
        selectedVersion, afterUpdateCoverage,]);

    const renderQuotePage = useCallback(({ onNext }) => {

        const overrideProps = generateOverrides(onNext);
        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onAddSideBySidePair: onAddSideBySidePair,
                onChangeSubmissionAndSync: onUpdateCoverage,
                onUpdateBranchName,
                onWithdrawBranch,
                // onSyncCoverages: _.noop,
            },
            resolveComponentMap: {
                validationissuescomponent: ValidationIssuesComponent,
                errorsandwarningsdisplaycomponent: ErrorsAndWarningsDisplayComponent,
                sxsquoteperiods: SideBySidePeriodsComponent,
                // withdrawtransactioncomponent: WithdrawTransactionComponent,
                // accountClearanceComponent: AccountClearanceComponent
                nottakencomponent: MarkNotTakenComponent,
                addnewversioncomponent: IMAddNewVersionComponent,
                texteditconfirm: TextEditConfirmComponent,
                //
                // quoteclausetablecomponentv2: IMQuoteClauseTableComponent,
                policylinesummary: IMPolicyLineSummaryComponent,
            }
        };

        // const isLoading = isServiceCallInProgress && !sxsDataDTO;
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={{}}
                overrideProps={overrideProps}
                // onModelChange={updateFormData}
                // onValueChange={writeValue}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                // onValidationChange={onValidate}
            />
        );
    }, [generateOverrides, submissionVM, updateWizardData, accountActiveQuotes]); // sxsDataDTO

    //---------------------
    const isInitializing = isServiceCallInProgress && !sxsDataDTO;
    return (
        <WizardPage
            showNext={false}
            // showPrevious={false}
            // showCancel={false}
            // customFooterRenderFunc={wizardPageFooterContentRender}
            renderCustomWizardPageFooterContent = {renderCustomWizardPageFooterContent}
        >
            {!isInitializing && renderQuotePage}
        </WizardPage>
    );
}

IMQuotePage.propTypes = {
    ...wizardProps,
};

IMQuotePage.defaultProps = {
    ...WizardPage.defaultProps,
};
export default IMQuotePage;