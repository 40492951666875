import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleItemTitle: {
        id: 'InlandMarine.boatCoverage.schedules.table.Boat, Motor & Trailer',
        defaultMessage: 'Boat, Motor & Trailer'
    },
    scheduleItem: {
        id: 'InlandMarine.boatCoverage.schedules.scheduleItem.Schedule Item',
        defaultMessage: 'Schedule Item',
    },
});