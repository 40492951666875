// import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
// import { ServiceManager } from '@jutro/legacy/services';
import {
    ViewModelForm,
    ViewModelServiceContext,
} from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import React, { useContext } from 'react';
import { AddressInputComponent } from 'wni-capability-gateway-react';
import metadata from './LocationDetails.metadata.json5'

const RESET_LOCATIONS_PATH_MAPS = [
    'address',
    'country',
    'city',
    'county',
    'state',
];
const RESET_LOCATIONS_PATH_MAPS_ONVALUECHANGE = ['address', 'state'];

function LocationDetails(props) {
    const {
        currentRow,
        onValidate,
        onValueChange,
        syncWizardDataSnapshot,
        showErrors,
        isReadOnly,
        externalData: { jobID, sessionUUID, authHeader },
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');



    const onAddressChange = async (value, path) => {
        onValueChange(value, path);
    };

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: false,
            showRequired: true,
            readOnly: isReadOnly,
        },
        addressLookup: {
            model: currentRow,
            dataPath: 'address',
            onAddressChange: onAddressChange,
            hideFieldType: {
                addressType: true,
                country: true,
                pobox: true,
            },
            showErrors,
            onValidate,
            readOnly: isReadOnly,
            defaultCountryRequired: false,
        },
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.pageContent,
            currentRow,
            fieldId,
            fieldPath,
            overrideProps
        );
    };

    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            addressinput: AddressInputComponent,
        },
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={currentRow}
            overrideProps={overrideProps}
            onValidationChange={onValidate}
            onValueChange={onValueChange}
            resolveValue={readValue}
            showErrors={showErrors}
            {...resolvers}
        />
    );
}

export default LocationDetails;
