import React, {useState, useCallback, useMemo, useEffect} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useTranslator } from '@jutro/locale';
import {
    QuoteUtil,
    ErrorsAndWarningsUtil,
} from 'wni-portals-util-js';
import { PortalConstants } from 'wni-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { IMWarehouseLegalLiabilityService as  WarehouseLegalLiabilityService} from 'wni-capability-quoteandbind-im';
import CoveragesConfigContext from '../../context/IMCoveragesConfigContext';
import IMCoveragesConfig from '../../config/IMCoverages.config';
import metadata from './IMWarehouseLegalLiabilityPage.metadata.json5';
import IMSearchAndAddClausesPopup from '../../components/IMSearchAndAddClausesPopup/IMSearchAndAddClausesPopup';
import WizardPage from '../../templates/IMWizardPage';
import IMCommonMainCoverage from '../../components/IMCommonMainCoverage/MainCoveragesCard';
import IMCommonCovPartSchedule from '../../components/IMComonCovPartSchedule/CommonCovPartSchedule';
import IMCommonAdditionalCovCard from '../../components/IMCommonAdditionalCovCard/IMCommonAdditionalCovCard';
import IMExclusionAccordionCard from '../../components/IMCommonExclusionCard/IMCommonExclusionCard';
import messages from './IMWarehouseLegalLiabilityPage.messages';

const IMWarehouseLegalLiabilityPage = (props) => {
    const modalApi = useWniModal();
    const {
        wizardData: submissionVM,
        updateWizardData,
        isEditable = true,
        //
        // onPreQuotePageNext,
    } = props;

    const IMWAREHOUSE_ACCORDION_CARD_COMPONENT_MAPS = ['mainCovCard', 'supplementalCovCard', 'scheduleCovCard']

    const { initialValidation, onValidate, isComponentValid, invalidFields } = useValidation('IMWarehouseLegalLiabilityPage');
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const [validationIssues, updateValidationIssues] = useState([])
    const [isEditing, setIsEditing] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const [warehouseLegalLiabilityClauses, setWarehouseLegalLiabilityClauses] = useState()
    const [warehouseLegalLiabilityLoadingClauses, setWarehouseLegalLiabilityLoadingClauses] = useState()

    const {
        jobID,
        sessionUUID,
        baseData: { 
            periodStatus
        }
    } = submissionVM.value

    const {
        loadingMask: { isLoadingMask, setLoadingMask },
    } = useDependencies('loadingMask');

    const pageLevelValidation = useMemo(
        () => validationIssues,
        [
            validationIssues
        ]
    );

    // Middle layer introduced for the convenience of debugging
    const updateErrorsAndWarningsForCovComponent = (errorsAndWarnings) => {
        const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
        updateValidationIssues(newValidationIssues);
    }
    const calculateLimit = useCallback(async (termCode) => {
        setLoadingMask(true)
        const res = await WarehouseLegalLiabilityService.calculateLimit(jobID, sessionUUID, termCode, authHeader)
        setWarehouseLegalLiabilityClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, sessionUUID, setLoadingMask]) 

    const initCoverablePartClauses = useCallback(async () => {
        setLoadingMask(true)
        const isDraftPeriod = periodStatus ===  PortalConstants.QUOTE_STATUS_DRAFT

        const res = isDraftPeriod ? await WarehouseLegalLiabilityService.getWarehouseLegalLiabilityClausesWithSync(jobID, sessionUUID, authHeader) 
            : await WarehouseLegalLiabilityService.getWarehouseLegalLiabilityClauses(jobID, sessionUUID, authHeader)
        const {
            errorsAndWarnings,
        } = res
        updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
        setWarehouseLegalLiabilityClauses(res)
        setLoadingMask(false)
    }, [authHeader, jobID, periodStatus, sessionUUID, setLoadingMask]) 

    useEffect(() => {
        initCoverablePartClauses()
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showClausesModal = useCallback((clauseType) => {
        const componentProps = {
            showCloseBtn: false,
            showCancelBtn: false,
            serviceProps: {
                jobID,
                sessionUUID,
                authHeader
            },
            setLoadingMask,
            clauseType,
            coverableService: WarehouseLegalLiabilityService,
            calculateLimit
        };
        return modalApi.showModal(
            <IMSearchAndAddClausesPopup {...componentProps} />
        );
    }, [authHeader, calculateLimit, jobID, modalApi, sessionUUID, setLoadingMask]);

    const onClickAddSearchClauses = useCallback(async (clauseType) => {
        const data = await showClausesModal(clauseType)
        setWarehouseLegalLiabilityClauses(data)
    },[showClausesModal])

    const setWizardDataToDraft = useCallback((newSubmissionVM) => {
        // Refactoring Notice: Consider to extract this into a common method
        _.set(newSubmissionVM, 'baseData.periodStatus', PortalConstants.QUOTE_STATUS_DRAFT);
        return newSubmissionVM
    }, []);

    const updateWizardDataWhileSetPeriodStatus = (newSubmissionVM) => {
        setWizardDataToDraft(newSubmissionVM)
        updateWizardData(newSubmissionVM)
    };

    const onPageNext = async () => {
        if(isEditable){

            const {
                onPreQuotePageNext, // = onDefaultPageNext,
            } = props;
    
            setLoadingMask(true);
            const errorsAndWarnings = await WarehouseLegalLiabilityService.onWarehouseLegalLiabilityNext(jobID, sessionUUID, authHeader);      
            updateErrorsAndWarningsForCovComponent(errorsAndWarnings);
            const newValidationIssues = _.uniqBy(ErrorsAndWarningsUtil.getValidationIssues(errorsAndWarnings), 'reason');
            const hasErrorIssues = newValidationIssues.some((issue) => {
                return issue.type === 'error';
            });
            const hasNewErrorsOrWarnings = ErrorsAndWarningsUtil.hasNewValidationIssue(validationIssues, newValidationIssues);
            if (hasErrorIssues || hasNewErrorsOrWarnings) {
                return false;
            }
            setLoadingMask(false);

            // return submissionVM;
            let retval = submissionVM;
            if (_.isFunction(onPreQuotePageNext)) {
                retval = await onPreQuotePageNext();
            }
            return retval;
        }
        return submissionVM;
    }

    const handleError = () => {
        setShowErrors(true)
        return false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const accordionCommonProps = {
            coveragePartClauses: warehouseLegalLiabilityClauses,
            setCoveragePartClauses: setWarehouseLegalLiabilityClauses,
            isEditing,
            setIsEditing,
            isEditable: isEditable,
            showErrors,
            onValidate: onValidate,
            loadingClauses: warehouseLegalLiabilityLoadingClauses,
            setLoadingClauses: setWarehouseLegalLiabilityLoadingClauses,
            onClickAddSearchClauses,
            wizardData: submissionVM,
            updateWizardDataWhileSetPeriodStatus,
            updateCoverageFormClausesService: WarehouseLegalLiabilityService.updateWarehouseLegalLiabilityClauses
        }

    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                isEditable: isEditable,
            },
            // accor
            coverages: {
                errorState: !_.isEmpty(_.intersection(invalidFields, IMWAREHOUSE_ACCORDION_CARD_COMPONENT_MAPS))
            },
            mainCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(warehouseLegalLiabilityClauses, 'mainCoverage'),
                coverageFormPath: 'mainCoverage',
                calculateLimit
            },
            supplementalCovCard: {
                ...accordionCommonProps,
                mainCoverage: _.get(warehouseLegalLiabilityClauses, 'supplementalCoverage'),
                coverageFormPath: 'supplementalCoverage'
            },
            scheduleCovCard: {
                ...accordionCommonProps,
                coveragePartSchedule: _.get(warehouseLegalLiabilityClauses, 'scheduledIMWarehouse'),
                updateScheduleService: WarehouseLegalLiabilityService.updateWarehouseLegalLiabilitySchedule,
                schedulePath: 'scheduledIMWarehouse',
                scheduleLable: translator(messages.ListOfScheduleItems),
                scheduleFormHeader: translator(messages.ScheduleDetails)
            },
            additionalAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'WarehouseLegalLiability'
            },
            exclusionAccordionCard: {
                ...accordionCommonProps,
                coveragePartName: 'WarehouseLegalLiability'
            }
        }
    }, [accordionCommonProps, warehouseLegalLiabilityClauses, calculateLimit, translator]);

    
    const overrideProps = generateOverrides();
    const resolvers = {
        callbackMap: {
        },
        componentMap: {
            MainCovCard: IMCommonMainCoverage,
            SupplementalCovCard: IMCommonMainCoverage,
            ScheduleCovCard: IMCommonCovPartSchedule,
            AdditionalAccordionCard: IMCommonAdditionalCovCard,
            ExclusionAccordionCard: IMExclusionAccordionCard,
        },
    };

    const disableNavigator = isEditing || isLoadingMask || warehouseLegalLiabilityLoadingClauses

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            disableNext={disableNavigator}
            disablePrevious={disableNavigator}
            disableCancel={disableNavigator}
            onNext={isComponentValid ||!isEditable? onPageNext :handleError}
            alwaysCallOnNext
            showRequiredInfoForFasterQuote
            pageLevelValidationIssues={pageLevelValidation}
        >
            {warehouseLegalLiabilityClauses && 
                <CoveragesConfigContext.Provider value={IMCoveragesConfig}>
                    <ViewModelForm
                        uiProps={metadata.pageContent}
                        model={submissionVM}
                        overrideProps={overrideProps}
                        showErrors={showErrors}
                        onValidationChange={onValidate}
                        callbackMap={resolvers.callbackMap}
                        componentMap={resolvers.componentMap}
                    />
                </CoveragesConfigContext.Provider>
            }
        </WizardPage>
    );
}

export default IMWarehouseLegalLiabilityPage
