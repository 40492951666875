import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleFormHeader: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.buildersRisk.scheduleFormHeader",
        "defaultMessage": "Receptacle Details"
    },
    scheduleLable: {
        "id": "quoteandbind.im.wizard.step.inlineMarine page.buildersRisk.scheduleLable",
        "defaultMessage": " "
    }
});