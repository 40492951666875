import React from 'react';
import _ from 'lodash';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import {
    ErrorsAndWarningsUtil,
    ValidationIssueUtil,
    QuoteCoveragesUtil,
    QuoteUtil
} from 'wni-portals-util-js';
import { DomRenderUtil } from 'wni-portals-util-react';
import { QuoteCommonMessages } from 'wni-platform-translations';

// import messages from 'gw-capability-quoteandbind-pa-react/pages/Quote/QuotePage.messages';
import QuotePageConstants from '../config/CPPQuotePage.config';

import customMessages from '../CPPQuotePage.messages';

import { Button } from '@jutro/legacy/components';

const {
    LOB_NAME,
    LOB_OFFERING_PATH,
    LOB_COVERABLE_VEHICLES_PATH,
    SXS_PERIODS_PATH,
    SXS_COVERAGES_PATH,
    //
    LINE_COVERAGES_PATH,
    SXS_VEHICLE_SETS_PATH,
    VEHICLE_COVERAGES_PATH,
    //
    VEHICLE_COVERAGE_OWNING_COVERABLE_ID,
} = QuotePageConstants;

function getVehicleDetailsDesc(cppWatercraftDetailsDTO) {
    if (!cppWatercraftDetailsDTO) {
        return '';
    }
    const {
        year = '',
        manufacturer = '',
        model = '',
    } = cppWatercraftDetailsDTO;
    const retval = [year, manufacturer, model].join(' ').trim();
    return retval;
}
function getVehicleDescTitle(cppWatercraftDTO, titleFormatter) {
    const {
        watercraftDetail,
        motorDetail,
        trailerDetail,
    } = cppWatercraftDTO;
    const retval = titleFormatter({
        watercraftDetailDesc: getVehicleDetailsDesc(watercraftDetail),
        motorDetailDesc: getVehicleDetailsDesc(motorDetail),
        trailerDetailDesc: getVehicleDetailsDesc(trailerDetail),
    });
    return retval;
}


function getFixedId(cppCoverageDTO) {
    // reutrn cppCovrageDTO.fixedId;
    // return cppCovrageDTO.publicID;
    return cppCoverageDTO[VEHICLE_COVERAGE_OWNING_COVERABLE_ID];
}

function getCoverableVehicleID(cppWatercraftDTO) {
    return cppWatercraftDTO.publicID;   
}


// ===================================================================================

function structureClauseTableData(coverage) {
    // putting ID into an object as the Jutro table component expects an object
    return {
        publicID: coverage.publicID,
        codeIdentifier: coverage.code_Ext,
        // to be used in pouplateCovGroupWithCategoryPremium()
        categoryCode: coverage.coverageCategoryCode,
    };
}

/**
 * Consider to extract this method into CPPQuoteCoeveragesUtil
 * 
 * See QuoteCoveragesUtil.filterCovOnQuotePage()
 * 
 * @param {Object} coverageDTO CoverageDTO
 * @returns {boolean}
 */
function filterCovOnCPPQuotePage(coverageDTO) {
    const {
        code_Ext: clausePatternCode,
        selected
    } = coverageDTO;

    return selected;
}


function generateClauseData(columnData, coveragePath,
    // completeCoveragePathGetter,
    vehicleFixedId = undefined) {
    // const covPathGetter = completeCoveragePathGetter || (() => `coverages.${coveragePath}`);

    const retval = columnData.map(({ lobOffering, code }) => {
        let completeCoveragePath = `coverages.${coveragePath}`;
        // const completeCoveragePath = covPathGetter(lobOffering);

        if (!_.isNil(vehicleFixedId)) {
            const vehicleCoverages = _.get(lobOffering, `data.coverages.${VEHICLE_COVERAGES_PATH}`);

            // const vehicleCoveragesMap = _.get(lobOffering, `data.coverages.${VEHICLE_COVERAGES_PATH}`);
            // const vehicleCoverages = Object.entries(vehicleCoveragesMap);
            const vehicleCoverageIndex = vehicleCoverages.findIndex((vc) => getFixedId(vc) === vehicleFixedId);

            // const vehicleCoverageIndex = vehicleCoverages.findIndex((vc) =>  vc[0] === vehicleFixedId);
            
            completeCoveragePath = `coverages.${coveragePath}.children[${vehicleCoverageIndex}].coverages`;
            // completeCoveragePath = `coverages.${coveragePath}[${vehicleFixedId}]`;
        }
        // if (completeCoveragePathGetter && _.isFunction(completeCoveragePathGetter)) {
        //     completeCoveragePath = completeCoveragePathGetter(lobOffering);
        // }

        return {
            code: code,
            path: `${lobOffering.path}.${completeCoveragePath}`,
            clauses: _.get(lobOffering.data, completeCoveragePath.replace(/\.children/, '')),
            pairClauses: _.get(lobOffering.pairData, completeCoveragePath.replace(/\.children/, '')),
        };
    });
    return retval;
}

function structureCustomQuote(submissionVM, affectedQuote, clauses) {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    };
}

function getLineCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {}) {
    const offerings = _.get(submissionVM.value, LOB_OFFERING_PATH);
    // const lineCoverages = _.uniqBy(offerings.flatMap((offering) => (
    //     offering.coverages.lineCoverages.map(structureClauseTableData)
    // )), 'publicID');
    // const allLineCovs = offerings.flatMap((offering) => (offering.coverages.lineCoverages))
    //     .filter(QuoteCoveragesUtil.filterCovOnQuotePage);
    const allCoverages = {
        // primaryCovs: [],
        // commonOptionalCovs: [],
        // // additionalCovs: [],
        // otherOptionalCovs: [],
        // exclusionCovs: [],
        lineCoverages: [],
    };
    offerings.forEach((offering) => {
        const {
            coverages: {
                // primaryCoverages_Ext: primaryCoverages,
                // commonOptionalCoverages_Ext: commonOptionalCoverages = [],
                // // additionalCoverages: additionalCoveragesParam = [],
                // otherOptionalCoverages_Ext: otherOptionalCovs = [],
                // exclusion_Ext: exclusionCovs = [],
                lineCoverages = [],
            },
        } = offering;
        // primaryCoverages && allCoverages.primaryCovs.push(primaryCoverages.filter(filterCovOnCPPQuotePage));
        // commonOptionalCoverages&& allCoverages.commonOptionalCovs.push(commonOptionalCoverages.filter(filterCovOnCPPQuotePage));
        // // allCoverages.additionalCovs.push(additionalCoveragesParam.filter(filterCovOnCPPQuotePage));
        // otherOptionalCovs && allCoverages.otherOptionalCovs.push(otherOptionalCovs.filter(filterCovOnCPPQuotePage));
        // exclusionCovs && allCoverages.exclusionCovs.push(exclusionCovs.filter(filterCovOnCPPQuotePage));
        lineCoverages && allCoverages.lineCoverages.push(lineCoverages.filter(filterCovOnCPPQuotePage));
    });
    // const lineCoveragesLiabGroup = _.uniqBy((allCoverages.primaryCovs.flat().map(structureClauseTableData)), 'publicID');
    // const commonOptionalCoveragesGroup = _.uniqBy((allCoverages.commonOptionalCovs.flat().map(structureClauseTableData)), 'publicID');
    // // const additionalCoverages = _.uniqBy((allCoverages.additionalCovs.flat().map(structureClauseTableData)), 'publicID');
    // const otherOptionalCoveragesGroup = _.uniqBy((allCoverages.otherOptionalCovs.flat().map(structureClauseTableData)), 'publicID');
    // const exclusionCoveragesGroup = _.uniqBy(allCoverages.exclusionCovs.flat().map(structureClauseTableData), 'publicID');
    const lineCoveragesLiabGroup = _.uniqBy((allCoverages.lineCoverages.flat().map(structureClauseTableData)), 'publicID');

    // const allLineCovs = offerings.flatMap((offering) => {
    //     const {
    //         publicID_Ext: periodPublicID,
    //         coverages: {
    //             // baseCoverages: lineCoverages, // no lineCoverages for CPP, only baseCoverages in OOTB plugin
    //             primaryCoverages_Ext: lineCoverages,
    //         },
    //     } = offering;
    //     const periodCovIssues = periodToCovIssuessMap[periodPublicID];

    //     // const retval = lineCoverages.filter((lineCov) => QuoteCoveragesUtil.filterCovOnQuotePage(lineCov,
    //     //     periodCovIssues));
    //     const retval = lineCoverages;
    //     return retval;
    // });
    // const lineCoveragesLiabGroup = _.uniqBy((
    //     allLineCovs // .filter(QuoteCoveragesUtil.belongsToLiabilityTable)
    //         .map(structureClauseTableData)
    // ), 'publicID');

    // const optionalCoverages = offerings.flatMap((offering) => {
    //     const {
    //         coverages: {
    //             commonOptionalCoverages_Ext: commonOptionalCoverages = [],
    //         }
    //     } = offering;
    //     return commonOptionalCoverages;
    // });
    // const optionalCoveragesGroup = _.uniqBy(optionalCoverages.map(structureClauseTableData), 'publicID');


    // const additionalCoverages = offerings.flatMap((offering) => {
    //     const {
    //         coverages: {
    //             additionalCoverages: additionalCoveragesParam = []
    //         }
    //     } = offering;
    //     return additionalCoveragesParam;
    // });
    // const additionalCoveragesGroup = _.uniqBy(additionalCoverages.map(structureClauseTableData), 'publicID');

    const lineCoveragesOtherGroup = [];
    // const lineCoveragesOtherGroup = _.uniqBy((
    //     allLineCovs.filter((cov) => !QuoteCoveragesUtil.belongsToLiabilityTable(cov))
    //         .map(structureClauseTableData)
    // ), 'publicID');
    // const vehicleCoverages = _.uniqBy(offerings.flatMap((offering) => (
    //     offering.coverages.vehicleCoverages.flatMap(({ coverages }) => (
    //         coverages.filter(QuoteCoveragesUtil.filterCovOnQuotePage).map(structureClauseTableData)
    //     ))
    // )), 'publicID');
    return {
        // lineCoverages,
        lineCoveragesLiabGroup,
        // commonOptionalCoveragesGroup,
        // // additionalCoverages,
        // otherOptionalCoveragesGroup,
        // lineCoveragesOtherGroup,
        // exclusionCoveragesGroup,
        // vehicleCoverages,
    };
}


function getStructuredVehicleCoverages(cppOfferingDTOArray, vehicleFixedId,
    vehCovFilter = undefined) {
    const retval = _.uniqBy(cppOfferingDTOArray.flatMap((offering) => {
        const {
            publicID_Ext: periodPublicID,
            coverages: {
                // vehicleCoverages is not an array like PA, but a map for CPP. Hmm.
                [VEHICLE_COVERAGES_PATH]: vehicleCoverages,
            },
        } = offering;

        // const vehicleCoverages = Object.entries(vehicleCoveragesMap);
        const currentVehCoverages = vehicleCoverages.flatMap((vehicleCoverageDTO) => {

            // const {
            //     fixedId, coverages
            // } = vehicleCovergeDTO;
            const { coverages } = vehicleCoverageDTO;
            const fixedId = getFixedId(vehicleCoverageDTO);

            if (fixedId !== vehicleFixedId) {
                return [];
            }
            let currentVehCoveragesRetVal = coverages;
            if (_.isFunction(vehCovFilter)) {
                currentVehCoveragesRetVal = coverages.filter((cov) => vehCovFilter(cov, periodPublicID));
            }
            return currentVehCoveragesRetVal;
        });
        return currentVehCoverages.map(structureClauseTableData);
    }), 'publicID');

    return retval;
}


function filterCovOnQuotePage(coverageDto, covIssues = [],
    vehicleFixedId = undefined) {
    const {
        code_Ext: clausePatternCode,
        selected
    } = coverageDto;

    // ====Criteria 1: include those coverages that have been selected;
    let retval = selected;
    // ====Criteria 2: if the coverage is not selected, check whether it is
    // is listed in the Recommended Upgrade coverage list
    if (!(
        retval
        // Recommended DriverCoverage is handled by
        // RecommendCoveragesComponent.goToDriverCoveragesPage()
        // || PACoverageConfig.isDriverCoveragePatternCode(clausePatternCode)
        || _.isEmpty(covIssues)
    )) {
        const covIssue = covIssues.find((issue) => {
            const {
                coveragePatternCode: issueClausePatternCode,
                vehicleFixedId: issueVehicleFixedId,
            } = issue;
            let covMatch = issueClausePatternCode === clausePatternCode;

            if (covMatch && !_.isNil(vehicleFixedId)) {
                const vehicleMatch = issueVehicleFixedId === vehicleFixedId;
                covMatch = covMatch && vehicleMatch;
            }
            return covMatch;
        });
        retval = !_.isNil(covIssue);
    }
    return retval;
}

/**
 * 
 * Get a map of vehicleCoverages with form of:
 *  {
 *     vehicle.fixedId: [ [publicID: clause_public_ID}]]
 *  }
 * @param {object} submissionVM
 * @param {object} periodToCovIssuessMap (Optional)
 * @param {array} vehicleCoverageSets (Optional)
 * @returns {object}
 */
function getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuessMap = {},
    vehicleCoverageSets = []) {
    const vehicles = _.get(submissionVM.value, LOB_COVERABLE_VEHICLES_PATH, []);

    // To be updated to vehicle.fixedId?
    const vehicleFixedIdList = vehicles.map((vehicle) => getCoverableVehicleID(vehicle));

    const offerings = _.get(submissionVM.value, LOB_OFFERING_PATH);

    const vehicleCoveragesMap = {};
    vehicleFixedIdList.forEach((vehicleFixedId) => {
        const vehicleCoveragesArray = getStructuredVehicleCoverages(offerings,
            vehicleFixedId, (vehCov, periodPublicID) => {
                const periodCovIssues = periodToCovIssuessMap[periodPublicID];

                return filterCovOnQuotePage(vehCov, periodCovIssues, vehicleFixedId);
            });
        vehicleCoveragesMap[vehicleFixedId] = vehicleCoveragesArray;
    });
    return vehicleCoveragesMap;
}

// ============================================================
function generateColumnData({
    submissionVM, sxsDataDTO,
    paymentMethod,
    // installmentPlansMap = {},
    // filterLobOfferings = false,
    ...extraColumnData
}) {
    if (!sxsDataDTO) {
        return [];
    }

    const lobOfferings = _.get(submissionVM.value, LOB_OFFERING_PATH, []);
    const sxsPeriods = _.get(sxsDataDTO, SXS_PERIODS_PATH);
    const sxsCoverages = _.get(sxsDataDTO, SXS_COVERAGES_PATH);
    // const sxsVehicleCovSets = _.get(sxsDataDTO, `${LOB_NAME}.vehicleSets`);

    // // if (lobOfferings.length !== sxsPeriods.length) {
    // //     return [];
    // // }
    // // ===========to be removed BEGIN=========================
    // if (!lobOfferings) {
    //     return []; // empty value to test QuotePage before coverage data is ready
    // }
    // // ===========to be removed END===========================
    // const sxsPeriodsMatchLobOfferings = sxsPeriods.every((sxsPeriod) => lobOfferings.some(
    //     (lobOffering) => sxsPeriod.publicID === lobOffering.publicID_Ext
    // ));
    // if (!sxsPeriodsMatchLobOfferings) {
    //     return [];
    // }


    const errorsAndWarnings = _.get(sxsDataDTO, 'errorsAndWarnings', {});

    // Basically, SideBySidePeriod Info such as PolicyType, Premium, etc. are put into 'quote',
    // along with line & vehicle coverages data
    // const periodStatus = _.get(submissionVM, 'baseData.periodStatus.value');
    // const columnData = lobOfferings.map((lobOffering, lobOfferingIndex) => {
    //     const sxsPeriodIndex = sxsPeriods.findIndex(
    //         (sp) => sp.publicID === lobOffering.publicID_Ext
    //     );
    //     const sxsPeriod = sxsPeriods[sxsPeriodIndex];
    const columnData = sxsPeriods.map((sxsPeriod, sxsPeriodIndex) => {
        const lobOffering = [];
        const lobOfferingIndex = sxsPeriodIndex;

        const {
            publicID: sxsPeriodPublicID,
            columnIndex,
            pairPeriodPublicId_Ext: sxsPairPeriodPublicID,
        } = sxsPeriod;
        const sxsPeriodUWIssues = ErrorsAndWarningsUtil.filterUWIssuesBasedOnPeriod(
            _.get(errorsAndWarnings, 'underwritingIssues'), sxsPeriodPublicID
        );
        const sxsPeirodEligibilityIssues = ErrorsAndWarningsUtil.filterEligibilityIssuesBasedOnPeriod(
            _.get(errorsAndWarnings, `eligibilityIssues_Ext.${LOB_NAME}`), sxsPeriodPublicID
        );
        // const vehicleCoverageSets = QuoteCoveragesUtil.filterVehicleCoverageSets(sxsVehicleCovSets, columnIndex);

        // const sxsLobOffering = filterLobOfferings ? filterLobOffering(lobOffering, sxsCoverages) : lobOffering;
        const sxsLobOffering = lobOffering;
        const sxsPairLobOffering = QuoteUtil.getLobOfferingByPeriodPublicID(lobOfferings, sxsPairPeriodPublicID);

        // const installmentPlans = installmentPlansMap[sxsPeriod.publicID];

        return {
            name: sxsPeriod.branchName,
            code: sxsPeriod.columnIndex,
            sxsPeriod: {
                path: `${SXS_PERIODS_PATH}.children.${sxsPeriodIndex}`,
                data: sxsPeriod,
                underwritingIssues: sxsPeriodUWIssues,
                eligibilityIssues: sxsPeirodEligibilityIssues,
                vehicleCoverageSets: [],
                paymentMethod,
                // installmentPlans,
                ...extraColumnData,
            },
            lobOffering: {
                path: `${LOB_OFFERING_PATH}.children.${lobOfferingIndex}`,
                // data: lobOffering,
                data: sxsLobOffering,
                pairData: sxsPairLobOffering,
                index: lobOfferingIndex,
            }
        };
    });
    return _.sortBy(columnData, ['code']);
}


// Line Coverages and Vehicle Coverages Info
function generateTableData(submissionVM, columnData, translator, sxsDataDTO = undefined) {
    // ===========to be removed BEGIN=========================
    const offerings = _.get(submissionVM.value, LOB_OFFERING_PATH);
    if (!offerings) {
        return []; // empty value to test QuotePage before coverage data is ready
    }
    // ===========to be removed END=========================
    
    const periodToCovIssuesMap = QuoteCoveragesUtil.getPeriodToCovIssuesMap(sxsDataDTO, `${LOB_NAME}`);
    const sxsVehicleCovSets = _.get(sxsDataDTO, SXS_VEHICLE_SETS_PATH);
    // line coverages
    const lineCoveragesUniqueIDMap = getLineCoveragesUniqueID(submissionVM, periodToCovIssuesMap);


    const sxsPeriods = _.get(sxsDataDTO, SXS_PERIODS_PATH);

    // To be refactored
    const cppSxsPeriodInfoDTO = sxsPeriods && sxsPeriods[0];

    // const lineCoverages = {
    //     header: translator(messages.generalCoverages),
    //     data: coveragesUniqueIDMap.lineCoverages,
    //     tableContent: generateClauseData(columnData, 'lineCoverages')
    // };

    // 
    // const liabGroupPopulatedWithCategoryPremiums = populateCPPCovGroupWithCategoryPremium(
    //     lineCoveragesUniqueIDMap.lineCoveragesLiabGroup, 
    //     cppSxsPeriodInfoDTO, translator);
    const lineCoveragesLiabGroup = {
        header: translator(QuoteCommonMessages.policyLevelQuoteDetails),
        // data: liabGroupPopulatedWithCategoryPremiums, // lineCoveragesUniqueIDMap.lineCoveragesLiabGroup,
        data: lineCoveragesUniqueIDMap.lineCoveragesLiabGroup,
        tableContent: generateClauseData(columnData, LINE_COVERAGES_PATH), // 'primaryCoverages_Ext')
    };

    // const commonOptionalCoveragesGroup = {
    //     header: translator(QuoteCommonMessages.commonOptionalCoverages),
    //     data: lineCoveragesUniqueIDMap.commonOptionalCoveragesGroup,
    //     tableContent: generateClauseData(columnData, 'commonOptionalCoverages_Ext')
    // };
    
    // const otherOptionalCoveragesGroup = {
    //     header: translator(QuoteCommonMessages.otherOptionalCoverages),
    //     data: lineCoveragesUniqueIDMap.otherOptionalCoveragesGroup,
    //     tableContent: generateClauseData(columnData, 'otherOptionalCoverages_Ext')
    // };

    // const exclusionCoveragesGroup = {
    //     header: translator(QuoteCommonMessages.exclusionCoverages),
    //     data: lineCoveragesUniqueIDMap.exclusionCoveragesGroup,
    //     tableContent: generateClauseData(columnData, 'exclusion_Ext')
    // };

    // const additionalCoveragesGroup = {
    //     header: translator(QuoteCommonMessages.additionalCoverages),
    //     data: lineCoveragesUniqueIDMap.additionalCoveragesGroup,
    //     tableContent: generateClauseData(columnData, 'additionalCoverages')
    // };

    // vehicle coverages
    const vehicles = _.get(submissionVM.value, LOB_COVERABLE_VEHICLES_PATH, []);
    const vehicleCoveragesIDMap = getVehicleCoveragesUniqueID(submissionVM, periodToCovIssuesMap, sxsVehicleCovSets);
    
    
    const vehicleCoverages = vehicles.map((vehicle) => {
        const vehicleFixedId = getCoverableVehicleID(vehicle);
        const vehicleDescTitle = getVehicleDescTitle(vehicle, ({
            watercraftDetailDesc,
            motorDetailDesc,
            trailerDetailDesc,
        }) => {
            if (_.isEmpty(motorDetailDesc) && _.isEmpty(trailerDetailDesc)) {
                return translator(customMessages.vehicleSpecificCoverage, {vehicleDetails: watercraftDetailDesc})
            }
            const wTitle = _.isEmpty(watercraftDetailDesc) ? null : translator(customMessages.watercraftDetails, { watercraftDetailDesc });
            const mTitle = _.isEmpty(watercraftDetailDesc) ? null : translator(customMessages.motorDetails, { motorDetailDesc });
            const tTitle = _.isEmpty(watercraftDetailDesc) ? null : translator(customMessages.trailerDetails, { trailerDetailDesc });
            const retval = [wTitle, mTitle, tTitle].filter(_.identity).join('; ')
            return retval;
        });
        
        return {
            header: vehicleDescTitle,
            // data: coveragesUniqueIDMap.vehicleCoverages,
            data: vehicleCoveragesIDMap[vehicleFixedId],
            tableContent: generateClauseData(columnData, VEHICLE_COVERAGES_PATH, vehicleFixedId),
            // (lobOffering) => {
            //     const vehicleCovs = _.get(lobOffering, 'data.coverages.vehicleCoverages');
            //     const vehicleCovIdx = vehicleCovs.findIndex((vc) => getFixedId(vc) === vehicle.fixedId);
            //     const retval = `coverages.vehicleCoverages.children[${vehicleCovIdx}].coverages`;
            //     return retval;
            // })
            vehicleFixedId: vehicleFixedId,
        };
    });

    return [
        // lineCoverages,
        lineCoveragesLiabGroup,
        // commonOptionalCoveragesGroup,
        // otherOptionalCoveragesGroup,
        // exclusionCoveragesGroup,
        // additionalCoveragesGroup,
        ...vehicleCoverages,
        // lineCoveragesOtherGroup,
    ];
}

function getCustomQuote(vm, lobPath, quotePath, lobName, filterChangedClauses = false) {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(lobOffering.coverages, lobName, null);
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
}

export default {
    generateColumnData,
    generateTableData,
    //
    // getCustomQuote
};
