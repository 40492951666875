// import WALFrontendScheduleItemSyncUtil from './util/WALFrontendScheduleItemSyncUtil'
// import WALOptionsUtil from './util/WALOptionsUtil'
// import WALScheduleOptionValuesSortUtil from './util/WALScheduleOptionValuesSortUtil'

/**
 * @type {{
*  [scheduleConfigOption: String]: Object
* }}
*/
const scheduleConfig = {
    /**
     * Table column visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleColumnIdsOverride: {
        IMMotorTruckCargoPropertyExcludedExclusion_Ext: [
            'IMPropertyExcludedPropertyType_Ext',
            'IMPropertyExcludedPropertyDescription_Ext'
        ],
        IMMotorTruckCargoTheftExclusion_Ext: [
            'IMTheftExclPropertyType_Ext',
            'IMTheftExclPropertyDescription_Ext'
        ],
        boatCoveragesScheduled: [
            'IMBoatType',
            'IMBoatYear_Ext',
            'IMBoatDescription',
            'BoatLimit',
            'IMBoatRate_Ext'
        ],
        IMCommonAdditionalInsuredA632SchCov: [
            'TypeKeyCol1',
            'StringCol1',
            'StringCol2',
            'StringCol3',
            'IMCommAddInsZipCode',
            'IMCommAddInsDescriptionOfProperty'
        ]
    },
    /**
     * Schedule details visible override
     * key<clauseCode> - value<ScheduleItemId>
     * @type {{
    *  [clauseCode: String]: Array.<String>
    * }}
    */
    visibleFieldIdsOverride: {
    },
    /**
     * @type {{
     *  [clauseCode: String]: Array.<String>
     * }}
     */
    fieldsNotNeedSyncWhileChange: {
    },
    /**
     * @type {{
     *  [clauseCode: String]: {
     *      [scheduleItemID: String]: (scheduleItem: Object, allOptions: Array) => Array
     *  }
     * }}
     */
    optionFilters: {
    },
    /**
     * Return a function which return schedule after frontend sync
     * 
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (schedule: Object, localPathToSchedule: String) => Object
     *  }
     * }}
     */
    frontendScheduleItemSync: {
    },
    /**
     * @type {{
     *  [clasueCode: String]: {
     *      [scheduleItemID: String]: (optionA: Object, optionB: Object) => Number
     *  }
     * }}
     */
    optionValueSortFunction: {
    }
}

/**
 * @type {{
 *  [clauseCode: String]: Array.<String>
 * }}
 */
const termConfig = {
    termsNotChangeWithSync: [
        // group by coverage
    ]
}

/**
 * A key(coverage code)-value(term code) set of coverage has limit term
 * And limit term display right of coverage label
 * 
 * @type {{
 *  [clauseCode: String]: String
 * }}
 */
const limitCoverageConfig = {
}

/**
 * @type {{
*  [clauseCode: String]: (...params: any) => Boolean
* }}
*/
const coverageAvailable = {
}

/**
 * @type {{
*  [clauseCode: String]: {
    *       [scheduleItemID: String]: (...params: any) => Boolean
    *   }
    * }}
    */
const covTermAvailable = {
}

/**
 * @enum {String}
 */
const ClauseComponentType = {
    Toggle: 'Toggle',
}

/**
 * default as checkbox
 * @type {{
*  [clauseCode: String]: String
* }}
*/
const clauseComponentTypeMap = {
}

export default {
    scheduleConfig,
    termConfig,
    limitCoverageConfig,
    coverageAvailable,
    covTermAvailable,
    ClauseComponentType,
    clauseComponentTypeMap,
}