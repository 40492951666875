import { useTranslator } from '@jutro/locale';
import { ServiceManager } from '@jutro/legacy/services';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
ViewModelForm,
ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import appConfig from 'app-config';
import _ from 'lodash';
import React,{ useCallback,useContext,useEffect,useState } from 'react';
import { useHistory,useParams } from 'react-router-dom';
import {
WniAccountQuoteService,
WniAccountService
} from 'wni-capability-gateway';
import { AgencyOfServiceComponentForCL } from 'wni-capability-gateway-react';
import { WniCommonChangeService,WniCustomEndorsementService } from 'wni-capability-policychange';
import {
AgencyInformation,
ProductsField,
ValidationIssuesComponent,
useWniModal
} from 'wni-components-platform-react';
import { ErrorsAndWarningsUtil,WindowUtil,WniProductsUtil, WniAccountsUtil } from 'wni-portals-util-js';
import { useProductsData } from 'wni-portals-util-react';
import AccountEligibity from './AccountEligibity/AccountEligibity';
import messages from './CLPolicyHolderPage.messages';
import metadata from './CLPolicyHolderPage.metadata.json5';
import policyholderUtil from './util/policyholder.util';
import PackagePolicyPopup from './PackagePolicyPopup/PackagePolicyPopup';

const { lobEndorsementURL, endorsementProducts } = appConfig;

const DTO_PATH = 'wni.edge.capabilities.gateway.account.dto.SaveAccountHolderReqDTO';
const xCenter = 'pc';

const { 
    GL_PRODUCT_CODE,
    IM_PRODUCT_CODE,
    CR_PRODUCT_CODE,
    CPP_PRODUCT_CODE
} = WniProductsUtil;

const {
    COMMERCIAL_ACCOUNT_CODE
} = WniAccountsUtil;

const {
    premiseSubline,
    ALONGSIDE_PREMISE_SUBLINES,

    getSublinesAvailableValues,
    isFieldVisible,
    getImIncludedPackageValues,
    getProductSelectedAndLinePatterns,
    renderImIncludedInfo,
    isProductMessageVisible
} = policyholderUtil;

function CLPolicyHolderPage() {
    const viewModelService = useContext(ViewModelServiceContext);
    const modalApi = useWniModal();
    const history = useHistory();
    const { accountNumber } = useParams();

    const {
        location: {
            state: { 
                AOEffectiveDate, 
                baseState, 
                productSelected: AOProductSelected,
                isNewAccount } = {}, // location status could be null during local test
        },
    } = history;
    const {
        isComponentValid,
        onValidate,
        invalidFields
    } = useValidation('CLPolicyHolderPage');
    const { lobQuoteURL } = appConfig;

    const [productSelected, updateProductSelected] = useState(AOProductSelected);
    const [showErrors, updateShowErrors] = useState(false);
    const [accountDetails, updateAccountDetails] = useState({});
    const [policyHolderVM, updatePolicyHolderVM] = useState(null);
    const [validationIssues, updateValidationIssues] = useState([]);
    const [hazardRatingLevel, updateHazardRatingLevel] = useState(null);
    const [isHazardRatingBlock, updateHazardRatingBlock] = useState(false);
    const [hazardRatingNotification, updateHazardRatingNotification] = useState();
    // POI-53218: : trigger error message if user selects Inland Marine / Crime and only, and there are no other policies on the account, and they attempt to click next
    const [showProductError, updateShowProductError] = useState(false);

    // for internal user functionality - search Agency
    const { authHeader, authUserData } = useAuthentication();
    const userPublicID = authUserData && authUserData.publicID;
    const isExternalUser = _.get(authUserData, 'isExternalUser_Ext');
    const [licensedAgentOptions, updateLicensedAgentOptions] = useState();
    const [servicingAgentOptions, updateServicingAgentOptions] = useState();
    const [internalAgencyValue, updateinternalAgencyValue] = useState();
    const [producerCodeDisplayName, updateProducerCodeDisplayName] = useState('');
    const [licensedAgentValue, updateLicensedAgentValue] = useState();
    const [servicingAgentValue, updateServicingAgentValue] = useState();
    const { getAvailableEffectiveDate, getAvailableProducts } =  useProductsData();
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies(['loadingMask']);

    const localeService = ServiceManager.getService('locale-service');
    const defaultCountryCode = localeService.getDefaultCountryCode();

    const translator = useTranslator();

    const setDefaultAgent = (accountDetailsResponse) => {
        const {
            agencyData: {
                agencyInfo: {
                    licensedAgentData,
                    servicingAgentData,
                }
            } = {}
        } = accountDetailsResponse;
        // default Licensed Producer
        const findLicensedUser = licensedAgentData.find((item) => item.publicID === userPublicID);
        const defaultLicensed = findLicensedUser ? userPublicID : null;
        // default account contact
        const findServicingUser = servicingAgentData.find((item) => item.publicID === userPublicID);
        const defaultServicing = findServicingUser ? userPublicID : null;
      
        _.set(accountDetailsResponse, 'agencyData.producerOrLicensedAgent', defaultLicensed);
        _.set(accountDetailsResponse, 'agencyData.servicingAgent', defaultServicing);
       return accountDetailsResponse;
    };

    const initModelData = async (accountDetailsResponse) => {
        setLoadingMask(true);
        const newVM = await viewModelService.create(accountDetailsResponse, xCenter, DTO_PATH);
        updatePolicyHolderVM(newVM);
        setLoadingMask(false);
    };
    
    const initAccountDetails = async () => {
        setLoadingMask(true);
        const accountDetailsResponse = await WniAccountQuoteService.retrieveAccountQuoteData(accountNumber, authHeader);
        const initAccountDetailsResponse = setDefaultAgent(accountDetailsResponse)
        updateAccountDetails(initAccountDetailsResponse);
        

        const quoteBaseState = baseState || _.get(accountDetailsResponse, 'accountHolder.primaryAddress.state');
        const glEffectiveDate = getAvailableEffectiveDate(GL_PRODUCT_CODE, quoteBaseState);
        const productRulesData = await WniAccountQuoteService.retrieveProductRulesData(accountNumber, glEffectiveDate, authHeader);

        const initData = { ...accountDetailsResponse, productRules: productRulesData}
        initModelData(initData);
        setLoadingMask(false);
    };

    useEffect(() => {
        if (!viewModelService) {
            return;
        }
        initAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewModelService]);

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
        WindowUtil.scrollToInvalidField(invalidFields);
        return false;
    }, [updateShowErrors, invalidFields]);

    // const validProductsComponent = () => {
    //     const productKey = 'productSelectedValidation';
    //     const productValidations = {
    //         key: productKey,
    //         type: 'error',
    //         reason: translator(messages.productEmptyMessage),
    //     };
    //     const validations = validationIssues.filter(
    //         (msg) => msg.key !== productKey
    //     );
    //     if (_.isEmpty(productSelected)) {
    //         updateValidationIssues([...validations, productValidations]);
    //         return false;
    //     }
    //     updateValidationIssues(validations);
    //     return true;
    // };

    useEffect(() => {
        updateHazardRatingBlock(false);
        updateHazardRatingLevel(null);
        updateHazardRatingNotification(null);
        updateShowProductError(false);
    }, [productSelected]);

    const validHazardRating = async(level) => {
        let hazardRating = true;
        switch(level){
            case 'l_1':
                hazardRating = true;
                break;
            case 'l_2':
            case 'l_3':
                if(!isHazardRatingBlock) {
                    updateHazardRatingBlock(true);
                    updateHazardRatingNotification(messages[level]);
                    window.scrollTo(0, 0)
                    hazardRating = false;
                } else {
                    updateHazardRatingBlock(false);
                    updateHazardRatingNotification(null);
                    hazardRating = true;
                }
                break;
            case 'l_4':
            case 'l_5':
                hazardRating = false;
                if(!isHazardRatingBlock) {
                    updateHazardRatingBlock(true);
                    // const productNames = productSelected.map((item)=> getProductName(item)).join(', ');
                    const hazardMessages = translator(messages[level])
                    updateHazardRatingNotification(hazardMessages);
                    window.scrollTo(0, 0)
                } else {
                    updateHazardRatingBlock(false);
                    updateHazardRatingNotification(null);
                    history.push({
                        pathname: '/cl-account-quotes/submission-block',
                        state: {
                            blockProducts: productSelected
                        }
                    })
                }                
                break;
            default:
                hazardRating = true;
                break
        }
        return hazardRating
    };
    const generateNewSubmissionDTO = (productSelectedMap = []) => {
        const quoteBaseState =
            baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state');
        const externalProducerCode = _.get(policyHolderVM.value, 'agencyData.producerCode');
        const internalProducerCode = (typeof(internalAgencyValue) === 'string') ? internalAgencyValue : _.get(internalAgencyValue,'publicID');
        return productSelectedMap.map((product) => {
            return {
                accountNumber: accountNumber,
                country: defaultCountryCode,
                effectiveDate: getAvailableEffectiveDate(product, quoteBaseState) || accountDetails.systemDate,
                producerCode: externalProducerCode || internalProducerCode,
                productCode: product,
                state: quoteBaseState,
            };
        });
    };
    const getLicensedAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService
            .getLicensedAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        return options;
    };
    const getServicingAgentOptions = async (producerCodePublicID) => {
        const res = await WniAccountService.getServicingAgentData(producerCodePublicID, authHeader);
        const options = res.map((value) => {
            return {
                code: value.publicID,
                name: value.displayName
            };
        });
        // setServicingAgentDefaultValue
        const servicingDefaultValue = _.find(options, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code")
        updateServicingAgentValue(servicingDefaultValueCode);
        return options;
    };
    const updateInternalAgency = async (value) => {
        const producerCodePublicID = value.publicID;
        const servicingRes = await getServicingAgentOptions(producerCodePublicID);
        const licensedAgentsRes = await getLicensedAgentOptions(producerCodePublicID);
        const servicingDefaultValue = _.find(servicingRes, (item) => item.code === userPublicID);
        const servicingDefaultValueCode = _.get(servicingDefaultValue, "code");
        const licensedDefaultValue = _.filter(licensedAgentsRes, (item) => item.code === userPublicID);
        updateLicensedAgentOptions(licensedAgentsRes);
        updateServicingAgentOptions(servicingRes);
        updateinternalAgencyValue(producerCodePublicID);
        updateLicensedAgentValue(licensedDefaultValue[0]);
        updateServicingAgentValue(servicingDefaultValueCode);
        updateProducerCodeDisplayName(_.get(value, 'name'))
    };
    
    const calculateHazardRatingLevel = async(sortProductSelected) => {
        /** calculate hazard rating code */
        const requestData = {
            accountNumber,
            baseState: baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state'),
            newSubmissionDTO: generateNewSubmissionDTO(sortProductSelected)
        }
        const res = await WniAccountQuoteService.calculateHazardRatingLevel(requestData,authHeader);
        const level = _.get(res, 'level', 'l_1');
        updateHazardRatingLevel(level);
        // const isValidHForazardRating = await validHazardRating(level)
        return level;
    };
    
    const createCPPPolicyChange = async(vm) => {
        const quoteBaseState = baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state');
        const createPolicyChangeData = {
            policyNumber: _.get(vm, 'productRules.selectPolicy.value'),
            effectiveDate: getAvailableEffectiveDate(CPP_PRODUCT_CODE, quoteBaseState)
        };
        const productRulesData = _.get(vm, 'productRules.value', {});
        setLoadingMask(true);
        const res = await WniCommonChangeService.createCPPPolicyChangeWhenSelectProduct(createPolicyChangeData, productRulesData, authHeader);
        setLoadingMask(false);
        const hasError = ErrorsAndWarningsUtil.hasValidationError(res.errorsAndWarnings)
        updateValidationIssues(ErrorsAndWarningsUtil.getValidationIssues(res.errorsAndWarnings))
        if (hasError) {
            setLoadingMask(false);
            return null
        };

        const { jobID } = res;
        
        if (_.isNil(jobID)) {
            return null;   
        }

        await WniCustomEndorsementService.addRecentlyViewedPolicyChange(jobID, authHeader);
        setLoadingMask(false);
        return res;
    };

    const jumpToCPPPolicyChange = (policyChangeData) => {
        if (endorsementProducts.includes(CPP_PRODUCT_CODE)) {
            history.push(lobEndorsementURL[CPP_PRODUCT_CODE], {
                isReadOnly: false,
                policyNumber: _.get(policyChangeData, 'changeData.policyNumber'),
                jobNumber: policyChangeData.jobID,
            });
        } else {
            JobUtil.openJobInXCenter(policyChangeData.jobID);
        }
    };

    const continueCreate = async (vm, sortProductSelected, level) => {
        let policyChangeData;
        let newSortProductSelected = [...sortProductSelected];
        // create CPP PolicyChange with selected product
        if(sortProductSelected.includes(CPP_PRODUCT_CODE) && _.get(vm, 'productRules.isAddedToExistingPackage.value')) {
            policyChangeData = await createCPPPolicyChange(vm);
            newSortProductSelected = _.filter(sortProductSelected, ((item) => item !== CPP_PRODUCT_CODE));
        };

        if(_.isEmpty(newSortProductSelected)) { // if only create CPP policychange, and not other product selected
            jumpToCPPPolicyChange(policyChangeData);
            return false;
        };

        // create selected Product Submission
        // if there has other product besides CPP, will continue to create other product submission
        setLoadingMask(true);
        const newAccountQuoteData = await WniAccountQuoteService.saveAccountHolder(
                {
                    ...vm.value,
                    selectedProducts: newSortProductSelected,
                    servicingAgent: _.get(vm.value, 'agencyData.servicingAgent'),
                    producerOrLicensedAgent: _.get(vm.value, 'agencyData.producerOrLicensedAgent'),
                    newSubmissionDTO: generateNewSubmissionDTO(newSortProductSelected),
                    naicsClassLevel: level
                },
                authHeader
            );
        setLoadingMask(false);

        if(policyChangeData) { // if create CPP policychange, need to jump policychange first
            jumpToCPPPolicyChange(policyChangeData);
            return false;
        };

        const submissionToOpen = _.get(newAccountQuoteData, 'submissionToOpen');
        const postalCode = _.get(newAccountQuoteData, 'accountHolder.primaryAddress.postalCode');

        history.push(lobQuoteURL[sortProductSelected[0]], {
            isReadOnly: false,
            quoteentry: {
                postalCode: postalCode,
                quoteID: submissionToOpen,
            },
        });
        return false;
    };

    const onNext = async () => {
        
        if(!isComponentValid || !policyHolderVM?.aspects.valid || !policyHolderVM?.aspects.subtreeValid) {
            handleValidation();
            return false;
        }

        const productSelectedAndLinePatterns = getProductSelectedAndLinePatterns(policyHolderVM) || {};

        // set cpp line selection
        const selectedPatterns = productSelectedAndLinePatterns.selectedlinePatterns || [];
        _.set(policyHolderVM.value, 'productRules.selectedlinePatterns', selectedPatterns);

        // generate select productcode
        const newProductSelected = productSelectedAndLinePatterns.selectedProducts || [];
        const sortProductSelected = await WniProductsUtil.getSortProductSelected(newProductSelected);

        const IM_CR_LINET_PATTERNS = ['IMLine', 'CR7Line'];
        const CP_GL_LINE_PATTERNS = ['CP7Line', 'GL7Line'];
        const hasPoliciesWithGLCP = _.get(policyHolderVM, 'productRules.hasPoliciesWithGLCP.value', false);
        const isTriggerProductError = !_.isEmpty(_.intersection(selectedPatterns, IM_CR_LINET_PATTERNS)) && _.isEmpty(_.intersection(selectedPatterns, CP_GL_LINE_PATTERNS));
        if(isTriggerProductError && !hasPoliciesWithGLCP) {
            updateShowProductError(isTriggerProductError);
            WindowUtil.scrollTo('productErrorContainer');
            return false;
        }

        const level = hazardRatingLevel || await calculateHazardRatingLevel(sortProductSelected);
        if(!await validHazardRating(level)) {
            return false;
        }

        if(sortProductSelected.includes(CPP_PRODUCT_CODE) && _.get(policyHolderVM, 'productRules.availablePolicies.value', []).length > 0) {
            const componentProps = {
                size: 'lg',
                model: _.get(policyHolderVM, 'productRules')
            };
            modalApi.showModal(<PackagePolicyPopup {...componentProps} />).then((res) => {
                _.set(policyHolderVM.value, 'productRules', res);
                continueCreate(policyHolderVM, sortProductSelected, level)
            }).catch(_.noop);
            return false;
        };

        
        continueCreate(policyHolderVM, sortProductSelected, level)
        
    };

    const onPrevious = useCallback(async () => {
        return history.push(`/accounts/${accountNumber}/summary`)
    }, [accountNumber, history]);

    const onCancel = () => {
        updateHazardRatingBlock(false);
        updateHazardRatingNotification(null)
    };

    const writeValue = (value, path) => {
        const newVM = viewModelService.clone(policyHolderVM);
        _.set(newVM, path, value);
        updatePolicyHolderVM(newVM);
    };

    const getAccountProductAvailable = () => {
        const productsMaps = _.get(accountDetails, 'accountHolder.productAvailable_Ext', []);
        return productsMaps.map((item) => {
            return {
                ...item,
                isAvailable: isHazardRatingBlock ? false : item.isAvailable
            }
        })
    };

    const handleProductSelected = (newValues) => {
        updateProductSelected(newValues);
        writeValue(newValues, 'selectedProducts');
    };

    const handleSublinesChange = (value, path) => {
        let newValue = [...value];
        const beforeValue = _.get(policyHolderVM, 'productRules.sublineIncluded.value', []);
        // Liquor must always be written alongside prem/ops. 
        // if liquor is selected first, auto select prem/ops and do not allow agent to deselect. 
        // if liquor and prem/ops is selected and prem/ops is manually deselected. system should deselect liquor.
        const hasAlongSideSubline = _.intersection(value, ALONGSIDE_PREMISE_SUBLINES);
        if(!_.isEmpty(hasAlongSideSubline) && !_.includes(beforeValue, premiseSubline)) {
            newValue = [...newValue, premiseSubline];
            writeValue(newValue, path);
            return false;
        };
       
        if(!_.includes(value, premiseSubline)) {
            newValue = value.filter((item) => !_.includes(ALONGSIDE_PREMISE_SUBLINES, item));
            writeValue(newValue, path);
            return false;
        };
        writeValue(newValue, path);

    };

    const renderProductErrorMessage = () => {
        const selectIMorCR = _.intersection(productSelected, [CR_PRODUCT_CODE, IM_PRODUCT_CODE]);
        if(_.isEmpty(selectIMorCR)) {
            return null
        }
        const selectProductNames = selectIMorCR.map((item) => WniProductsUtil.getProductName(item));
        return selectProductNames.map((item) => {
            return <div>{translator(messages.productErrorMessage, { productName: item})}</div>
        })
    };

    if (!policyHolderVM) {
        return null;
    }
    

    const overrideProps = {
        '@field': {
            // apply to all fields
            labelPosition: 'left',
            showRequired: true,
            showOptional: false,
            disabled: isHazardRatingBlock
        },
        hazardRatingNotification: {
            visible: isHazardRatingBlock,
            message: hazardRatingNotification
        },
        validations: {
            validationIssues: validationIssues,
            visible: validationIssues.length > 0,
            scrollToIssues: true,
        },
        accountEligibity: {
            model: policyHolderVM,
            basePath: 'accountHolder',
            onValueChange: writeValue,
            onValidate,
            showErrors,
        },
        agencyServiceContent: {
            visible: isExternalUser,
            model: policyHolderVM,
            basePath: 'agencyData',
            onValueChange: writeValue,
            isExternalUser,
            onValidate,
            showErrors,
        },
        agencySearchServiceContent: {
            visible: !isExternalUser,
            licensedAgentOptions,
            servicingAgentOptions,
            // the prop name is called external, but actually is used for internal user, not suggest to change the old common component
            updateExternalAgency: updateInternalAgency,
            externalAgencyName: producerCodeDisplayName,
            externalAgencyPublicID: internalAgencyValue,
            updateLicensedAgent: updateLicensedAgentValue,
            updateServicingAgent: updateServicingAgentValue,
            licensedAgentValue,
            servicingAgentValue,
            updateLicensedAgentOptions,
            updateServicingAgentOptions,
        },
        createAccountMsg: {
            visible: !!isNewAccount,
        },
        productNotification: {
            visible: isProductMessageVisible(getAvailableProducts(COMMERCIAL_ACCOUNT_CODE, baseState))
        },
        productMapsContent: {
            productSelected,
            updateProductSelected: handleProductSelected,
            productVisible: true,
            viewModelService,
            accountType: COMMERCIAL_ACCOUNT_CODE,
            accountProductAvailableMaps: getAccountProductAvailable(),
            baseState: baseState || _.get(accountDetails, 'accountHolder.primaryAddress.state'),
            validationMessages: _.get(policyHolderVM, `selectedProducts.aspects.validationMessages`, [])
        },
        // products rules
        indicateSublines: {
            // visible: _.includes(productSelected, GL_PRODUCT_CODE),
            visible: isFieldVisible(policyHolderVM, 'sublineIncluded'),
            availableValues: getSublinesAvailableValues(policyHolderVM, 'availableSublines'),
            onValueChange: handleSublinesChange
        },
        // addedToPackage: {
        //     visible: isFieldVisible(policyHolderVM, 'isAddedToExistingPackage')
        // },
        imIncludedPackage: {
            // visible: _.includes(productSelected, IM_PRODUCT_CODE),
            visible: isFieldVisible(policyHolderVM, 'imIncludedPackage'),
            secondaryLabel: renderImIncludedInfo(translator(messages.imIncludedPackageInfo)),
            availableValues: getImIncludedPackageValues()
        },
        // selectPolicy: {
        //     visible: isFieldVisible(policyHolderVM, 'selectPolicy'),
        //     availableValues: getAvailableValues(policyHolderVM, 'availablePolicies'),
        //     defaultValue: setSelectPolicyDefaultValue(policyHolderVM, 'availablePolicies')
        // },
        productErrorMessage: {
            visible: showProductError,
            content: renderProductErrorMessage()
        },
        // when hazard rating warning is show, previous button should be hide, and cancel button should be show
        previousAction: {
            visible: !isHazardRatingBlock
        },
        cancelAction: {
            visible: isHazardRatingBlock
        }
        
    };
    const resolvers = {
        resolveCallbackMap: {
            onPrevious,
            onCancel,
            onNext
        },
        resolveComponentMap: {
            validationissuescomponent: ValidationIssuesComponent,
            agencyServiceinfo: AgencyInformation,
            agencySearchServiceinfo: AgencyOfServiceComponentForCL,
            productsfield: ProductsField,
            underwritingquestion: AccountEligibity
        },
    };


    //---------------------
    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={policyHolderVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );
}

export default CLPolicyHolderPage;