import React, {useEffect, useCallback} from 'react'
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import IMCoverageUtil from '../../util/IMCoverageUtil';
import CoverageContext from '../../context/IMCoverageContext';
import SingleClauseContext from '../../context/IMSingleClauseContext';
import IMTermComponent from "../IMSingleClauseComponentVM/IMTermComponent";

const MainCoveragesCard = (props) => {

    const {
        id: validationID,
        coveragePartClauses,
        setCoveragePartClauses,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
        mainCoverage, 
        coverageFormPath,
        updateCoverageFormClausesService,
        calculateLimit
    } = props

    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');


    const {
        code_Ext: clauseCode,
        terms: clauseCovTerms
    } = mainCoverage


    const {
        jobID,
        sessionUUID,
    } = submissionVM.value


    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID)
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID)
            }
            
        }
        
    }, [isComponentValid, onPageValidate, validationID])

    const changeClauses = useCallback(
        (value, changedPath, clausesPath, originClauses) => {
            const newClauses = IMCoverageUtil.setSingleMainClauseValue(originClauses, value, changedPath);
            const newCoveragePartClauses = _.clone(coveragePartClauses)
            _.set(newCoveragePartClauses, clausesPath, newClauses);
            setCoveragePartClauses(newCoveragePartClauses);
            setIsEditing(false);
            return newCoveragePartClauses;
        },
        [coveragePartClauses, setCoveragePartClauses, setIsEditing]
    );

    const changeMainCoverages = useCallback(
        (value, changedPath) => {
            return changeClauses(value, changedPath, coverageFormPath, mainCoverage);
        },
        [changeClauses, coverageFormPath, mainCoverage]
    );


    const updateVMToServer = useCallback(async (newCoveragePartClauses, setEditingFunc) => {
        // const updatedCoveragePartClausesPublicIDs = IMCoverageUtil.getUpdatedCoveragesCodesArrayByUpdatedLineClausesDTO(newCoveragePartClauses);
        setLoadingMask(true)
        // setLoadingClausesFunc(updatedCoveragePartClausesPublicIDs)
        const needToUpdateCoverage = _.get(newCoveragePartClauses, coverageFormPath);
        const finalCoverage = {};
        _.set(finalCoverage, coverageFormPath, needToUpdateCoverage);
        const coveragePartClausesFromServer = await updateCoverageFormClausesService(
            jobID,
            sessionUUID,
            finalCoverage,
            authHeader
        )
        // setLoadingClausesFunc(false)
        setEditingFunc(false)
        setLoadingMask(false)
        setCoveragePartClauses(coveragePartClausesFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }, [setLoadingMask, coverageFormPath, updateCoverageFormClausesService, jobID, sessionUUID, authHeader, setCoveragePartClauses, updateWizardDataWhileSetPeriodStatus, submissionVM]);

    const updateNewCoveragePartClausesToServer = useCallback(async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }, [updateVMToServer, setIsEditing]);

    const changeMainCoveragesAndSync = useCallback((value, changedPath) => {
            const newCoveragePartClauses = changeMainCoverages(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        },
        [changeMainCoverages, updateNewCoveragePartClausesToServer]
    );

    const onSyncCoverages = useCallback(
        () => {
            updateNewCoveragePartClausesToServer(coveragePartClauses);
        },
        [coveragePartClauses, updateNewCoveragePartClausesToServer]
    )

    return (
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: coveragePartClauses,
                        updateClausesContainer: setCoveragePartClauses,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <SingleClauseContext.Provider value={{
                        clauseCode: clauseCode,
                        clausePath: 'mainCoverage',
                        isEditable,
                        // isDisabled,
                        onChangeClause: changeMainCoverages,
                        onSyncCoverages: onSyncCoverages,
                        onChangeSubmissionAndSync: changeMainCoveragesAndSync,
                        labelPosition: 'left',
                        onValidate,
                        setIsEditing,
                        showErrors,
                    }}>
                        {clauseCovTerms.map(term => {
                            const termIndex = clauseCovTerms
                                .findIndex((clauseTerm) => _.get(clauseTerm, 'code_Ext') === _.get(term, 'code_Ext'))
                            const visibleOfDivider = _.get(term, 'visibleOfDivider_Ext');
                            const visibleOfExtendedLabel = _.get(term, 'visibleOfExtendedLabel_Ext');
                            const extendedLabel = _.get(term ,'extendedLabel_Ext');
                            return <>
                                {visibleOfDivider && <hr/>}
                                {visibleOfExtendedLabel && <h4>{extendedLabel}</h4>}
                                <IMTermComponent
                                    key={_.get(term, 'code_Ext')}
                                    term={term}
                                    termIndex = {termIndex}
                                    calculateLimit = {calculateLimit}
                                />
                            </>
                        })}
                    </SingleClauseContext.Provider>
                </CoverageContext.Provider>
                
            </div>
    )
}

export default MainCoveragesCard