import _ from 'lodash'
import { AccordionCard } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import React, { useEffect } from 'react';
import { IMAAISCommonFormsService } from 'wni-capability-quoteandbind-im';
import AccordionCardTitle from "../../../../components/IMClauseAccordionCardTitle";
import messages from './ConditionsAndExclusionsCard.messages';
import CoverageContext from '../../../../context/IMCoverageContext';
import IMClausesComponentVM from '../../../../components/IMClausesComponentVM';
import IMSingleClauseComponentVM from '../../../../components/IMSingleClauseComponentVM';
import IMCoverageUtil from '../../../../util/IMCoverageUtil';



const ConditionsAndExclusionsCard = (props) => {
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');

    const withLoadingMask = async (serviceCallFunc) => {
        setLoadingMask(true);
        const res = await serviceCallFunc();
        setLoadingMask(false);
        return res;
    };

    const {
        commonForms,
        setCommonForms,
        isEditing,
        setIsEditing,
        isEditable,
        showErrors,
        onValidate: onPageValidate,
        onClickAddSearchClauses,
        wizardData: submissionVM,
        updateWizardDataWhileSetPeriodStatus,
    } = props;


    const translator = useTranslator();

    const validationID = 'ConditionsAndExclusionsCard';
    const clauseType = 'exclusionsAndConditions';
    const { jobID, sessionUUID } = submissionVM.value;

    const { onValidate, isComponentValid } = useValidation(validationID);
    const { authHeader } = useAuthentication();

    useEffect(() => {
        if (onPageValidate) {
            onPageValidate(isComponentValid, validationID);
        }
        return () => {
            if (onPageValidate) {
                onPageValidate(true, validationID);
            }
        };
    }, [isComponentValid, onPageValidate]);

    const {
        exclusionAndCondition
    } = commonForms

    const changeSubmission = (value, changedPath) => {
            const newClauses = IMCoverageUtil.setClauseValue(exclusionAndCondition, 'exclusionAndCondition', value, changedPath);
            const newCommonForms = _.clone(commonForms)
            _.set(newCommonForms, 'exclusionAndCondition', newClauses);
            setCommonForms(newCommonForms);
            setIsEditing(false);
            return newCommonForms;
        }

    const updateVMToServer = async (newCoveragePartClauses, setEditingFunc) => {
        const commonFormsToUpdate = IMCoverageUtil.generateCommonFormsClausesDTO(newCoveragePartClauses);
        const commonFormsFromServer = await withLoadingMask(() => IMAAISCommonFormsService.updateAAISCommonFormsCoverage(
            jobID,
            sessionUUID,
            commonFormsToUpdate,
            authHeader
        ))
        setEditingFunc(false)
        setCommonForms(commonFormsFromServer);
        updateWizardDataWhileSetPeriodStatus(submissionVM)
    }
    const updateNewCoveragePartClausesToServer = async (newCoveragePartClauses) => {
        updateVMToServer(newCoveragePartClauses, setIsEditing)
    }

    const changeClauseAndSync = (value, changedPath) => {
            const newCoveragePartClauses = changeSubmission(value, changedPath);
            
            updateNewCoveragePartClausesToServer(newCoveragePartClauses);
        }

    const onSyncCoverages = () => {
            updateNewCoveragePartClausesToServer(commonForms);
        }

    return (
        <AccordionCard
            id="ConditionsandExclusionsCard"
            initialExpanded
            chevron
            className="coveragesAccordion"
            cardBodyClassName="accordionCardBody"
            cardHeadingClassName="coveragesAccordionHeader font-capitalize"
            title={isEditable ?
                <AccordionCardTitle
                    title={translator(messages.ConditionsandExclusions)}
                    actionText={messages.AddConditionsandExclusions}
                    onClick={() => onClickAddSearchClauses(clauseType)}
                /> : translator(messages.ConditionsandExclusions)}
            errorState={showErrors && !isComponentValid}
        >
            <div className="formCoveragesContainer">
                <CoverageContext.Provider
                    value={{
                        wizardData: submissionVM,
                        updateWizardData: updateWizardDataWhileSetPeriodStatus,
                        clausesContainer: commonForms,
                        updateClausesContainer: setCommonForms,
                        updateVMToServer: updateVMToServer
                    }}
                >
                    <IMClausesComponentVM
                        splitByClauseCategory={false}
                        sortBySelected
                        onSyncCoverages={onSyncCoverages}
                        onChangeSubmissionAndSync={changeClauseAndSync}
                        onChangeClause={changeSubmission}
                        onValidate={onValidate}
                        showAmount={false}
                        path="exclusionAndCondition"
                        value={exclusionAndCondition}
                        showErrors={showErrors}
                        componentMapOverrides={{
                            IMSingleClauseComponentVM: IMSingleClauseComponentVM,
                        }}
                        setIsEditing={setIsEditing}
                        isEditing={isEditing}
                        isEditable={isEditable}
                    />
                </CoverageContext.Provider>
                
            </div>
        </AccordionCard>
    );
};

export default ConditionsAndExclusionsCard;
