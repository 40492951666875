import React, {
    useContext,
    useCallback,
    useEffect,
    useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useTranslator } from '@jutro/locale';
import { QuestionSetComponent } from 'gw-components-platform-react';
import WizardPage from '../../templates/GLWizardPage';

import metadata from './GLUnderwritingQuestionsPage.metadata.json5';

function GLUnderwritingQuestionsReadOnlyPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        isReadOnly = false
    } = props;


    const generateOverrides = useCallback(() => {
        return {
            '@field': {
                // apply to all fields
                labelPosition: 'left',
            },
            questionSetsContainer: {
                isReadOnly
            }
        };
    }, [isReadOnly]);

    const onPageNext = useCallback(async () => {
        return submissionVM;
    }, []);

    const writeValue = useCallback((value, path) => {
        const newSubmissionVM = _.cloneDeep(submissionVM);
        _.set(newSubmissionVM, path, value);
        updateWizardData(newSubmissionVM);
    }, [submissionVM]);

    //---------------------
    const overrideProps = generateOverrides();
    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            questionset: QuestionSetComponent
        }
    };

    return (
        <WizardPage
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValueChange={writeValue}
            />
        </WizardPage>
    );
}

GLUnderwritingQuestionsReadOnlyPage.propTypes = WizardPage.propTypes;
GLUnderwritingQuestionsReadOnlyPage.defaultProps = WizardPage.defaultProps;

GLUnderwritingQuestionsReadOnlyPage.propTypes = {
    ...wizardProps
};

GLUnderwritingQuestionsReadOnlyPage.defaultProps = {
};
export default GLUnderwritingQuestionsReadOnlyPage;