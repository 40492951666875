import React, {
    useContext,
    useCallback,
    useState,
    useEffect
} from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { IMCoveragePartsService } from 'wni-capability-quoteandbind-im';
import {
    WniProductsUtil,
    QuoteUtil,
    WindowUtil,
    ValidationIssueUtil
} from 'wni-portals-util-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import IMSelectablePartsPopup from './Component/IMSelectablePartsPopup/IMSelectablePartsPopup';
import WizardPage from '../../templates/IMWizardPage';
import metadata from './IMCoveragePartSelectionPage.metadata.json5';
import messages from './IMCoveragePartSelectionPage.messages';
import IMCoverageSelectionUtil from '../../util/IMCoverageSelectionUtil';
import CoverageFormSelection from './Component/CoverageFormSelection/CoverageFormSelection';

const {
    getIMSkipPages
} = IMCoverageSelectionUtil;

const {
    IM_PRODUCT_CODE,
    getLobName
} = WniProductsUtil;

const IM_LOB_NAME = getLobName(IM_PRODUCT_CODE);

const COVERAGE_PART_NAME= 'coveragePartSelection';

const COVERAGE_PART_PATH = `lobData.${IM_LOB_NAME}.${COVERAGE_PART_NAME}`;

function IMCoveragePartSelectionPage(props) {
    const {
        wizardData: submissionVM,
        updateWizardData,
        updateWizardSnapshot,
        isReadOnly,
        toggleWizardPageDisplay
    } = props;

    const {
        jobID,
        sessionUUID,
        baseData: {
            productCode
        } = {},
        lobData: {
            [IM_LOB_NAME]: {
                [COVERAGE_PART_NAME]: {
                    selectableCoverageParts
                } = {}
            }
        },
    } = submissionVM.value;
    
    const modalApi = useWniModal();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');

    const viewModelService = useContext(ViewModelServiceContext);
    const {
        initialValidation,
        onValidate,
        invalidFields,
        isComponentValid,
    } = useValidation('IMCoveragePartSelectionPage');

    const [validationIssues, updateValidationIssues] = useState([]);
    const [displayWarnings, updateDisplayWarnings] = useState(false);
    const [selection, updateSelection] = useState([]);
    const [showErrors, updateShowErrors] = useState(false);

   const getSkipPages = (data = {}) => {
        const skipSteps = getIMSkipPages(productCode, data)
        toggleWizardPageDisplay(skipSteps)
   };

    const handleValidation = useCallback(() => {
        updateShowErrors(true);
    }, [invalidFields]);

    const updateSubmissionVMByResponse = (response) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, COVERAGE_PART_PATH, _.get(response, COVERAGE_PART_NAME, {}));
        updateWizardSnapshot(newSubmissionVM);

        getSkipPages(response.coveragePartSelection);

        return newSubmissionVM;
    };

    const generateValidationIssues = (issues) => {
        const newValidationIssues = ValidationIssueUtil.getValidationIssues(issues);
        updateValidationIssues(newValidationIssues);

        const hasValidationError = ValidationIssueUtil.hasErrorInValidationIssueList(newValidationIssues);
        const hasValidationWarning = ValidationIssueUtil.hasWarningInValidationIssueList(newValidationIssues);
        if(hasValidationWarning && !displayWarnings) {
            updateDisplayWarnings(true);
            return false;
        }
        if (hasValidationError) {
            WindowUtil.scrollToWizardErrors();
            updateShowErrors(true);
            return false;
        }
        return true;
    };

    const addCoverageParts = async (selectedPartsCode) => {
        const requstData = {
            jobID,
            sessionUUID,
            codes: selectedPartsCode
        }
        setLoadingMask(true);
        const res = await IMCoveragePartsService.addCoverageParts(requstData, authHeader);
        updateSubmissionVMByResponse(res);
        setLoadingMask(false);
    }

    const removeCoverageParts = () => {
        modalApi.showConfirm({
            title: messages.removeTitle,
            message: messages.removeDescription,
            status: 'warning',
            icon: 'gw-error-outline',
            confirmButtonText: commonMessages.ok,
            cancelButtonText: commonMessages.cancelModel
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return false;
            }
            const requstData = {
                jobID,
                sessionUUID,
                codes: selection
            }
            setLoadingMask(true);
            const res = await IMCoveragePartsService.removeCoverageParts(requstData, authHeader);
            updateSubmissionVMByResponse(res);
            updateSelection([]);
            setLoadingMask(false);
        })
    }

    const handleSelectableParts = () => {
        const componentProps = {
            title: messages.addCoveragePartSelectionTitle,
            size: 'lg',
            selectableCoverageParts,
            cancelButtonText: commonMessages.cancelModel,
            confirmButtonText: messages.addAllSelectedCoverageParts
        };
        return modalApi.showModal(
            <IMSelectablePartsPopup {...componentProps} />
        ).then((res) => {
            addCoverageParts(res);
        }).catch(() => {
            _.noop();
        });;
    }

    const onPageNext = async () => {
        if(isReadOnly) {
            return submissionVM;
        }
        if(!isComponentValid) {
            handleValidation();
            return false;
        };

        const requstData = {
            jobID,
            sessionUUID,
            dto: _.get(submissionVM, `${COVERAGE_PART_PATH}.value`)
        };
        setLoadingMask(true);
        const res = await IMCoveragePartsService.onPageNext(requstData, authHeader);
        updateSubmissionVMByResponse(res);
        setLoadingMask(false);
        const isPageValid = generateValidationIssues(res.errorsAndWarnings);
        if(!isPageValid) {
            return false;
        }
        return submissionVM;
    };

    const writeValue = (value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${COVERAGE_PART_PATH}.${path}`, value);
        updateWizardData(newSubmissionVM)
    };

    const syncCoverageForm = async(value, path) => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM, `${COVERAGE_PART_PATH}.${path}`, value);
        updateWizardData(newSubmissionVM)
        const requstData = {
            jobID,
            sessionUUID,
            dto: _.get(newSubmissionVM, `${COVERAGE_PART_PATH}.value`)
        };
        setLoadingMask(true);
        const res = await IMCoveragePartsService.syncCoverageForm(requstData, authHeader);
        updateSubmissionVMByResponse(res);
        setLoadingMask(false);
    };
    //---------------------
    const overrideProps = {
        '@field': {
        },
        addCoverageParts: {
            visible: !isReadOnly,
            disabled: _.isEmpty(selectableCoverageParts),
        },
        delCoverageParts: {
            visible: !isReadOnly,
            disabled: _.isEmpty(selection)
        },
        coveragePartTable: {
            onSelectionChange: (rows) => updateSelection(rows),
            selectionType: isReadOnly ? 'none' : 'multi',
            selectedRows: selection
        },
        coverageFormSelection: {
            visible: productCode === IM_PRODUCT_CODE,
            vm: _.get(submissionVM, COVERAGE_PART_PATH),
            writeValue,
            isReadOnly,
            syncCoverageForm
        }
    };
    const resolvers = {
        callbackMap: {
            handleSelectableParts,
            removeCoverageParts
        },
        componentMap: {
            coverageformselection: CoverageFormSelection
        }
    };

    return (
        <WizardPage
            skipWhen={QuoteUtil.getSkipRatedQuotedFnV2(initialValidation)}
            onNext={onPageNext}
            pageLevelValidationIssues={validationIssues}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
                {...resolvers}
            />
        </WizardPage>
    );
}

export default IMCoveragePartSelectionPage;