import React, {
// useEffect,
useState
} from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { useTranslator } from '@jutro/locale';
import { DropdownMenuButton } from '@jutro/components';
import { useWniModal } from 'wni-components-platform-react';
import { WniDateUtil } from 'wni-portals-util-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { Link as LinkComponent, FieldLinkComponent } from 'gw-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import accountSummaryConstants from '../../AccountSummaryConstants';
import metadata from './AQASActivePolicesPanel.metadata.json5';
import styles from './AQASActivePolicesPanel.module.scss';
import messages from './AQASActivePolicesPanel.messages';

import { CurrencyField } from '@jutro/legacy/components';

import { DropdownMenuLink } from '@jutro/router';

const {
    lobEndorsementURL
} = appConfig;
function AQASActivePolicesPanel(props) {
    const modalApi = useWniModal();
    const {
        accountNumber,
        activePolicies,
        availableProducerCodesForCurrentUser,
    } = props;

    const { interactionModel } = useDependencies('interactionModel');
    const translator = useTranslator();
    const { authUserData } = useAuthentication();
    const history = useHistory();

    const [
        productFilterSelectedItem,
        setProductFilterSelectedItem
    ] = useState(accountSummaryConstants.ProductFilterOptionAllCode);

    const getProductFilterAvailableItems = () => {
        const options = [{
            code: accountSummaryConstants.ProductFilterOptionAllCode,
            name: translator(messages.activePoliciesProductFilterAllItemName),
        }];
        activePolicies.forEach((policySummary) => {
            const productCode = _.get(policySummary, 'product.productCode');
            if (!options.some((option) => option.code === productCode)) {
                options.push({
                    code: productCode,
                    name: _.get(policySummary, 'product.productName')
                });
            }
        });
        return options;
    };

    const handleProductFilterChange = (newFilter) => {
        setProductFilterSelectedItem(newFilter);
    };

    const getProductCell = (rowData) => {
        return _.get(rowData, 'product.productName', '-');
    };

    const getEffectiveDateCell = (rowData) => {
        const effectiveDate = _.get(rowData, 'effective');
        return effectiveDate ? WniDateUtil.formatDateWithPattern(effectiveDate) : '-';
    };

    const getExpirationDateCell = (rowData) => {
        const expirationDate = _.get(rowData, 'expiration');
        return expirationDate ? WniDateUtil.formatDateWithPattern(expirationDate) : '-';
    };

    const getTotalPremiumCell = (rowData) => {
        const totalCost = _.get(rowData, 'totalCost_Ext');
        return totalCost ? (
            <CurrencyField
                id="currency"
                value={totalCost}
                readOnly
                hideLabel
                showOptional={false}
            />
        ) : '-';
    };

    const getPolicyAccess = (agencyOfServiceCode) => {
        let result = false;
        if (!_.get(authUserData, 'isExternalUser_Ext')) {
            result = true;
        } else {
            _.forEach(availableProducerCodesForCurrentUser, (agency) => {
                const { code } = agency;
                if (agencyOfServiceCode === code) {
                    result = true;
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    const showWarningMsg = () => {
        modalApi.showAlert({
            status: 'warning',
            icon: 'gw-error-outline',
            message: messages.warningMsgNoRights,
        }).catch(() => {
            _.noop();
        });
    };

    const getViewPolicyCell = (rowData, _index, { id: property }) => {
        const agencyOfServiceCode = rowData.producerCodeOfService;
        const access = getPolicyAccess(agencyOfServiceCode);
        if (!access) {
            return <FieldLinkComponent
                id={`policy${rowData[property]}`}
                className={styles.removeLinkStyle}
                value={rowData[property]}
                onClick={showWarningMsg}
            />
        }
        const policyNumber = _.get(rowData, 'policyNumber');
        if (!policyNumber || !accountNumber) {
            return '-';
        }
        const url = interactionModel.getURLObj(null, 'policySummary', accountNumber, policyNumber);
        return (
            <LinkComponent
                {...url}
                className={styles.removeLinkStyle}
            >
                {policyNumber}
            </LinkComponent>
        );
    };

    const getCell = (items, _index, property) => {
        return items[property.id] ? items[property.id] : '-';
    };

    const getChangeOrCancelCell = (rowData) => {
        
        const onChangePolicyClick = () => {
            const agencyOfServiceCode = rowData.producerCodeOfService;
            const access = getPolicyAccess(agencyOfServiceCode);
            if (access) {
                const { policyNumber: policyNumberParam, product: { productCode } = {} } = rowData;
                history.push(lobEndorsementURL[productCode], {
                    policyNumber: policyNumberParam,
                });
            } else {
                showWarningMsg();
            }
        };

        const onCancelPolicyClick = () => {
            const agencyOfServiceCode = rowData.producerCodeOfService;
            const access = getPolicyAccess(agencyOfServiceCode);
            if (access) {
                const { policyNumber: policyNumberParam } = rowData;
                const historyState = {
                    disableCancelLink: false,
                    disableChangePolicyLink: true,
                    displayBtnGroup: false
                };
                history.push(`/policies/${policyNumberParam}/summary`, historyState);
            } else {
                showWarningMsg();
            }
        };
        return (
            <div>
                <DropdownMenuButton
                    icon="gw-expand-more"
                    id="dropdownMenuButton"
                    className="dropDownMenuIconbtn"
                    menuClassName="dropDownMenuList"
                    alignRight
                >
                    <DropdownMenuLink
                        onClick={() => onChangePolicyClick()}
                    >
                        {translator(messages.changePolicyButton)}
                    </DropdownMenuLink>
                    <DropdownMenuLink
                        onClick={() => onCancelPolicyClick()}
                    >
                        {translator(messages.cancelPolicyButton)}
                    </DropdownMenuLink>
                </DropdownMenuButton>
            </div>
        );
    };

    const productFilterAvailableValue = getProductFilterAvailableItems();

    const displayedPolicies = productFilterSelectedItem
        === accountSummaryConstants.ProductFilterOptionAllCode
        ? activePolicies : activePolicies
            .filter(
                (policySummary) => _.get(policySummary, 'product.productCode') === productFilterSelectedItem
            );

    const overrideProps = {
        '@field': {
            // apply to all fields
            // labelPosition: breakpoint === 'desktop' ? 'left' : 'top',
            labelPosition: 'left',
        },
        productFilter: {
            availableValues: productFilterAvailableValue,
            value: productFilterSelectedItem,
        },
        activePoliciesTable: {
            data: displayedPolicies
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            getProductCell: getProductCell,
            getEffectiveDateCell: getEffectiveDateCell,
            getExpirationDateCell: getExpirationDateCell,
            getTotalPremiumCell: getTotalPremiumCell,
            getViewPolicyCell: getViewPolicyCell,
            getChangeOrCancelCell: getChangeOrCancelCell,
            getCell: getCell,
            handleProductFilterChange: handleProductFilterChange,
        },
        resolveComponentMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={{}}
            overrideProps={overrideProps}
            // onModelChange={updateFormData}
            // onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            // onValidationChange={onValidate}
        />
    );
}

AQASActivePolicesPanel.propTypes = {
    accountNumber: PropTypes.string.isRequired,
    activePolicies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    availableProducerCodesForCurrentUser: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default AQASActivePolicesPanel;
