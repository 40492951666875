import { defineMessages } from 'react-intl';

export default defineMessages({
    scheduleAdd: {
        id: 'InlandMarine.valuablePapers.table.Add',
        defaultMessage: 'Add',
    },
    scheduleDel: {
        id: 'InlandMarine.valuablePapers.table.del',
        defaultMessage: 'Delete Selected'
    },
    scheduleEdit: {
        id: 'InlandMarine.valuablePapers.table.edit',
        defaultMessage: 'Edit'
    },

    valuableNumber: {
        id: 'InlandMarine.valuablePapers.table.valuableNumber',
        defaultMessage: 'No.'
    },
    locationBuilding: {
        id: 'InlandMarine.valuablePapers.table.locationBuilding',
        defaultMessage: 'Location/Building'
    },
    isLocationALibrary: {
        id: 'InlandMarine.valuablePapers.table.isLocationALibrary',
        defaultMessage: 'Is Location a Library?'
    },
    Yes: {
        id: 'InlandMarine.valuablePapers.table.Yes',
        defaultMessage: 'Yes'
    },
    No: {
        id: 'InlandMarine.valuablePapers.table.No',
        defaultMessage: 'No'
    },
    receptacleManufacturer: {
        id: 'InlandMarine.valuablePapers.table.Receptacle Manufacturer',
        defaultMessage: 'Receptacle Manufacturer'
    },
    receptacleLabel: {
        id: 'InlandMarine.valuablePapers.table.receptacleLabel',
        defaultMessage: 'Receptacle Label'
    },
    receptacleClass: {
        id: 'InlandMarine.valuablePapers.table.receptacleClass',
        defaultMessage: 'Receptacle Class'
    },
    recptacleIssue: {
        id: 'InlandMarine.valuablePapers.table.Receptacle Issuer',
        defaultMessage: 'Receptacle Issuer'
    },
    UnscheduledLimit: {
        id: 'InlandMarine.valuablePapers.table.Unscheduled Limit',
        defaultMessage: 'Unscheduled Limit'
    },
    scheduleView: {
        id: 'InlandMarine.valuablePapers.table.view',
        defaultMessage: 'View'
    },
    
})