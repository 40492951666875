
const FIRST_CHANGE_WIZARD_PAGE = 'IMPolicyInfoChangePage';

function getChangePageJumpList({ policyChangeSource, getPageJumpAction }) {
    let retval = [];
    if (policyChangeSource === 'cancellation') {
        retval = [{
            name: 'Mailing Address',
            action: getPageJumpAction('IMPolicyDetailsPage'),
        }];
    } else {
        retval = [
            {
                name: 'Policy Details',
                action: getPageJumpAction('IMPolicyDetailsPage'),
            },
            {
                name: 'Coverage Part Selection',
                action: getPageJumpAction('IMCoveragePartSelectionPage'),
            },
            {
                name: 'Buildings And Locations',
                action: getPageJumpAction('IMBuildingsAndLocationsPage'),
            },
            {
                name: 'Modifiers',
                action: getPageJumpAction('IMModifiersPage'),
            },
            {
                name: 'Common Forms',
                action: getPageJumpAction('IMCommonFormPage'),
            },
            {
                name: 'Bailee Customers Floater Coverage - Dry Cleaners and Laundry',
                action: getPageJumpAction('IMBaileePartPage'),
            },
            {
                name: 'Boat Coverage',
                action: getPageJumpAction('IMBoatPartPage'),
            },
            {
                name: "Builders' Risk",
                action: getPageJumpAction('IMBuilderRiskPartPage'),
            },
            {
                name: 'Contractors\' Equipment',
                action: getPageJumpAction('IMContractorsEquipPartPage'),
            },
            {
                name: 'Electronic Data Processing',
                action: getPageJumpAction('IMElectronicDataProcessingPartPage'),
            },
            {
                name: 'Fine Arts Floater',
                action: getPageJumpAction('IMFineArtsFloaterPartPage'),
            },
            {
                name: 'Installation Floater',
                action: getPageJumpAction('IMInstallationFloaterPartPage'),
            },
            {
                name: 'Misc Forms',
                action: getPageJumpAction('IMMiscFormsPartPage'),
            },
            {
                name: 'Miscellaneous Floaters',
                action: getPageJumpAction('IMMiscFloatersPartPage'),
            },
            {
                name: 'Motor Truck Cargo Legal Liability Coverage',
                action: getPageJumpAction('IMMotorTruckCargoPartPage'),
            },
            {
                name: 'Museum Collection Coverage',
                action: getPageJumpAction('IMMuseumsPartPage'),
            },
            {
                name: 'Riggers\' Coverage',
                action: getPageJumpAction('IMRiggersPartPage'),
            },
            {
                name: 'Transit',
                action: getPageJumpAction('IMTransitPartPage'),
            },
            {
                name: 'Warehouse Legal Liability',
                action: getPageJumpAction('IMWarehouseLegalLiabilityPartPage'),
            },
            {
                name: 'Risk Analysis',
                action: getPageJumpAction('IMRiskAnalysisPage'),
            },
            {
                name: 'Underwriting Questions Page',
                action: getPageJumpAction('IMUnderwritingQuestionsPage'),
            }
        ];
        // return  [{
        //     name: 'Policy Details',
        //     action: getPageJumpAction('WALPolicyDetailsPage'),
        // }, {
        //     name: 'Watercrafts',
        //     action: getPageJumpAction('WALWatercraftPage'),
        // }, {
        //     name: 'Operators',
        //     action: getPageJumpAction('WALOperatorPage'),
        // }, {
        //     name: 'Coverages',
        //     action: getPageJumpAction('WALCoveragesPage'),
        // }, {
        //     name: 'Loss History and Prior Carrier',
        //     action: getPageJumpAction('WALIncidentsAndReportsPage'),
        // }];
    }
    return retval;
}

export {
    FIRST_CHANGE_WIZARD_PAGE,
    getChangePageJumpList,
};