import { defineMessages } from 'react-intl';

export default defineMessages({
    ReceptacleDetails: {
        id: 'InlandMarine.signs.schedules.scheduleItem.Receptacle Details',
        defaultMessage: 'Receptacle Details',
    },
    Cancel: {
        id: 'InlandMarine.signs.schedules.scheduleItem.Cancel',
        defaultMessage: 'Cancel',
    },
    SaveAndContinue: {
        id: 'InlandMarine.signs.schedules.scheduleItem.SaveAndContinue',
        defaultMessage: 'Save & Continue',
    },
})